import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { L as y, a as S } from "./index.esm-qyC9L-ym.js";
import { createContext as U, useContext as P, useState as s, useEffect as C } from "react";
import { a as b, s as A } from "./sentry-Djb1pAvw.js";
import { F } from "./provider-CNUuOe_A.js";
const u = /* @__PURE__ */ U({}), T = ({ children: x, override: i = !1, loggedInPartnerID: r = void 0 }) => {
  const { fpapi: o } = P(F), [v, d] = s(void 0), [f, n] = s(r), [h, L] = s(!!(i && f)), [j, a] = s(!1);
  return C(() => {
    var p;
    if (i) {
      a(!0), r && sessionStorage.setItem("loggedInPartner", r), n(r), L(!0);
      return;
    }
    (p = o == null ? void 0 : o.userAuthService) == null || p.getAuthenticatedUser().then((e) => {
      var l, m, c;
      e && ((l = e == null ? void 0 : e.profile) == null ? void 0 : l.type) === "investor" && (d(e), b({
        id: (m = e == null ? void 0 : e.profile) == null ? void 0 : m.sub
      }), A({
        tagName: "invp",
        tagValue: (c = e == null ? void 0 : e.profile) == null ? void 0 : c.fp_identifier
      })), a(!0);
    });
    const g = sessionStorage.getItem("loggedInPartner");
    n(g || void 0);
  }, []), /* @__PURE__ */ t.jsx(t.Fragment, {
    children: j ? /* @__PURE__ */ t.jsx(u.Provider, {
      value: {
        loggedInUser: v,
        loggedInPartner: f,
        nonPartnerDashboardUser: h,
        setLoggedInUser: d,
        setLoggedInPartner: n
      },
      children: x
    }) : /* @__PURE__ */ t.jsx(y, {
      variant: "plain",
      children: /* @__PURE__ */ t.jsx(S, {
        variant: "fullpage"
      })
    })
  });
};
export {
  u as LoginContext,
  T as LoginProvider
};
