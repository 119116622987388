import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as _, useEffect as I } from "react";
import { i as s } from "./index-C-jpp3An.js";
import { e as m, T as C, M as u, k as E, B as M, S as w, p as N, C as x, c as h, a as A, P as F } from "./index.esm-qyC9L-ym.js";
import { AppContext as k } from "./AppContext.js";
import { u as P } from "./useAuth-s3T3bPBI.js";
import { F as L } from "./provider-CNUuOe_A.js";
import { R as U, F as j } from "./react-final-form.es-B_J3uV0l.js";
import { f as D, g as S, h as y, r as R } from "./validators-BNinzCrT.js";
import { i as W } from "./utils-Bzskk-FI.js";
import { S as G } from "./StatusMessage-CBzuZxyP.js";
import { ErrorPage as H } from "./ErrorPage.js";
import { W as K } from "./Welcome-HpuiFPhk.js";
function X({ handleAddRTAFailureCodes: a }) {
  const l = [
    {
      label: "Code",
      value: "ERROR_CODE"
    },
    {
      label: "Regex",
      value: "ERROR_TEXT_REGEX"
    },
    {
      label: "Text pattern",
      value: "ERROR_TEXT_CONTAINS"
    }
  ], r = [
    {
      label: "BSE",
      value: "BSE"
    },
    {
      label: "RTA",
      value: "RTA"
    }
  ], o = [
    {
      label: "CAMS",
      value: "1"
    },
    {
      label: "KFINTECH",
      value: "2"
    }
  ];
  return /* @__PURE__ */ e.jsx("div", {
    children: /* @__PURE__ */ e.jsx(U, {
      onSubmit: a,
      subscription: {
        submitting: !0,
        values: !0,
        errors: !0
      },
      children: (c) => /* @__PURE__ */ e.jsxs("form", {
        onSubmit: c.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(m, {
                message: s._({
                  id: "8KDBy9"
                })
              }),
              /* @__PURE__ */ e.jsx(j, {
                name: "failure_codes",
                validate: D(R, y(4), S(150)),
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(C, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (i) => t.input.onChange(i.target.value),
                      id: t.input.name,
                      autoComplete: "off"
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(u, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(m, {
                message: s._({
                  id: "kFziKH"
                })
              }),
              /* @__PURE__ */ e.jsx(j, {
                name: "gateway",
                validate: R,
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(E, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (i) => {
                        t.input.onChange(i);
                      },
                      placeholder: "Select gateway",
                      options: r
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(u, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(m, {
                message: s._({
                  id: "54uCA2"
                })
              }),
              /* @__PURE__ */ e.jsx(j, {
                name: "rta",
                validate: R,
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(E, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (i) => {
                        t.input.onChange(i);
                      },
                      placeholder: "Select RTA",
                      options: o
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(u, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(m, {
                message: s._({
                  id: "8kF3Wn"
                })
              }),
              /* @__PURE__ */ e.jsx(j, {
                name: "type",
                validate: R,
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(E, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (i) => {
                        t.input.onChange(i);
                      },
                      placeholder: "Select type",
                      options: l
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(u, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-4",
            children: [
              /* @__PURE__ */ e.jsx(m, {
                message: s._({
                  id: "enZZPY"
                })
              }),
              /* @__PURE__ */ e.jsx(j, {
                name: "match_criteria",
                validate: D(R, y(1), S(150)),
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(C, {
                      name: t.input.name,
                      value: t.input.value,
                      onChange: (i) => t.input.onChange(i.target.value),
                      id: t.input.name,
                      autoComplete: "off"
                    }),
                    t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(u, {
                      type: "error",
                      variant: "field",
                      title: t.meta.error
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(M, {
            type: "submit",
            fullwidth: !0,
            customClass: "mb-2 mt-4",
            children: s._({
              id: "uhQPR2"
            })
          })
        ]
      })
    })
  });
}
function B({ addedOrderFailureCode: a }) {
  const l = {
    ERROR_TEXT_REGEX: "Regex",
    ERROR_TEXT_CONTAINS: "Text pattern",
    ERROR_CODE: "Code"
  }, r = {
    1: "CAMS",
    2: "KFINTECH"
  };
  return /* @__PURE__ */ e.jsx(G, {
    title: "Order failure code added successfully",
    iconName: "successInfo",
    children: W(a) ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(w, {
      customClass: "mt-4",
      children: /* @__PURE__ */ e.jsxs(N, {
        children: [
          /* @__PURE__ */ e.jsx(x, {
            span: 6,
            children: /* @__PURE__ */ e.jsx(h, {
              label: s._({
                id: "h8J9U6"
              }),
              value: a == null ? void 0 : a.value
            })
          }),
          /* @__PURE__ */ e.jsx(x, {
            span: 6,
            children: /* @__PURE__ */ e.jsx(h, {
              label: s._({
                id: "kFziKH"
              }),
              value: a == null ? void 0 : a.gateway
            })
          }),
          /* @__PURE__ */ e.jsx(x, {
            span: 6,
            children: /* @__PURE__ */ e.jsx(h, {
              label: s._({
                id: "enZZPY"
              }),
              value: a == null ? void 0 : a.key
            })
          }),
          /* @__PURE__ */ e.jsx(x, {
            span: 6,
            children: /* @__PURE__ */ e.jsx(h, {
              label: s._({
                id: "54uCA2"
              }),
              value: r[a == null ? void 0 : a.rta_id]
            })
          }),
          /* @__PURE__ */ e.jsx(x, {
            span: 6,
            children: /* @__PURE__ */ e.jsx(h, {
              label: s._({
                id: "8kF3Wn"
              }),
              value: l[a == null ? void 0 : a.type]
            })
          })
        ]
      })
    })
  });
}
function Z() {
  const { fpapi: a } = f(L), [l, r] = _(!1), [o, c] = _("ADD_ORDER_FAILURE_CODE_FORM"), [t, i] = _({}), g = async (n) => {
    var d, v, T;
    r(!0);
    try {
      const b = {
        gateway: (d = n == null ? void 0 : n.gateway) == null ? void 0 : d.value,
        rta_id: (v = n == null ? void 0 : n.rta) == null ? void 0 : v.value,
        type: (T = n == null ? void 0 : n.type) == null ? void 0 : T.value,
        key: n == null ? void 0 : n.match_criteria,
        value: n == null ? void 0 : n.failure_codes
      }, O = await a.fpClient.rta_failure_codes().add(b);
      i(O), c("ADDED_ORDER_FAILURE_CODE_STATUS_PAGE"), r(!1);
    } catch {
      c("ERROR"), r(!1);
    }
  }, p = () => /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(F, {
        title: s._({
          id: "ixJKds"
        })
      }),
      /* @__PURE__ */ e.jsx(X, {
        handleAddRTAFailureCodes: g
      })
    ]
  });
  return l ? /* @__PURE__ */ e.jsx(A, {
    variant: "fullpage"
  }) : (() => {
    switch (o) {
      case "ADD_ORDER_FAILURE_CODE_FORM":
        return p();
      case "ADDED_ORDER_FAILURE_CODE_STATUS_PAGE":
        return /* @__PURE__ */ e.jsx(B, {
          addedOrderFailureCode: t
        });
      case "ERROR":
        return /* @__PURE__ */ e.jsx(H, {
          title: s._({
            id: "STsSui"
          }),
          description: s._({
            id: "uHsknt"
          }),
          showAction: !0,
          actionText: s._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            c("ADD_ORDER_FAILURE_CODE_FORM");
          }
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function le() {
  const [a, l] = _(""), { AppState: { showWelcomePage: r }, setShowWelcomePage: o } = f(k), { isFpapiLoading: c } = f(L), t = P(), i = [
    {
      step: 1,
      content: s._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: s._({
        id: "4sMyFj"
      })
    },
    {
      step: 3,
      content: s._({
        id: "l9vDMc"
      })
    }
  ];
  I(() => {
    if (r)
      return l("WELCOME");
    t.isLoading || (t.isAuthenticated ? c || l("ADD_RTA_FAILURE_CODES") : t.login());
  }, [
    t.isAuthenticated,
    t.isLoading,
    r,
    c
  ]);
  const g = () => {
    o(!1), l("LOADING");
  };
  return (() => {
    switch (a) {
      case "WELCOME":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(F, {
              title: s._({
                id: "1RCQ5U"
              }),
              customClass: "mb-6"
            }),
            /* @__PURE__ */ e.jsx(K, {
              onAck: g,
              welcomeSteps: i
            })
          ]
        });
      case "LOADING":
        return /* @__PURE__ */ e.jsx(A, {
          variant: "fullpage"
        });
      case "ADD_RTA_FAILURE_CODES":
        return /* @__PURE__ */ e.jsx(Z, {});
      default:
        return /* @__PURE__ */ e.jsx(A, {
          variant: "fullpage"
        });
    }
  })();
}
export {
  le as ManageOrderFailureCodes
};
