import { j as l } from "./jsx-runtime-C8rcB1Lr.js";
import { i as T } from "./index-C-jpp3An.js";
import { L as I, a as B } from "./index.esm-qyC9L-ym.js";
import { useState as s, useEffect as M, useMemo as X } from "react";
import { ErrorPage as H } from "./ErrorPage.js";
import { t as V } from "./utils-Bzskk-FI.js";
import { s as y, r as $ } from "./sentry-Djb1pAvw.js";
import { C as O } from "./useConfiguration-B-lyaBB9.js";
const m = () => {
  var e;
  return (e = V) != null && e.isStagingOrSandbox ? "https://s.finprim.com" : "https://fpapps.io";
}, d = () => {
  var a, i;
  const e = (i = (a = window == null ? void 0 : window.location) == null ? void 0 : a.host) == null ? void 0 : i.split(".")[0];
  return window.location.hostname === "localhost" ? "prueba" : e;
}, W = ({ children: e, userType: a, override: i = !1, externalTenantConfig: A = null, externalAmcs: E = null, externalBanks: x = null }) => {
  const [h, P] = s(A), [g, R] = s(x), [u, N] = s(E), [n, _] = s(null), [v, w] = s(!1);
  M(() => {
    (async () => {
      var j;
      if (i) {
        w(!0);
        return;
      }
      try {
        const t = await (await fetch(`${m()}/dashboard/config`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Tenant-Name": d()
          }
        })).json(), r = document.createElement("link");
        r.rel = "stylesheet", window.location.hostname === "localhost" ? r.href = "/default.css" : r.href = t.theme, document.getElementsByTagName("head")[0].appendChild(r);
        const c = document.getElementById("favicon");
        c && window.location.hostname === "localhost" ? c.href = "/logo-favicon.ico" : c !== null && (c.href = t.favicon);
        const f = [], L = fetch(`${m()}/dashboard/assets/banks_config`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Tenant-Name": d()
          }
        });
        if (f.push(L), t != null && t.showSchemeLogo) {
          const o = fetch(`${m()}/dashboard/assets/amcs_config`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-Tenant-Name": d()
            }
          });
          f.push(o);
        }
        const D = (await Promise.all(f)).map((o) => o ? o.json() : null), [S, C] = await Promise.all(D);
        if (a === "EMPLOYER") {
          const o = await fetch("/employer_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          t.employer = await o.json();
        }
        if (a === "INTERNAL_ADMIN") {
          const o = await fetch("/internal_admin_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          if (!o.ok) {
            _({
              title: T._({
                id: "Qw6fIi"
              }),
              description: T._({
                id: "94jjGc"
              }),
              showDetails: !1,
              showAction: !1
            });
            return;
          }
          t.internal_admin = await o.json();
        }
        if (a === "ADMIN") {
          const o = await fetch("/admin_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          t.admin = await o.json();
        }
        C && N(C), R(S), P(t), y({
          tagName: "tenant",
          tagValue: (j = t == null ? void 0 : t.fp) == null ? void 0 : j.tenant
        }), y({
          tagName: "app",
          tagValue: process.env.REACT_APP_TYPE
        }), w(!0);
      } catch (p) {
        window.debug.log(p), $(p);
      }
    })();
  }, []);
  const b = X(() => ({
    tenantConfig: h,
    amcs: u,
    banks: g
  }), [
    h,
    u,
    g
  ]), k = () => n ? /* @__PURE__ */ l.jsx(H, {
    title: n.title,
    description: n.description,
    reason: n.reason,
    showDetails: n.showDetails,
    showAction: n.showAction,
    actionText: n.actionText,
    actionHandler: n.actionHandler,
    customClass: "pt-20 px-8"
  }) : v ? /* @__PURE__ */ l.jsx(O.Provider, {
    value: b,
    children: e
  }) : /* @__PURE__ */ l.jsx(B, {
    variant: "fullpage"
  });
  return /* @__PURE__ */ l.jsx(I, {
    variant: "plain",
    children: k()
  });
};
export {
  W as C
};
