import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useCallback as d, useContext as i, useEffect as u } from "react";
import { AppContext as x } from "./AppContext.js";
import { LoginContext as p } from "./LoginContext.js";
import { NotFoundPage as h } from "./NotFoundPage.js";
import { A as f } from "./Auth-Phxt4zVs.js";
import { P as j, S as b, c as o } from "./index.esm-qyC9L-ym.js";
import { u as g } from "./useConfiguration-B-lyaBB9.js";
import { i as a } from "./index-C-jpp3An.js";
import { T as w } from "./Tnc-xTwpAD-l.js";
function C() {
  const t = d(() => {
    window.history.back();
  }, []), { tenantConfig: r } = g(), s = r == null ? void 0 : r.contact_info;
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(j, {
        title: a._({
          id: "C0P2Zb"
        }),
        icon: "Back",
        onClick: t
      }),
      /* @__PURE__ */ e.jsxs(b, {
        header: {
          title: /* @__PURE__ */ e.jsx(o, {
            label: /* @__PURE__ */ e.jsx("span", {
              className: "text-neutral-60 dark:text-base-color",
              children: a._({
                id: "GH0SDK"
              })
            }),
            value: /* @__PURE__ */ e.jsx("span", {
              className: "text-neutral-dark-0 dark:text-base-color",
              children: s == null ? void 0 : s.working_hours
            })
          })
        },
        customClass: "mt-6",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3",
            children: /* @__PURE__ */ e.jsx(o, {
              label: a._({
                id: "j0OMD5"
              }),
              value: s == null ? void 0 : s.call_us_on
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3",
            children: /* @__PURE__ */ e.jsx(o, {
              label: a._({
                id: "EshbxC"
              }),
              value: s == null ? void 0 : s.mail_us
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3",
            children: /* @__PURE__ */ e.jsx(o, {
              label: a._({
                id: "r/JpY3"
              }),
              value: s == null ? void 0 : s.whatsapp_us
            })
          })
        ]
      })
    ]
  });
}
function D() {
  const { loggedInUser: t, loggedInPartner: r } = i(p), { setShowWelcomePage: s } = i(x), l = new URLSearchParams(window.location.search).get("type");
  u(() => {
    (t || r) && s(!1);
  }, [
    t,
    r
  ]);
  const m = () => {
    const n = (c) => t || r ? c : /* @__PURE__ */ e.jsx(f, {
      redirectURL: window.location.href,
      allowRegistration: !1,
      showTestPan: {
        onboarded: !0
      }
    });
    switch (l) {
      case "tnc":
        return n(/* @__PURE__ */ e.jsx(w, {}));
      case "contact":
        return n(/* @__PURE__ */ e.jsx(C, {}));
      default:
        return /* @__PURE__ */ e.jsx(h, {});
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: m()
  });
}
export {
  D as Support
};
