import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { B as n } from "./index.esm-qyC9L-ym.js";
import { i as o } from "./index-C-jpp3An.js";
import { t as i } from "./utils-Bzskk-FI.js";
const s = "/images/bg-notfind.svg";
function m() {
  const a = () => {
    window.location.href = window.location.origin;
  }, e = () => {
    window.location.href = `${window.location.origin}/support?type=contact`;
  };
  return /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx("img", {
        src: s,
        className: "absolute left-0 top-9 w-full",
        style: {
          top: "48px"
        },
        alt: "Not found"
      }),
      /* @__PURE__ */ t.jsxs("div", {
        className: "relative z-10 pt-40",
        children: [
          /* @__PURE__ */ t.jsx("h2", {
            className: "text-neutral-dark-0 text-size-32 dark:text-neutral-dark-110 mb-2",
            children: o._({
              id: "gQRxEq"
            })
          }),
          /* @__PURE__ */ t.jsx("p", {
            className: "text-size-14 text-neutral-60 mb-4 leading-normal",
            children: o._({
              id: "T2OA1U",
              values: {
                0: i.isStagingOrSandbox ? "You can go back to home and search from the available options. " : ""
              }
            })
          }),
          i.isStagingOrSandbox && /* @__PURE__ */ t.jsx("div", {
            className: "mb-2",
            children: /* @__PURE__ */ t.jsx(n, {
              variant: "text",
              onClick: a,
              children: o._({
                id: "hsJetG"
              })
            })
          }),
          /* @__PURE__ */ t.jsxs("div", {
            children: [
              " ",
              /* @__PURE__ */ t.jsx(n, {
                variant: "text",
                onClick: e,
                children: o._({
                  id: "TjPSZZ"
                })
              })
            ]
          })
        ]
      })
    ]
  });
}
export {
  m as NotFoundPage
};
