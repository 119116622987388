import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as y, useState as i, useEffect as b } from "react";
import { AppContext as K } from "./AppContext.js";
import { u as X } from "./useConfiguration-B-lyaBB9.js";
import { F as Y } from "./provider-CNUuOe_A.js";
import { LoginContext as Z } from "./LoginContext.js";
import { P as $, a as ee } from "./index.esm-qyC9L-ym.js";
import { r as x } from "./sentry-Djb1pAvw.js";
import { a as te, i as se, B as ne } from "./utils-Bzskk-FI.js";
import { i as a } from "./index-C-jpp3An.js";
import { W as ae } from "./Welcome-HpuiFPhk.js";
import { ErrorPage as _ } from "./ErrorPage.js";
import { P as oe, a as re, b as ce } from "./PurchasePlanConsent-B0mO4SkT.js";
import { P as f } from "./Product-DxVYHIQw.js";
import { O as ie } from "./OrderStatus-CRO1IwVe.js";
import { A as le } from "./Auth-Phxt4zVs.js";
function Fe({ order_id: g }) {
  const { fpapi: r } = y(Y), { loggedInUser: O } = y(Z), { AppState: { showWelcomePage: j }, setShowWelcomePage: k } = y(K), [l, u] = i(null), [w, P] = i(null), [m, v] = i([]), [R, c] = i(!1), [d, E] = i(null), [W, n] = i("payment"), [I, T] = i(null), { tenantConfig: p, amcs: M } = X(), U = p == null ? void 0 : p.fallbackLogo, q = p == null ? void 0 : p.showSchemeLogo, [B, S] = i(L), D = [
    {
      step: 1,
      content: a._({
        id: "oSiV7/"
      })
    },
    {
      step: 2,
      content: a._({
        id: "Lk2Fhh"
      })
    }
  ];
  b(() => {
    (async () => {
      if (!O)
        n("login");
      else {
        try {
          c(!0);
          const s = await r.getInvestorProfile();
          if (E(s), te(g) === "Purchase plan") {
            const o = await r.fpClient.mf_purchase_plans().fetch(g);
            P(o), n("purchase_plan_consent"), c(!1);
            return;
          }
          const t = await r.fpClient.mf_purchases().fetch(g);
          if (u(t), await C(t.scheme), t != null && t.plan) {
            const o = await r.fpClient.mf_purchase_plans().fetch(t == null ? void 0 : t.plan);
            P(o);
          }
          if (t.state === "confirmed" || t.state === "submitted" || t.state === "successful") {
            n("payment_completed"), c(!1);
            return;
          } else if (t.state === "pending")
            n("payment"), c(!1);
          else if (t.state === "failed" && t.failure_code === "payment_failure") {
            const o = await r.fpClient.mf_purchases().retry(t.id);
            u(o), n("payment"), c(!1);
          } else {
            n("payment_error"), c(!1);
            return;
          }
        } catch (s) {
          n("error"), c(!1), x(s);
          return;
        }
        c(!1);
      }
    })();
  }, []);
  async function C(s) {
    try {
      const t = await r.fpClient.master_data().fetchScheme(s);
      H(t);
    } catch (t) {
      window.debug.log(t), x(t);
    }
  }
  function H(s) {
    const t = q ? {
      ...s,
      schemeLogo: M[s == null ? void 0 : s.amc_id] || U
    } : {
      ...s
    };
    v(t);
  }
  async function N({ success: s, paymentId: t, error: o }) {
    var F, A;
    if (t)
      try {
        const h = await r.fpClient.payments().fetch(Number(t));
        T(h);
        const J = await r.fpClient.mf_purchases().fetch(l.id);
        u(J);
      } catch (h) {
        window.debug.log(h), n("error"), x(h);
      }
    s ? n("order_status") : o && ((A = (F = o == null ? void 0 : o.data) == null ? void 0 : F.error) == null ? void 0 : A.message) === "consent_not_captured" ? n(l.plan ? "purchase_plan_consent" : "purchase_consent") : n("order_status_error");
  }
  function Q() {
    const s = window.location.origin + ne + "/checkout?isin=" + l.scheme;
    window.location.href = s;
  }
  b(() => {
    S(L());
  }, [
    j
  ]);
  function L() {
    return j ? "welcome" : "payment";
  }
  function V() {
    k(!1), S("payment");
  }
  const z = () => {
    switch (W) {
      default:
      case "login":
        return /* @__PURE__ */ e.jsx(le, {
          redirectURL: window.location.href,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !1,
            kycNonCompliant: !1
          }
        });
      case "payment":
        return !se(l) && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(f, {
              scheme: m
            }),
            /* @__PURE__ */ e.jsx(ce, {
              investorProfile: d,
              mf_purchase: l,
              onCompletion: N
            })
          ]
        });
      case "payment_completed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(f, {
              scheme: m
            }),
            /* @__PURE__ */ e.jsx(_, {
              title: a._({
                id: "pMWyvp"
              }),
              description: a._({
                id: "heQwEb"
              }),
              showDetails: !1,
              showAction: !1
            })
          ]
        });
      case "payment_error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(f, {
              scheme: m
            }),
            /* @__PURE__ */ e.jsx(_, {
              title: a._({
                id: "DwyPtq"
              }),
              description: a._({
                id: "om4LRf"
              }),
              showDetails: !1,
              showAction: !1
            })
          ]
        });
      case "order_status":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(f, {
              scheme: m
            }),
            /* @__PURE__ */ e.jsx(ie, {
              investorProfile: d,
              scheme: m,
              mf_order: w || l,
              payment: I,
              handleInvestAgain: Q
            })
          ]
        });
      case "order_status_error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(f, {
              scheme: m
            }),
            /* @__PURE__ */ e.jsx(_, {
              title: a._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: a._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                n("payment");
              }
            })
          ]
        });
      case "purchase_consent":
        return /* @__PURE__ */ e.jsx(re, {
          investorProfile: d,
          id: l.id,
          onSuccess: () => n("payment"),
          action: "confirm"
        });
      case "purchase_plan_consent":
        return /* @__PURE__ */ e.jsx(oe, {
          investorProfile: d,
          id: w.id,
          onSuccess: async (s, t) => {
            await C(t.scheme), u(t), n("payment");
          },
          action: "confirm"
        });
      case "error":
        return /* @__PURE__ */ e.jsx(_, {
          title: a._({
            id: "6gcOn5"
          }),
          description: a._({
            id: "whqIka"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Retry",
          actionHandler: () => n("payment")
        });
    }
  };
  function G() {
    return B === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx($, {
          title: a._({
            id: "6MLcer"
          })
        }),
        /* @__PURE__ */ e.jsx(ae, {
          onAck: V,
          welcomeSteps: D
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: R ? /* @__PURE__ */ e.jsx(ee, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: z()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: G()
  });
}
export {
  Fe as ManagePayments
};
