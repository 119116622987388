import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as y, useContext as fe, useEffect as oe } from "react";
import { a as de, M as re, c as I, P, R as Re, S as ue, B as ie, D as ke, q as ze, b as ve } from "./index.esm-qyC9L-ym.js";
import { u as _e } from "./useConfiguration-B-lyaBB9.js";
import { F as xe } from "./provider-CNUuOe_A.js";
import { u as Ce, O as Fe } from "./OtpForm-BDSqbu14.js";
import { R as Ae, d as $e, A as se, B as Me, i as Z, S as Ue, f as Ve, k as me, M as Je, T as He, N as Qe } from "./utils-Bzskk-FI.js";
import { r as H, b as Xe } from "./sentry-Djb1pAvw.js";
import { i as n } from "./index-C-jpp3An.js";
import { NotFoundPage as Ke } from "./NotFoundPage.js";
import { P as ae } from "./Product-DxVYHIQw.js";
import { O as Ne } from "./OrderStatus-CRO1IwVe.js";
import { m as Se, M as qe, r as Ge } from "./MandateListing-7eU3x5KZ.js";
import { u as Ye } from "./Auth-Phxt4zVs.js";
import { o as Ze } from "./redirection-_r5ryu1X.js";
import { B as Pe } from "./BankAccountListing-21nhFdVG.js";
import { T as et } from "./Tnc-xTwpAD-l.js";
import { ErrorPage as Oe } from "./ErrorPage.js";
import { S as Be } from "./StatusMessage-CBzuZxyP.js";
function tt({ scheme: $, investorProfile: b, mf_purchase: k, onCompletion: S }) {
  var je, he;
  const { tenantConfig: C, banks: Q } = _e(), { analytics: T } = Ye(), U = C == null ? void 0 : C.fallbackLogo, j = k != null && k.amount ? k.amount : 0, [g, F] = y(null), [N, q] = y(!0), [m, M] = y(!1), [V, t] = y("initial"), [ee, c] = y(!1), [O, u] = y(null), [i, X] = y(null), { fpapi: L } = fe(xe), [te, G] = y([]);
  let B = ((je = C == null ? void 0 : C.app_constants) == null ? void 0 : je.paymentMethods) || [];
  B = j >= 1e5 ? B : B == null ? void 0 : B.filter((o) => o !== "mandate");
  const [z, Y] = y((B || [])[0]), [ne, p] = y(!1), f = Ae(B), [a, r] = y(""), [_, x] = y(""), D = (he = C == null ? void 0 : C.app_constants) == null ? void 0 : he.paymentGateway;
  oe(() => {
    W();
  }, []), oe(() => {
    w(), K();
  }, [
    g
  ]);
  const W = async () => {
    try {
      if (b != null && b.id) {
        p(!0);
        const o = await L.fpClient.bank_accounts().fetchAll({
          profile: b.id
        });
        F(o.data), p(!1);
      }
    } catch (o) {
      p(!1), window.debug.error(o), H(o);
    }
  }, w = async () => {
    const o = g == null ? void 0 : g.map((v) => v.old_id);
    $e(o) || L.fpClient.mandates().fetchAll({
      bank_account_id: o == null ? void 0 : o.join(","),
      size: 100
    }).then((v) => {
      const s = v.mandates;
      if (s.sort((d, h) => new Date(h.created_at).valueOf() - new Date(d.created_at).valueOf()), s.length > 0) {
        g == null || g.forEach((l) => {
          s == null || s.forEach((R) => {
            R.bank_account_id === l.old_id && (R.bank = {
              bank_name: l.bank_name,
              ifsc: l.ifsc_code,
              number: l.account_number
            });
          });
        });
        const d = [];
        s.forEach((l) => {
          l.mandate_status === "APPROVED" && d.push(l);
        });
        const h = d.filter((l) => l.mandate_limit >= j);
        d.length && h[0] ? X(h[0]) : X(null);
      }
    });
  }, K = async () => {
    if (g) {
      const o = g.map((v) => L.fpClient.bank_account_verifications().fetchAll({
        bank_accounts: [
          String(v.id)
        ]
      }));
      Promise.allSettled(o).then((v) => {
        const s = [];
        v.forEach((d, h) => {
          if (d.status === "fulfilled") {
            const { data: l } = d.value, R = l[0], A = {
              ...g[h]
            };
            R && (A.verification = R, R.status === "completed" && ((d == null ? void 0 : d.status) === "completed" && (d == null ? void 0 : d.reason) === "digital_verification" && (d == null ? void 0 : d.confidence) === "zero" || s.push(A)));
          } else d.status === "rejected" && window.debug.log(d);
        }), G(s), s.length && !O && u(s[0]);
      }).catch((v) => {
        window.debug.log(v), Xe(v), H(v);
      });
    }
  }, E = async () => {
    try {
      const o = await L.fpClient.email_addresses().fetchAll({
        profile: b == null ? void 0 : b.id
      }), v = await L.fpClient.phone_numbers().fetchAll({
        profile: b == null ? void 0 : b.id
      });
      return {
        emailData: o,
        phoneData: v
      };
    } catch (o) {
      window.debug.log(o), H(o);
    }
  }, le = (o) => {
    o.stopPropagation(), q(!N);
  }, ge = (o) => (o == null ? void 0 : o.mandate_status) !== "APPROVED" ? n._({
    id: "n8egj/"
  }) : (o == null ? void 0 : o.mandate_limit) < j ? n._({
    id: "1go36W"
  }) : "", De = (o) => {
    S({
      success: !0,
      isWindowBlocked: !1,
      paymentId: o
    });
  }, Le = (o) => {
    S({
      success: !1,
      isWindowBlocked: !0,
      paymentId: o
    });
  }, Ee = (o) => {
    S({
      success: !0,
      isWindowBlocked: !1,
      paymentId: o
    });
  };
  function Ie(o) {
    o.preventDefault();
    const v = O.old_id;
    if (z === "mandate") {
      const s = ge(i);
      r(s), s || (t("initiated"), L.fpClient.payments().createNachPayment({
        mandate_id: i.id,
        amc_order_ids: [
          k.old_id
        ]
      }).then((d) => {
        S({
          success: !0,
          isWindowBlocked: !1,
          paymentId: d.id
        });
      }).catch((d) => {
        var h, l;
        H(d), d && ((l = (h = d == null ? void 0 : d.data) == null ? void 0 : h.error) == null ? void 0 : l.message) === "consent_not_captured" ? S({
          success: !1,
          isWindowBlocked: !1,
          error: d
        }) : (x("Something went wrong"), t("initial"));
      }));
    } else
      D && D === "BILLDESK" ? (t("initiated"), L.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          k.old_id
        ],
        method: z,
        bank_account_id: v,
        payment_postback_url: `${window.location.origin + Me}/redirection`,
        provider_name: "BILLDESK"
      }).then((s) => {
        Ze(s == null ? void 0 : s.token_url, "payment-billdesk", () => De(s == null ? void 0 : s.id), () => Le(s == null ? void 0 : s.id), () => Ee(s == null ? void 0 : s.id));
      }).catch((s) => {
        var d, h;
        H(s), s && ((h = (d = s == null ? void 0 : s.data) == null ? void 0 : d.error) == null ? void 0 : h.message) === "consent_not_captured" ? S({
          success: !1,
          isWindowBlocked: !1,
          error: s
        }) : (x("Something went wrong"), t("initial"));
      })) : (t("initiated"), L.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          k.old_id
        ],
        method: z,
        bank_account_id: v
      }).then((s) => {
        E().then((d) => {
          var pe, we;
          const { emailData: h, phoneData: l } = d, R = s.id, A = s.sdk_options.razorpay;
          let ye = null;
          for (const J in A.method)
            A.method[J] && (ye = J);
          const We = {
            amount: A.amount,
            currency: "INR",
            email: (pe = h == null ? void 0 : h.data[0]) == null ? void 0 : pe.email,
            contact: (we = l == null ? void 0 : l.data[0]) == null ? void 0 : we.number,
            method: ye,
            bank: A.bank_code,
            order_id: A.order_id
          }, ce = Ge(A.key);
          ce.on("payment.success", function(J) {
            T.track("Payment Processed", {
              category: "Payments",
              revenue: j
            }), T.track("Payment Processed", {
              category: "Payments",
              revenue: j
            }), L.submitPayment(A.callback_url, J.razorpay_payment_id, J.razorpay_order_id, J.razorpay_signature).then(() => {
              S({
                success: !0,
                isWindowBlocked: !1,
                paymentId: R
              });
            });
          }), ce.on("payment.error", function(J) {
            T.track("Payment Failed", {
              category: "Payments",
              revenue: j
            }), L.submitPaymentError(A.callback_url, "error", J.error.code, J.error.description).then(() => {
              S({
                success: !1,
                isWindowBlocked: !1,
                paymentId: R
              });
            });
          }), ce.createPayment(We);
        });
      }).catch((s) => {
        var d, h;
        H(s), s && ((h = (d = s == null ? void 0 : s.data) == null ? void 0 : d.error) == null ? void 0 : h.message) === "consent_not_captured" ? S({
          success: !1,
          isWindowBlocked: !1,
          error: s
        }) : (x("Something went wrong"), t("initial"));
      }));
  }
  const be = () => {
    var o, v, s, d, h;
    return /* @__PURE__ */ e.jsx(e.Fragment, {
      children: ne ? /* @__PURE__ */ e.jsx(de, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          $ && /* @__PURE__ */ e.jsx(ae, {
            scheme: $
          }),
          k.plan && /* @__PURE__ */ e.jsx(re, {
            type: "important",
            variant: "flash",
            title: n._({
              id: "0Q3xNa"
            }),
            customClass: "mb-5"
          }),
          /* @__PURE__ */ e.jsx(I, {
            label: `${k.plan ? n._({
              id: "/DJBcB"
            }) : n._({
              id: "KqeLJ1"
            })}`,
            value: `₹ ${se(j)}`,
            direction: "row",
            border: !0,
            customClass: "mb-7"
          }),
          /* @__PURE__ */ e.jsx("form", {
            onSubmit: Ie,
            className: "flex flex-1 flex-col",
            children: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-1 flex-col justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-8",
                      children: /* @__PURE__ */ e.jsxs("div", {
                        children: [
                          /* @__PURE__ */ e.jsx(P, {
                            variant: "sub",
                            title: n._({
                              id: "pCX9tn"
                            }),
                            customClass: "mb-3"
                          }),
                          /* @__PURE__ */ e.jsx(Re, {
                            variant: "iconBased",
                            name: "radioname",
                            checked: B.indexOf(z),
                            group: f,
                            onChange: (l, R) => {
                              Y(R.value), r("");
                            }
                          })
                        ]
                      })
                    }),
                    z === "mandate" && j >= 1e5 ? /* @__PURE__ */ e.jsxs("div", {
                      className: "mb-8 mt-4",
                      children: [
                        /* @__PURE__ */ e.jsx(P, {
                          variant: "sub",
                          title: n._({
                            id: "tJ7I/4"
                          })
                        }),
                        i ? /* @__PURE__ */ e.jsx(ue, {
                          children: /* @__PURE__ */ e.jsxs("div", {
                            className: "flex flex-wrap items-center justify-between",
                            children: [
                              /* @__PURE__ */ e.jsx("div", {
                                className: "w-full",
                                children: /* @__PURE__ */ e.jsx(I, {
                                  label: n._({
                                    id: "L/u9Dz"
                                  }),
                                  value: i ? `${(o = i == null ? void 0 : i.bank) == null ? void 0 : o.bank_name} - ****${(v = i == null ? void 0 : i.bank) == null ? void 0 : v.number.slice(-4)}` : "-",
                                  logo: Q[(d = (s = i == null ? void 0 : i.bank) == null ? void 0 : s.ifsc) == null ? void 0 : d.slice(0, 4)] || U
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(I, {
                                  label: n._({
                                    id: "x4Fb5/"
                                  }),
                                  value: i ? `₹${se(i == null ? void 0 : i.mandate_limit)}` : "-"
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(I, {
                                  label: n._({
                                    id: "MhEf8P"
                                  }),
                                  value: i ? Se[i.mandate_status].value : "-",
                                  icon: i ? Se[i.mandate_status].icon : "",
                                  iconPlacement: "left"
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(ie, {
                                  variant: "text",
                                  onClick: (l) => {
                                    l.preventDefault(), l.stopPropagation(), c(!0);
                                  },
                                  children: n._({
                                    id: "ShFidp"
                                  })
                                })
                              })
                            ]
                          })
                        }) : /* @__PURE__ */ e.jsx(e.Fragment, {
                          children: /* @__PURE__ */ e.jsx(ke, {
                            type: "notes",
                            text: n._({
                              id: "o+Qbf0"
                            }),
                            children: /* @__PURE__ */ e.jsx(ie, {
                              variant: "text",
                              onClick: (l) => {
                                l.preventDefault(), l.stopPropagation(), c(!0);
                              },
                              children: n._({
                                id: "uhQPR2"
                              })
                            })
                          })
                        }),
                        a && /* @__PURE__ */ e.jsx("div", {
                          children: /* @__PURE__ */ e.jsx(re, {
                            type: "error",
                            variant: "field",
                            title: a,
                            customClass: ""
                          })
                        })
                      ]
                    }) : /* @__PURE__ */ e.jsxs("div", {
                      children: [
                        /* @__PURE__ */ e.jsx(P, {
                          variant: "sub",
                          title: n._({
                            id: "bu9VTL"
                          }),
                          customClass: "mb-3"
                        }),
                        /* @__PURE__ */ e.jsx(ke, {
                          type: O ? "borderedNotes" : "notes",
                          // text="ICICI BANK (*****9872)"
                          text: O ? `${O.bank_name} - ****${O.account_number.slice(-4)}` : n._({
                            id: "Hgankl"
                          }),
                          logo: O ? Q[(h = O == null ? void 0 : O.ifsc_code) == null ? void 0 : h.slice(0, 4)] : U,
                          children: /* @__PURE__ */ e.jsx(ie, {
                            variant: "text",
                            onClick: () => {
                              t("listing_bankaccount");
                            },
                            children: O ? n._({
                              id: "ShFidp"
                            }) : n._({
                              id: "uhQPR2"
                            })
                          })
                        })
                      ]
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsxs(ze, {
                      id: "terms-id",
                      checked: N,
                      onChange: le,
                      customClass: "mb-3",
                      children: [
                        n._({
                          id: "PFNsn4"
                        }),
                        /* @__PURE__ */ e.jsx("span", {
                          children: " "
                        }),
                        /* @__PURE__ */ e.jsx("a", {
                          onClick: (l) => {
                            l.preventDefault(), l.stopPropagation(), M(!0);
                          },
                          className: "text-size-14",
                          children: n._({
                            id: "863m1W"
                          })
                        })
                      ]
                    }),
                    _ && /* @__PURE__ */ e.jsx(re, {
                      type: "error",
                      variant: "field",
                      title: "Something went wrong"
                    }),
                    /* @__PURE__ */ e.jsx(ie, {
                      variant: N && te.length ? "primary" : "disabled",
                      fullwidth: !0,
                      customClass: "mb-8",
                      type: "submit",
                      children: n._({
                        id: "AIDv2f",
                        values: {
                          0: se(j)
                        }
                      })
                    })
                  ]
                })
              ]
            })
          }),
          /* @__PURE__ */ e.jsx(ve, {
            variant: "fullPage",
            title: n._({
              id: "s4HNy/"
            }),
            isOpen: m,
            toggleModal: M,
            contentHeightMax: !0,
            children: /* @__PURE__ */ e.jsx(et, {
              disableBackButton: !0
            })
          }),
          /* @__PURE__ */ e.jsx(ve, {
            variant: "fullPage",
            title: n._({
              id: "XeOA76"
            }),
            isOpen: ee,
            toggleModal: () => {
              c(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(qe, {
              investorProfile: b,
              status: [
                "approved"
              ],
              limit: j,
              handleMandateSelection: (l) => {
                const R = ge(l);
                r(R), X(l), c(!1);
              }
            })
          })
        ]
      })
    });
  }, Te = () => {
    switch (V) {
      case "initial":
        return be();
      case "initiated":
        return /* @__PURE__ */ e.jsx(de, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ e.jsx(Pe, {
          investorProfile: b,
          handleBankSelection: (o) => {
            u(o), G([
              o
            ]), t("initial"), W();
          }
        });
      default:
        return be();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: Te()
  });
}
function pt({ investorProfile: $, id: b, showSchemeDetails: k = !0, isGroupInvestment: S = !1, action: C, onSuccess: Q }) {
  const { fpapi: T } = fe(xe), [U, j] = y(!1), [g, F] = y(""), [N, q] = y([]), [m, M] = y(null), { tenantConfig: V, amcs: t } = _e(), ee = V == null ? void 0 : V.fallbackLogo, c = V == null ? void 0 : V.showSchemeLogo, [O, u] = y({}), { collectedAcquisition: i, initiateSendOTP: X } = Ce();
  oe(() => {
    L();
  }, []);
  const L = async () => {
    var r, _;
    const p = b == null ? void 0 : b.split(",");
    j(!0);
    let f, a;
    try {
      if (f = await T.fpClient.mf_purchases().fetch(b.split(",")[0]), a = !Z(f) && await T.fpClient.payments().fetchAll({
        amc_order_ids: f == null ? void 0 : f.old_id
      }), k) {
        const D = await T.fpClient.master_data().fetchScheme(f.scheme);
        te(D);
      }
      M(f), f.state === "pending" ? F("consent") : F("consent_not_required");
      const x = {
        given_for_object: f == null ? void 0 : f.object,
        action: C,
        given_for: p
      };
      await X(x), j(!1);
    } catch (x) {
      window.debug.log(x, "error"), !Z(f) && (f == null ? void 0 : f.state) !== "pending" && ((r = a == null ? void 0 : a.payments) != null && r.length) ? (u((_ = a == null ? void 0 : a.payments) == null ? void 0 : _[0]), F("finished")) : F("error"), j(!1);
    }
  }, te = (p) => {
    const f = c ? {
      ...p,
      schemeLogo: t[p == null ? void 0 : p.amc_id] || ee
    } : {
      ...p
    };
    q(f);
  }, G = async () => {
    var p, f, a, r, _, x, D, W;
    if (j(!0), Q)
      try {
        const w = await T.fpClient.mf_purchases().update({
          id: m == null ? void 0 : m.id,
          consent: {
            email: (p = i == null ? void 0 : i.acquisition_details) == null ? void 0 : p.email,
            isd_code: (f = i == null ? void 0 : i.acquisition_details) == null ? void 0 : f.isd,
            mobile: (a = i == null ? void 0 : i.acquisition_details) == null ? void 0 : a.mobile_number
          }
        });
        Q(w), j(!1);
      } catch {
        F("error"), j(!1);
      }
    else
      try {
        const w = await T.fpClient.mf_purchases().update({
          id: m == null ? void 0 : m.id,
          consent: {
            email: (r = i == null ? void 0 : i.acquisition_details) == null ? void 0 : r.email,
            isd_code: (_ = i == null ? void 0 : i.acquisition_details) == null ? void 0 : _.isd,
            mobile: (x = i == null ? void 0 : i.acquisition_details) == null ? void 0 : x.mobile_number
          }
        });
        M(w), F("payment"), j(!1);
      } catch (w) {
        if (w && ((W = (D = w == null ? void 0 : w.data) == null ? void 0 : D.error) == null ? void 0 : W.message) === "failed to update investor consent, already exists") {
          F("payment"), j(!1);
          return;
        }
        F("error"), j(!1);
      }
  }, B = () => /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: n._({
          id: "ol5JJc"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(ue, {
        customClass: "mb-8",
        children: /* @__PURE__ */ e.jsxs("div", {
          className: "-mr-2 -ml-2  flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(I, {
                label: n._({
                  id: "4Zu577"
                }),
                value: m != null && m.amount ? `₹ ${se(m == null ? void 0 : m.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(I, {
                label: n._({
                  id: "rs9/UI"
                }),
                value: "One time"
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(I, {
                label: n._({
                  id: "cXcPd1"
                }),
                value: m != null && m.folio_number ? m == null ? void 0 : m.folio_number : "New folio"
              })
            })
          ]
        })
      })
    ]
  }), z = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: n._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ e.jsx(ae, {
        scheme: N
      })
    ]
  });
  function Y({ success: p, isWindowBlocked: f, paymentId: a }) {
    (async () => {
      if (a)
        try {
          const r = await T.fpClient.payments().fetch(Number(a));
          u(r);
          const _ = await T.fpClient.mf_purchases().fetch(m.id);
          M(_);
        } catch (r) {
          window.debug.log(r), H(r);
        }
      F(p && !f || !p && !f ? "finished" : "finished_error");
    })();
  }
  return U ? /* @__PURE__ */ e.jsx(de, {
    variant: "fullpage"
  }) : (() => {
    switch (g) {
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && z(),
            B(),
            /* @__PURE__ */ e.jsx(Fe, {
              handleSubmit: G,
              pageTitle: n._({
                id: "s/ORP1"
              }),
              isGroupInvestment: S,
              investorProfile: $,
              collectAcquisition: i
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && z(),
            B(),
            /* @__PURE__ */ e.jsx(Be, {
              title: n._({
                id: "8Erf1o"
              }),
              description: n._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && z(),
            B(),
            /* @__PURE__ */ e.jsx(Oe, {
              title: n._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: n._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                F("payment");
              }
            })
          ]
        });
      case "payment":
        return !Z($) && !Z(m) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ae, {
              scheme: N
            }),
            /* @__PURE__ */ e.jsx(tt, {
              investorProfile: $,
              mf_purchase: m,
              onCompletion: Y
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !Z(N) && !Z(m) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ae, {
              scheme: N
            }),
            /* @__PURE__ */ e.jsx(Ne, {
              investorProfile: $,
              scheme: N,
              mf_order: m,
              payment: O,
              isGroupInvestment: S
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "not_allowed":
        return /* @__PURE__ */ e.jsx(Ke, {});
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function wt({ investorProfile: $, id: b, showSchemeDetails: k = !0, isGroupInvestment: S = !1, onSuccess: C, action: Q, isFirstInstallmentPayment: T }) {
  const { fpapi: U } = fe(xe), [j, g] = y(!1), [F, N] = y(""), [q, m] = y([]), [M, V] = y(null), [t, ee] = y(null), { tenantConfig: c, amcs: O } = _e(), { collectedAcquisition: u, initiateSendOTP: i } = Ce(), X = (a) => {
    var r, _, x, D, W, w;
    if (S) {
      const K = (_ = (r = c == null ? void 0 : c.fundList) == null ? void 0 : r.find((E) => E.isin === (a == null ? void 0 : a.scheme))) == null ? void 0 : _.nfo;
      return ((x = c == null ? void 0 : c.groupInvestment) == null ? void 0 : x.days) && ((D = c == null ? void 0 : c.groupInvestment) == null ? void 0 : D.cutOffDays) && Ue({
        salaryDay: (W = c == null ? void 0 : c.groupInvestment) == null ? void 0 : W.days[0],
        salaryCutoffDay: (w = c == null ? void 0 : c.groupInvestment) == null ? void 0 : w.cutOffDays[0],
        NFO: K,
        purchaseDate: a.created_at
      });
    }
  };
  oe(() => {
    L();
  }, []);
  const L = async () => {
    const a = b == null ? void 0 : b.split(",");
    try {
      g(!0);
      const r = await U.fpClient.mf_purchase_plans().fetch(b);
      if (k) {
        const x = await U.fpClient.master_data().fetchScheme(r == null ? void 0 : r.scheme);
        te(x);
      }
      const _ = {
        given_for_object: r == null ? void 0 : r.object,
        action: Q,
        given_for: a
      };
      await i(_), ee(r), N("consent"), V(X(r)), g(!1);
    } catch (r) {
      window.debug.log(r), g(!1), N("error"), H(r);
    }
  }, te = (a) => {
    const r = c != null && c.showSchemeLogo ? {
      ...a,
      schemeLogo: O[a == null ? void 0 : a.amc_id] || (c == null ? void 0 : c.fallbackLogo)
    } : {
      ...a
    };
    m(r);
  }, G = (a) => {
    const r = new Date(a);
    return r.getDay() === 5 ? 3 : r.getDay() === 6 ? 2 : 1;
  }, B = async (a) => {
    let r;
    try {
      r = await U.fpClient.mf_purchases().fetchAll({
        plan: a == null ? void 0 : a.id
      });
    } catch (E) {
      window.debug.log(E), H(E);
    }
    if (!r.data.length)
      return;
    const _ = r.data[0], x = new Date(a == null ? void 0 : a.created_at), D = new Date(new Date(x).setDate(x.getDate() + 1)), W = new Date(new Date(x).setDate(x.getDate() + G(x))), w = new Date(_ == null ? void 0 : _.scheduled_on);
    if (new Date(_ == null ? void 0 : _.created_at).toDateString() === x.toDateString() || w.toDateString() === D.toDateString() || w.toDateString() === W.toDateString())
      return _;
  }, z = async () => {
    var a, r, _, x, D, W, w, K;
    if (C) {
      g(!0);
      try {
        await U.fpClient.mf_purchase_plans().update({
          id: t == null ? void 0 : t.id,
          consent: {
            email: (a = u == null ? void 0 : u.acquisition_details) == null ? void 0 : a.email,
            isd_code: (r = u == null ? void 0 : u.acquisition_details) == null ? void 0 : r.isd,
            mobile: (_ = u == null ? void 0 : u.acquisition_details) == null ? void 0 : _.mobile_number
          }
        });
        const E = await B(t);
        C(!0, E), g(!1);
      } catch (E) {
        if (E && ((D = (x = E == null ? void 0 : E.data) == null ? void 0 : x.error) == null ? void 0 : D.message) === "consent is already exists for the plan") {
          const le = await B(t);
          C(!0, le), g(!1);
          return;
        }
        N("error"), g(!1);
      }
    } else {
      g(!0);
      try {
        await U.fpClient.mf_purchase_plans().update({
          id: t == null ? void 0 : t.id,
          consent: {
            email: (W = u == null ? void 0 : u.acquisition_details) == null ? void 0 : W.email,
            isd_code: (w = u == null ? void 0 : u.acquisition_details) == null ? void 0 : w.isd,
            mobile: (K = u == null ? void 0 : u.acquisition_details) == null ? void 0 : K.mobile_number
          }
        }), N("success"), g(!1);
      } catch {
        N("error"), g(!1);
      }
    }
  }, Y = () => {
    var a;
    return /* @__PURE__ */ e.jsxs("div", {
      style: {
        marginTop: "-20px"
      },
      children: [
        /* @__PURE__ */ e.jsx(ue, {
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(I, {
                  label: n._({
                    id: "vOD7hD"
                  }),
                  value: t != null && t.amount ? `₹ ${se(t == null ? void 0 : t.amount, 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(I, {
                  label: n._({
                    id: "UMXPdU"
                  }),
                  value: t != null && t.frequency ? (a = Ve(t == null ? void 0 : t.frequency)) == null ? void 0 : a.replace(/_/g, " ") : "-"
                })
              }),
              t != null && t.requested_activation_date && !(t != null && t.start_date) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(I, {
                      label: n._({
                        id: "+KWVFJ"
                      }),
                      value: t != null && t.requested_activation_date ? me(new Date(t == null ? void 0 : t.requested_activation_date), "dd-MMM-yyyy") : "-"
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(I, {
                      label: n._({
                        id: "cki4uJ"
                      }),
                      value: t != null && t.installment_day ? Je(t == null ? void 0 : t.installment_day) : "-"
                    })
                  })
                ]
              }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  M != null && M.installment_date || t != null && t.start_date ? /* @__PURE__ */ e.jsx("div", {
                    className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(I, {
                      label: n._({
                        id: "NdlT2Q"
                      }),
                      value: S && He(t) ? M != null && M.installment_date ? me(new Date(M == null ? void 0 : M.installment_date), "dd-MMM-yyyy") : "-" : t != null && t.start_date ? me(new Date(t == null ? void 0 : t.start_date), "dd-MMM-yyyy") : "-"
                    })
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
                  t != null && t.end_date ? /* @__PURE__ */ e.jsx("div", {
                    className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(I, {
                      label: n._({
                        id: "gqWUiZ"
                      }),
                      value: t != null && t.end_date ? Qe(t == null ? void 0 : t.end_date) : "-"
                    })
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                ]
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(I, {
                  label: n._({
                    id: "cXcPd1"
                  }),
                  value: t != null && t.folio_number ? t == null ? void 0 : t.folio_number : "New folio"
                })
              })
            ]
          })
        }),
        T ? /* @__PURE__ */ e.jsx(re, {
          type: "important",
          variant: "flash",
          title: n._({
            id: "JbUeSI"
          }),
          customClass: "mb-5"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  }, ne = () => {
    sessionStorage.removeItem("investment_workflow_stage"), sessionStorage.removeItem("investment_workflow_order_id");
    const a = window.location.origin + Me + "/checkout?isin=" + (t == null ? void 0 : t.scheme);
    window.location.href = a;
  }, p = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(P, {
        title: n._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ e.jsx(ae, {
        scheme: q
      })
    ]
  });
  return j ? /* @__PURE__ */ e.jsx(de, {
    variant: "fullpage"
  }) : (() => {
    switch (F) {
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && p(),
            Y(),
            /* @__PURE__ */ e.jsx(Fe, {
              handleSubmit: z,
              pageTitle: n._({
                id: "s/ORP1"
              }),
              investorProfile: $,
              isGroupInvestment: S,
              collectAcquisition: u
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && p(),
            Y(),
            /* @__PURE__ */ e.jsx(Be, {
              title: n._({
                id: "8Erf1o"
              }),
              description: n._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(Oe, {
          title: n._({
            id: "kCTQHV"
          }),
          description: n._({
            id: "oahseM"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            k && p(),
            /* @__PURE__ */ e.jsx(Ne, {
              investorProfile: $,
              scheme: q,
              mf_order: t,
              handleInvestAgain: C ? ne : !1
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
export {
  wt as P,
  pt as a,
  tt as b
};
