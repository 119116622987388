import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as S, useState as c, useLayoutEffect as et, useEffect as ot } from "react";
import { AppContext as nt } from "./AppContext.js";
import { F as it } from "./provider-CNUuOe_A.js";
import { LoginContext as st } from "./LoginContext.js";
import { P as p, a as rt, b as at, D as lt } from "./index.esm-qyC9L-ym.js";
import { u as ct } from "./useConfiguration-B-lyaBB9.js";
import { r as dt } from "./sentry-Djb1pAvw.js";
import { r as ft } from "./MandateListing-7eU3x5KZ.js";
import { b as O, i as E, B as h } from "./utils-Bzskk-FI.js";
import { i as n } from "./index-C-jpp3An.js";
import { o as ut } from "./redirection-_r5ryu1X.js";
import { W as mt } from "./Welcome-HpuiFPhk.js";
import { A as pt } from "./Auth-Phxt4zVs.js";
import { P as ht, a as gt } from "./PlansListing-DUyiEwTk.js";
import { ErrorPage as _t } from "./ErrorPage.js";
import { E as wt, P as xt, S as jt } from "./StopPlanStatus-BTZy1UKz.js";
function Jt({ isGroupInvestment: x, mandate_id: z }) {
  var F;
  const { loggedInUser: g } = S(st), { AppState: { showWelcomePage: L, tenant: W }, setShowWelcomePage: B } = S(nt), { fpapi: s } = S(it), [j, J] = c({}), [T, b] = c(k), [U, f] = c(!1), [r, v] = c({}), [K, _] = c("initial"), [D, y] = c(!1), [w, N] = c({}), [G, yt] = c(!1), [H, R] = c({}), Y = [
    {
      step: 1,
      content: n._({
        id: "1ddmub"
      })
    },
    {
      step: 2,
      content: n._({
        id: "2l2YJx"
      })
    },
    {
      step: 3,
      content: n._({
        id: "05nr9e"
      })
    }
  ], { tenantConfig: u } = ct(), A = (F = u == null ? void 0 : u.groupInvestment) == null ? void 0 : F.allowedEmailDomains;
  et(() => {
    s && g && (f(!0), s.fetchUser(g.profile.preferred_username, W).then((o) => {
      if (o[0].annexure.isonboarded && o[0].annexure.isonboarded === "false") {
        f(!1);
        return;
      }
      s.getInvestorProfile().then((e) => {
        e && s.fpClient.mf_investment_accounts().fetchAll({
          investor: e.pan
        }).then((a) => {
          const l = a.data.filter((d) => d.primary_investor === g.profile.fp_identifier);
          if (!l) {
            f(!1);
            return;
          }
          x && s.fpClient.email_addresses().fetchAll({
            profile: e == null ? void 0 : e.id
          }).then((d) => {
            var m;
            if (!((m = d == null ? void 0 : d.data) == null ? void 0 : m.some((i) => {
              var M;
              return A && A[(M = i == null ? void 0 : i.email) == null ? void 0 : M.split("@")[1]];
            }))) {
              _("invalid_email_domain"), f(!1);
              return;
            }
          }), J(e), R(l[0]), f(!1);
        });
      });
    }));
  }, [
    G
  ]);
  const Z = (o) => {
    window.location.href = window.location.origin + h + "/status/mandate?id=" + o;
  }, $ = (o) => {
    window.location.href = window.location.origin + h + "/status/mandate?id=" + o;
  }, q = async (o, e) => {
    var a;
    if ((e == null ? void 0 : e.provider_name) === "BILLDESK")
      ut(o == null ? void 0 : o.token_url, "mandate-billdesk", () => Z(e == null ? void 0 : e.id), () => $(e == null ? void 0 : e.id));
    else
      try {
        const { emailData: l, phoneData: d } = await s.fetchEmailAndPhone(j), C = {
          amount: 0,
          currency: "INR",
          method: "emandate",
          bank: "ICIC",
          email: l.email,
          contact: d.number,
          order_id: (a = o.sdk_options) == null ? void 0 : a.order_id,
          customer_id: o.sdk_options.customer_id
        }, m = ft(o.sdk_options.key);
        m.createPayment(C), m.on("payment.success", function(i) {
          s.submitPayment(o.sdk_options.callback_url, i.razorpay_payment_id, i.razorpay_order_id, i.razorpay_signature).then(() => {
            window.location.href = window.location.origin + h + "/status/mandate?id=" + (e == null ? void 0 : e.id);
          });
        }), m.on("payment.error", function(i) {
          s.submitPaymentError(o.sdk_options.callback_url, "error", i.error.code, i.error.description).then(() => {
            window.location.href = window.location.origin + h + "/status/mandate?id=" + (e == null ? void 0 : e.id);
          });
        });
      } catch (l) {
        window.debug.log(l.message), dt(l);
      }
  };
  function I(o) {
    var a;
    f(!0);
    const e = {
      mandate_id: o == null ? void 0 : o.id
    };
    ((a = u == null ? void 0 : u.app_constants) == null ? void 0 : a.paymentGateway) === "BILLDESK" && (e.payment_postback_url = `${window.location.origin + h}/redirection`), s.fpClient.mandates().authorize(e).then(async (l) => {
      q(l, o);
    });
  }
  const P = () => {
    _("initial");
  }, Q = () => /* @__PURE__ */ t.jsx(at, {
    variant: "fullPage",
    title: n._({
      id: "JD6ntr",
      values: {
        0: O(w == null ? void 0 : w.id)
      }
    }),
    contentHeightMax: !0,
    isOpen: D,
    toggleModal: () => {
      y(!1);
    },
    children: /* @__PURE__ */ t.jsx(ht, {
      investorProfile: j,
      currentPlanObject: w,
      handleAuthorize: I,
      onModifySuccess: (o, e) => {
        y(!1), v(o), _(e);
      },
      isGroupInvestment: x
    })
  });
  function V() {
    var o;
    switch (K) {
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "initial":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "2yU70/"
              })
            }),
            /* @__PURE__ */ t.jsx(lt, {
              type: "text",
              text: n._({
                id: "t9eDJH"
              })
            }),
            /* @__PURE__ */ t.jsx(gt, {
              investorProfile: j,
              isGroupInvestment: x,
              mandate_id: z,
              mfInvestmentAccount: H,
              onModifySuccess: (e, a) => {
                v(e), _(a);
              },
              togglePlanDetailsModal: (e) => {
                y(!D), N(e);
              },
              handleAuthorize: I
            })
          ]
        });
      case "stop_finished":
        return !E(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "RGK6hg"
              })
            }),
            /* @__PURE__ */ t.jsx(jt, {
              currentPlanObject: r,
              handleGoToPlansList: P
            })
          ]
        });
      case "pause_finished":
        return !E(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "hNtwJw",
                values: {
                  0: (o = O(r == null ? void 0 : r.plan)) == null ? void 0 : o.toLowerCase()
                }
              })
            }),
            /* @__PURE__ */ t.jsx(xt, {
              pauseOrder: r,
              handleGoToPlansList: P
            })
          ]
        });
      case "edit_finished":
        return !E(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "LBzlW2"
              })
            }),
            /* @__PURE__ */ t.jsx(wt, {
              editOrder: r,
              handleGoToPlansList: P
            })
          ]
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ t.jsx(_t, {
          title: n._({
            id: "ISwZlJ"
          }),
          description: n._({
            id: "lwG6FE"
          }),
          showDetails: !1
        });
    }
  }
  ot(() => {
    b(k());
  }, [
    L
  ]);
  function k() {
    return L ? "welcome" : "plansList";
  }
  function X() {
    B(!1), b("plansList");
  }
  function tt() {
    return T === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(p, {
          title: n._({
            id: "SynB8s"
          })
        }),
        /* @__PURE__ */ t.jsx(mt, {
          onAck: X,
          welcomeSteps: Y
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx("div", {
        className: "mb-20",
        children: U ? /* @__PURE__ */ t.jsx(rt, {
          variant: "fullpage"
        }) : g ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            V(),
            Q()
          ]
        }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(p, {
              title: n._({
                id: "SynB8s"
              })
            }),
            /* @__PURE__ */ t.jsx(pt, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                onboarded: !0
              }
            })
          ]
        })
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: tt()
  });
}
export {
  Jt as ManagePlansList
};
