import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as he, useState as q, useEffect as Ve, useRef as He, useLayoutEffect as Mt, useMemo as Ue, useCallback as Lt, memo as $t } from "react";
import { a as rt, P as nt, D as Oe, e as W, T as be, M as S, B as Me, k as We, i as zt, j as _t, l as mt, s as Zt, m as Xt, R as Wt, S as Ht, c as ft } from "./index.esm-qyC9L-ym.js";
import { w as Je, x as fe, W as Jt, X as ht, o as ge, p as wt, Y as et, q as Gt, m as je, s as at, r as Qt, k as vt, Z as xt, u as Yt, f as Kt, e as Ft, B as kt, _ as Dt } from "./utils-Bzskk-FI.js";
import { F as Ie } from "./provider-CNUuOe_A.js";
import { r as M } from "./sentry-Djb1pAvw.js";
import { u as Ct } from "./useConfiguration-B-lyaBB9.js";
import { i as n } from "./index-C-jpp3An.js";
import { AppContext as it } from "./AppContext.js";
import { B as er } from "./BankAccountAddition-wzo9f9_g.js";
import { R as lt, F as H } from "./react-final-form.es-B_J3uV0l.js";
import { o as pt } from "./redirection-_r5ryu1X.js";
import { f as le, a as tr, r as Q, b as rr, p as nr, i as gt, j as bt, q as ar, l as jt, t as ir, u as lr, w as or, x as dr } from "./validators-BNinzCrT.js";
import { ErrorPage as sr } from "./ErrorPage.js";
const yr = ({ user: o, onCompletion: F, folioDetails: re, isGroupInvestment: T = !1, isPartner: L, kycDetails: h, isKycCompliant: a, isKycOnly: U }) => {
  var ve;
  const { fpapi: O } = he(Ie), { tenantConfig: V } = Ct(), { AppState: { tenant: c } } = he(it), [d, N] = q(null), [P, I] = q(void 0), [C, J] = q(!1), [w, A] = q(null), j = Je(o == null ? void 0 : o.annexure), Re = !a && ((ve = V == null ? void 0 : V.app_constants) == null ? void 0 : ve.cancelled_cheque_required);
  Ve(() => {
    o && (async () => {
      var E;
      try {
        J(!0), A(null);
        const [B, R] = await Promise.all([
          O.fpClient.investor_profiles().fetch(o == null ? void 0 : o.profile),
          O.fpClient.bank_accounts().fetchAll({
            profile: o == null ? void 0 : o.profile
          })
        ]), K = (E = R == null ? void 0 : R.data) == null ? void 0 : E[0];
        K && I(K), N(B);
      } catch (B) {
        window.debug.error(B), M(B), A(n._({
          id: "J56bGZ"
        }));
      } finally {
        J(!1);
      }
    })();
  }, [
    o == null ? void 0 : o.profile
  ]);
  async function Y(E) {
    if (L && !(E != null && E.cancelled_cheque))
      return;
    const B = {
      bank_account: {
        account_holder_name: h == null ? void 0 : h.name,
        account_number: E == null ? void 0 : E.account_number,
        ifsc_code: E == null ? void 0 : E.ifsc_code,
        proof: E == null ? void 0 : E.cancelled_cheque
      }
    };
    return O.fpClient.kyc_requests().update(h == null ? void 0 : h.id, B);
  }
  async function Ae(E) {
    const B = o.id, R = {
      ...j,
      completed_step: "banking_details"
    };
    E && Object.assign(R, E);
    const K = await O.updateUser(B, R, null, c);
    if (K)
      return K;
    throw new Error(K);
  }
  const we = async ({ success: E, bankAccount: B }) => {
    E || (window.debug.error("ERROR UPDATING BANK ACCOUNT DETAILS"), A(n._({
      id: "qMcu4/"
    })));
    try {
      if (J(!0), A(null), U) {
        const R = await Y(B);
        F({
          updatedKycDetails: R
        });
      } else if (a)
        await Ae(L ? {
          partner_completed_step: "banking_details"
        } : void 0), F();
      else {
        const R = await Y(B);
        await Ae(L ? {
          completed_step: R ? "banking_details" : "financial_details",
          partner_completed_step: "contact_details"
        } : void 0), F({
          updatedKycDetails: R
        });
      }
    } catch (R) {
      window.debug.error(R), M(R), fe(R) && window.location.reload(), A(n._({
        id: "qMcu4/"
      }));
    } finally {
      J(!1);
    }
  }, Be = async () => {
    try {
      J(!0), A(null);
      const E = o.id, B = o == null ? void 0 : o.annexure;
      L ? B.partner_completed_step = "personal_details" : B.completed_step = "personal_details", await O.updateUser(E, B, null, c), F();
    } catch (E) {
      window.debug.error(E), M(E), A(n._({
        id: "qMcu4/"
      }));
    } finally {
      J(!1);
    }
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      C && /* @__PURE__ */ e.jsx(rt, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(er, {
        investorProfile: d,
        onCompletion: we,
        isPartner: L,
        kycDetails: h,
        needsCancelledCheque: Re,
        isGroupInvestment: T,
        formInitialValues: P,
        handleGoBack: U ? void 0 : Be,
        formError: w,
        folioDetails: re
      })
    ]
  });
}, cr = () => {
  const { fpapi: o } = he(Ie), F = (h) => h.sort((a, U) => Number(new Date(U.updated_at)) - Number(new Date(a.updated_at)));
  function re(h, a) {
    return (h == null ? void 0 : h.cancelled_cheque) && (a == null ? void 0 : a.status) === "completed" && (a == null ? void 0 : a.reason) === "digital_verification" && (a == null ? void 0 : a.confidence) === "zero";
  }
  function T(h, a) {
    return !(h != null && h.cancelled_cheque) && // no cancelled cheque
    (a == null ? void 0 : a.status) === "completed" && // verification status is complete
    (a == null ? void 0 : a.reason) === "digital_verification" && // reason is digital_verification
    (a == null ? void 0 : a.confidence) === "zero";
  }
  async function L(h) {
    if (h) {
      const a = [], U = [], O = [], V = [], c = [], d = [], N = h == null ? void 0 : h.map((I) => {
        const C = I.identifier ? String(I.identifier) : String(I.id);
        return o.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            C
          ]
        });
      });
      return (await Promise.allSettled(N)).forEach((I, C) => {
        var J;
        if (I.status === "fulfilled") {
          const w = {
            ...h[C]
          }, A = (J = I == null ? void 0 : I.value) == null ? void 0 : J.data[0];
          A ? A.status === "completed" ? re(w, A) ? O.push(w) : T(w, A) ? V.push(w) : a.push(w) : A.status === "pending" ? U.push(w) : A.status === "failed" && c.push(w) : d.push(w);
        } else if (I.status === "rejected")
          throw window.debug.error(I), I;
      }), {
        COMPLETE: [
          ...F(a)
        ],
        PENDING: [
          ...F(U)
        ],
        MANUAL_VERIFICATION_PENDING: [
          ...F(O)
        ],
        COLLECT_CANCELLED_CHEQUE: [
          ...F(V)
        ],
        FAILED: [
          ...F(c)
        ],
        NOVERIFICATION: [
          ...F(d)
        ]
      };
    } else
      throw new Error(n._({
        id: "VhBqMw"
      }));
  }
  return {
    getBankAccountsByVerificationStatus: L
  };
}, Nr = ({ user: o, onCompletion: F, folioDetails: re, kycCheckData: T, isPartner: L, isGroupInvestment: h, kycDetails: a, isKycCompliant: U, isKycOnly: O }) => {
  var ut;
  const V = He(null), c = o == null ? void 0 : o.id, d = Je(o == null ? void 0 : o.annexure), N = !O && h, { tenantConfig: P } = Ct(), I = (ut = P == null ? void 0 : P.groupInvestment) == null ? void 0 : ut.allowedEmailDomains, { fpapi: C } = he(Ie), { AppState: { tenant: J } } = he(it), [w, A] = q(null), [j, Re] = q(null), [Y, Ae] = q(null), [we, Be] = q(null), [ve, E] = q(null), [B, R] = q(null), [K, $] = q(null), [Ge, z] = q(!0), [Le, Fe] = q(!1), [Qe, $e] = q(!1), [v, _] = q(null), i = {}, { getBankAccountsByVerificationStatus: g } = cr(), [Z, ce] = q({
    address_proof_back: null,
    address_proof_front: null
  }), X = He({
    address_proof_back: !1,
    address_proof_front: !1
  }), de = {}, Se = {
    saveAddressProofSelection(r) {
      sessionStorage.setItem("address_proof", r);
    },
    getAddressProofSelection() {
      return sessionStorage.getItem("address_proof");
    },
    deleteAddressProofSelection() {
      sessionStorage.removeItem("address_proof");
    }
  }, Ye = () => {
    var t, s;
    let r = null;
    if (T) {
      const u = (t = T == null ? void 0 : T.entity_details) == null ? void 0 : t.correspondence_address, f = et(u == null ? void 0 : u.line_1, u == null ? void 0 : u.line_2, u == null ? void 0 : u.line_3);
      r = {
        // current addresses
        current_pincode: u != null && u.pincode ? u == null ? void 0 : u.pincode : null,
        current_residence_line1: f,
        email: (s = T == null ? void 0 : T.entity_details) == null ? void 0 : s.email
      };
    }
    return r;
  };
  Ve(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const ze = () => {
    Fe(!1);
  }, ue = () => {
    Fe(!1), $e(!0);
  };
  async function Ze() {
    if (a) {
      Qt.removeRedirectionState();
      const { data: r } = await C.fpClient.identity_documents().fetchAll({
        kyc_request: a == null ? void 0 : a.id
      }), t = r != null && r.length ? r[0] : null;
      return t && _(t), t;
    }
  }
  Ve(() => {
    (async () => {
      z(!0);
      try {
        await Ze();
      } catch (r) {
        window.debug.error(r), M(r), $(n._({
          id: "P/x4dd"
        })), z(!1);
        return;
      }
    })();
  }, [
    a
  ]), Mt(() => {
    (async () => {
      var r;
      try {
        z(!0);
        let t, s, u;
        if (o) {
          const x = await C.fpClient.investor_profiles().fetch(o == null ? void 0 : o.profile);
          t = await C.fpClient.email_addresses().fetchAll({
            profile: x == null ? void 0 : x.id
          }), s = await C.fpClient.phone_numbers().fetchAll({
            profile: x == null ? void 0 : x.id
          }), u = await C.fpClient.addresses().fetchAll({
            profile: x == null ? void 0 : x.id
          });
        }
        const { countries: f } = await C.fpClient.master_data().fetchCountries();
        if (Et(a)) {
          const x = [
            dt(a.address.proof),
            dt(a.address.proof_back)
          ], [p, b] = await Promise.all(x);
          Be(p == null ? void 0 : p.file), E(b == null ? void 0 : b.file), ce({
            address_proof_back: b == null ? void 0 : b.fileObj,
            address_proof_front: p == null ? void 0 : p.fileObj
          });
        }
        A(t == null ? void 0 : t.data), Re(s == null ? void 0 : s.data[0]), Ae((r = u == null ? void 0 : u.data) != null && r.length ? u == null ? void 0 : u.data : null), R(f);
      } catch (t) {
        window.debug.error(t), $(n._({
          id: "J56bGZ"
        })), M(t);
      } finally {
        z(!1);
      }
    })();
  }, [
    C == null ? void 0 : C.fpClient,
    o == null ? void 0 : o.profile
  ]);
  const ot = {
    "image/jpeg": 1,
    "image/png": 1,
    "image/jpg": 1,
    "application/pdf": 1
  }, Nt = Ue(() => ({
    driving_licence: 1,
    passport: 1
  }), []), Pe = Ue(() => [
    {
      label: n._({
        id: "mzsu4o"
      }),
      value: "aadhaar"
    },
    {
      label: n._({
        id: "nk8msg"
      }),
      value: "driving_licence"
    },
    {
      label: n._({
        id: "gFyr5x"
      }),
      value: "passport"
    },
    {
      label: n._({
        id: "Lp31ma"
      }),
      value: "voter_id"
    }
  ], []), Et = (r) => [
    "proof",
    "proof_back"
  ].every((s) => {
    var u;
    return (u = r == null ? void 0 : r.address) == null ? void 0 : u[s];
  }), dt = async (r) => {
    const t = await C.fpClient.files().fetch(r), s = t.url, f = await (await fetch(s)).blob();
    return {
      file: new File([
        f
      ], t == null ? void 0 : t.filename, {
        type: f.type
      }),
      fileObj: t
    };
  }, se = Ue(() => {
    var f, x, p, b, G, ne, _e, l, y, ae, Ce, ye, D, ee, Ne, Ee, qe, xe, te, m;
    const r = Ye();
    let t = {
      //perm_addr_is_corres_addr: "Yes",
      email: h ? Jt(I, w) : ht((f = w == null ? void 0 : w[0]) == null ? void 0 : f.email) ? "" : (x = w == null ? void 0 : w[0]) == null ? void 0 : x.email,
      phone: ht(j == null ? void 0 : j.number) ? "" : j == null ? void 0 : j.number,
      current_pincode: null,
      current_residence_line1: null,
      current_residence_line2: null,
      current_residence_line3: null,
      permanent_pincode: null,
      permanent_residence_line1: null,
      permanent_residence_line2: null,
      permanent_residence_line3: null
    };
    if (O && (t.email = a == null ? void 0 : a.email, t.phone = (p = a == null ? void 0 : a.mobile) == null ? void 0 : p.number), r && (t.current_pincode = (r == null ? void 0 : r.current_pincode) || t.current_pincode, t.current_residence_line1 = (r == null ? void 0 : r.current_residence_line1) || t.current_residence_line1, t.current_residence_line2 = (r == null ? void 0 : r.current_residence_line2) || t.current_residence_line2, t.current_residence_line3 = (r == null ? void 0 : r.current_residence_line3) || t.current_residence_line3, t.permanent_pincode = (r == null ? void 0 : r.permanent_pincode) || t.permanent_pincode, t.permanent_residence_line1 = (r == null ? void 0 : r.permanent_residence_line1) || t.permanent_residence_line1, t.permanent_residence_line2 = (r == null ? void 0 : r.permanent_residence_line2) || t.permanent_residence_line2, t.permanent_residence_line3 = (r == null ? void 0 : r.permanent_residence_line3) || t.permanent_residence_line3, t.email = r.email || t.email), a) {
      const ie = {
        email: {
          initFieldName: "email",
          initFieldValue: (h ? null : a == null ? void 0 : a.email) || (t == null ? void 0 : t.email)
        },
        number: {
          initFieldName: "phone",
          initFieldValue: ((b = a == null ? void 0 : a.mobile) == null ? void 0 : b.number) || (t == null ? void 0 : t.phone)
        },
        pincode: {
          initFieldName: "current_pincode",
          initFieldValue: ((G = a == null ? void 0 : a.address) == null ? void 0 : G.pincode) || (t == null ? void 0 : t.current_pincode)
        },
        line_1: {
          initFieldName: "current_residence_line1",
          initFieldValue: ((ne = a == null ? void 0 : a.address) == null ? void 0 : ne.line_1) || (t == null ? void 0 : t.current_residence_line1)
        },
        line_2: {
          initFieldName: "current_residence_line2",
          initFieldValue: ((_e = a == null ? void 0 : a.address) == null ? void 0 : _e.line_2) || (t == null ? void 0 : t.current_residence_line2)
        },
        line_3: {
          initFieldName: "current_residence_line3",
          initFieldValue: ((l = a == null ? void 0 : a.address) == null ? void 0 : l.line_3) || (t == null ? void 0 : t.current_residence_line3)
        },
        proof_type: {
          initFieldName: "address_proof_type",
          initFieldValue: Pe[ge(Pe, (y = a == null ? void 0 : a.address) == null ? void 0 : y.proof_type)]
        },
        proof_number: {
          initFieldName: "address_proof_number",
          initFieldValue: (ae = a == null ? void 0 : a.address) == null ? void 0 : ae.proof_number
        },
        proof_issue_date: {
          initFieldName: "address_proof_issue_date",
          initFieldValue: (Ce = a == null ? void 0 : a.address) == null ? void 0 : Ce.proof_issue_date
        },
        proof_expiry_date: {
          initFieldName: "address_proof_expiry_date",
          initFieldValue: (ye = a == null ? void 0 : a.address) == null ? void 0 : ye.proof_expiry_date
        }
      };
      ((D = a == null ? void 0 : a.address) == null ? void 0 : D.proof_type) !== "aadhaar" && (ie.aadhaar_number = {
        initFieldName: "aadhaar_number",
        initFieldValue: (a == null ? void 0 : a.aadhaar_number) || null
      });
      const me = wt(ie, a);
      t.address_proof_type !== "aadhaar" && we && ve && (t.address_proof_front = we, t.address_proof_back = ve), t = {
        ...t,
        ...me
      };
    }
    const s = Y != null && Y.length ? Y[(Y == null ? void 0 : Y.length) - 1] : null;
    if (s && !O && (t.current_pincode = (s == null ? void 0 : s.postal_code) || (t == null ? void 0 : t.current_pincode), t.current_residence_line1 = (s == null ? void 0 : s.line1) || (t == null ? void 0 : t.current_residence_line1), t.current_residence_line2 = (s == null ? void 0 : s.line2) || (t == null ? void 0 : t.current_residence_line2), t.current_residence_line3 = (s == null ? void 0 : s.line3) || (t == null ? void 0 : t.current_residence_line3)), v && (Se.getAddressProofSelection() === "aadhaar" || [
      null,
      void 0,
      "aadhaar"
    ].includes((ee = a == null ? void 0 : a.address) == null ? void 0 : ee.proof_type))) {
      const me = ((Ne = v == null ? void 0 : v.fetch) == null ? void 0 : Ne.status) === "successful" && ((Ee = v == null ? void 0 : v.data) == null ? void 0 : Ee.pincode) || "", oe = et((qe = v == null ? void 0 : v.data) == null ? void 0 : qe.line_1, (xe = v == null ? void 0 : v.data) == null ? void 0 : xe.line_2, (te = v == null ? void 0 : v.data) == null ? void 0 : te.line_3);
      t.address_proof_type = Pe[ge(Pe, "aadhaar")], t.current_pincode = me, t.current_residence_line1 = oe, N && (w != null && w.length) && (t.email = (m = w == null ? void 0 : w[0]) == null ? void 0 : m.email);
    }
    return t;
  }, [
    w,
    j == null ? void 0 : j.number,
    Pe,
    a,
    v,
    ve,
    we
  ]), At = async (r) => {
    var u;
    const t = [], s = a.pan;
    for (let f = 0; f < r.length; f++) {
      const x = r[f].purpose;
      try {
        const p = r[f].value, b = (u = p == null ? void 0 : p.type) == null ? void 0 : u.split("/")[1], G = new FormData();
        G.append("file", p, `${s}_${x}.${b}`), G.append("purpose", x), t.push(C.fpClient.files().create(G));
      } catch (p) {
        window.debug.error(p), t.push(Promise.reject(p)), M(p);
      }
    }
    return t;
  }, Bt = (r) => {
    const t = {
      address_proof_front: "",
      address_proof_back: ""
    };
    for (let s = 0; s < r.length; s++)
      r[s].status === "fulfilled" ? t[`${r[s].value.purpose}`] = r[s].value.id : r[s].reason;
    return t;
  };
  async function St(r) {
    var f;
    const t = await At(r), s = await Promise.allSettled(t), u = Bt(s);
    if ((f = Object.keys(i)) != null && f.length)
      throw i;
    return u;
  }
  async function Xe(r) {
    return C.fpClient.kyc_requests().update(a.id, r);
  }
  async function Pt(r) {
    return C.fpClient.master_data().fetchPincode(r);
  }
  function st(r) {
    const { profile: t, line1: s, line2: u, line3: f, city: x, state: p, postal_code: b, country: G } = r ?? {};
    return `${t ?? ""}${s ?? ""}${u ?? ""}${f ?? ""}${x ?? ""}${p ?? ""}${b ?? ""}${G ?? ""}`.toLowerCase();
  }
  async function Ke(r) {
    if (!(r != null && r.state)) {
      const u = await Pt(r.postal_code);
      r.state = u == null ? void 0 : u.state_name;
    }
    const t = st(r), s = Y == null ? void 0 : Y.find((u) => st(u) === t);
    return s || C.fpClient.addresses().create(r);
  }
  async function ct(r) {
    if (r && (w != null && w.length)) {
      const t = w == null ? void 0 : w.find((u) => (u == null ? void 0 : u.email) === r);
      return t || C.fpClient.email_addresses().create({
        profile: o == null ? void 0 : o.profile,
        email: r,
        belongs_to: "self"
      });
    } else
      return;
  }
  async function ke(r) {
    const t = {
      ...d,
      completed_step: "contact_details",
      ...r
    }, s = await C.updateUser(c, t, null, J);
    if (s)
      return s;
    throw new Error(s);
  }
  async function Tt() {
    var u, f, x, p;
    const r = await Ze(), t = (u = r == null ? void 0 : r.fetch) == null ? void 0 : u.status;
    if (!(t === "successful" || t === "pending")) {
      const b = await C.fpClient.identity_documents().create({
        kyc_request: a == null ? void 0 : a.id,
        type: "aadhaar",
        postback_url: `${window.location.origin + kt}/redirection`
      }), G = (f = b == null ? void 0 : b.fetch) == null ? void 0 : f.status, ne = (x = b == null ? void 0 : b.fetch) == null ? void 0 : x.redirect_url;
      if (_(b), G === "pending" && ne)
        Fe(!0), pt(ne, "kyc-identity-document", ze, ue);
      else
        throw b;
    } else if (t === "pending") {
      const b = (p = r == null ? void 0 : r.fetch) == null ? void 0 : p.redirect_url;
      Fe(!0), pt(b, "kyc-identity-document", ze, ue);
    }
  }
  const Vt = async (r) => {
    var t, s;
    z(!0);
    try {
      if (N && !await ct(r))
        throw new Error("Error updating email");
      try {
        await Tt();
      } catch (u) {
        $((s = (t = u == null ? void 0 : u.data) == null ? void 0 : t.error) == null ? void 0 : s.message), xt();
      }
    } catch {
      $(n._({
        id: "PsUTl7"
      })), xt();
    }
    z(!1);
  }, De = async (r, t, s, u) => {
    var y, ae;
    const f = r == null ? void 0 : r.profile, x = Je(r == null ? void 0 : r.annexure), p = await C.fpClient.bank_accounts().fetchAll({
      profile: f
    }), b = await g(p.data), G = (ae = (y = b == null ? void 0 : b.COMPLETE) == null ? void 0 : y[0]) == null ? void 0 : ae.id, ne = (x == null ? void 0 : x.verified_bac) || G, _e = {
      primary_investor: f,
      holding_pattern: "single",
      folio_defaults: {
        communication_email_address: t,
        communication_mobile_number: s,
        communication_address: u,
        payout_bank_account: ne || null
      }
    }, l = await It(r);
    if (l)
      return await C.fpClient.mf_investment_accounts().update({
        id: l == null ? void 0 : l.id,
        folio_defaults: _e.folio_defaults
      });
  }, It = async (r) => {
    var p;
    const t = (p = r == null ? void 0 : r.username) == null ? void 0 : p.toUpperCase(), s = r == null ? void 0 : r.profile;
    let u;
    const f = await C.fpClient.mf_investment_accounts().fetchAll({
      investor: t
    }), x = f == null ? void 0 : f.data;
    return x != null && x.length && (u = x.find((b) => b.primary_investor === s)), u;
  }, Rt = async ({ email: r, current_residence_line1: t, current_residence_line2: s, current_residence_line3: u, current_pincode: f, current_location: x, address_proof_type: p, address_proof_number: b, address_proof_issue_date: G, address_proof_expiry_date: ne, address_proof_front: _e, address_proof_back: l, aadhaar_number: y }) => {
    var Ne, Ee, qe;
    const [ae, Ce, ye] = (x == null ? void 0 : x.split(", ")) || [], D = {
      profile: o == null ? void 0 : o.profile,
      city: ae,
      state: Ce,
      postal_code: f,
      country: ye,
      line1: t || null,
      line2: s || null,
      line3: u || null
    }, ee = await ct(r);
    //! Add try catch
    if (U)
      try {
        z(!0);
        const xe = await Ke(D);
        await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, xe.id), await ke({
          partner_completed_step: "contact_details"
        }), F();
      } catch (xe) {
        window.debug.error(xe), $(n._({
          id: "qMcu4/"
        })), M(xe);
      } finally {
        z(!1);
      }
    else if ((p == null ? void 0 : p.value) === "aadhaar")
      try {
        if (z(!0), ((Ne = v == null ? void 0 : v.fetch) == null ? void 0 : Ne.status) === "successful") {
          const { data: m, id: ie } = v, me = {
            email: r,
            aadhaar_number: m == null ? void 0 : m.number.slice(-4),
            address: {
              proof_type: "aadhaar",
              proof: ie,
              line_1: m == null ? void 0 : m.line_1,
              line_2: m == null ? void 0 : m.line_2,
              line_3: m == null ? void 0 : m.line_3,
              city: m == null ? void 0 : m.city,
              pincode: m == null ? void 0 : m.pincode,
              country: m == null ? void 0 : m.country
            }
          };
          if (O)
            try {
              const oe = await Xe(me);
              F({
                updatedKycDetails: oe
              });
            } catch (oe) {
              window.debug.error(oe), z(!1), $(n._({
                id: "qMcu4/"
              })), M(oe), fe(oe) && window.location.reload();
              return;
            }
          else {
            const oe = {
              profile: o == null ? void 0 : o.profile,
              city: m == null ? void 0 : m.city,
              state: null,
              postal_code: m == null ? void 0 : m.pincode,
              country: m == null ? void 0 : m.country.toUpperCase(),
              line1: m == null ? void 0 : m.line_1,
              line2: (m == null ? void 0 : m.line_2) || null,
              line3: (m == null ? void 0 : m.line_3) || null
            };
            try {
              const pe = [
                Xe(me),
                Ke(oe)
              ], [k, Te] = await Promise.all(pe);
              await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, Te.id), await ke(), F({
                updatedKycDetails: k
              });
            } catch (pe) {
              window.debug.error(pe), z(!1), $(n._({
                id: "qMcu4/"
              })), M(pe), fe(pe) && window.location.reload();
              return;
            }
          }
        } else
          throw new Error("identity document is not ready");
      } catch (te) {
        window.debug.error(te), $(n._({
          id: "qMcu4/"
        })), M(te), fe(te) && window.location.reload();
      } finally {
        z(!1);
      }
    else
      try {
        z(!0);
        const te = [
          {
            purpose: "address_proof_front",
            value: _e
          },
          {
            purpose: "address_proof_back",
            value: l
          }
        ].filter((k) => {
          var Te;
          return !((Te = X == null ? void 0 : X.current) != null && Te[k.purpose]);
        }), m = await St(te), ie = {
          address_proof_front: (Ee = Z == null ? void 0 : Z.address_proof_front) == null ? void 0 : Ee.id,
          address_proof_back: (qe = Z == null ? void 0 : Z.address_proof_back) == null ? void 0 : qe.id
        }, me = [
          "voter_id",
          "aadhaar"
        ], oe = {
          line_1: D.line1,
          line_2: D.line2,
          line_3: D.line3,
          city: D.city,
          pincode: D.postal_code,
          country: D.country.toLowerCase(),
          proof: (m == null ? void 0 : m.address_proof_front) || (ie == null ? void 0 : ie.address_proof_front),
          proof_back: (m == null ? void 0 : m.address_proof_back) || (ie == null ? void 0 : ie.address_proof_back),
          proof_type: p.value,
          proof_number: b,
          proof_issue_date: me.includes(p.value) ? null : vt(G, "yyyy-mm-dd"),
          proof_expiry_date: me.includes(p.value) ? null : vt(ne, "yyyy-mm-dd")
        }, pe = {
          email: r,
          aadhaar_number: y,
          address: oe
        };
        if (O)
          try {
            const k = await Xe(pe);
            F({
              updatedKycDetails: k
            });
          } catch (k) {
            window.debug.error(k), $(n._({
              id: "qMcu4/"
            })), M(k), fe(k) && window.location.reload();
          }
        else
          try {
            const k = [
              Xe(pe),
              Ke(D)
            ], [Te, Ot] = await Promise.all(k);
            await De(o, ee == null ? void 0 : ee.id, j == null ? void 0 : j.id, Ot.id), await ke(), F({
              updatedKycDetails: Te
            });
          } catch (k) {
            window.debug.error(k), $(n._({
              id: "qMcu4/"
            })), M(k), fe(k) && window.location.reload();
          }
      } catch (te) {
        window.debug.error(te), $(n._({
          id: "tWA+Oc"
        })), M(te), fe(te) && window.location.reload();
      } finally {
        z(!1);
      }
    Se.deleteAddressProofSelection();
  }, qt = (r) => {
    var s, u;
    const t = (u = (s = r == null ? void 0 : r.values) == null ? void 0 : s.address_proof_type) == null ? void 0 : u.label;
    return U ? /* @__PURE__ */ e.jsx(tt, {
      countries: B,
      addressType: "current",
      formRef: V,
      kycCheckData: T,
      kycDetails: a,
      addressProofTypeLabel: "Communication address"
    }) : de.address ? null : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: n._({
              id: "FBMtAv"
            })
          }),
          /* @__PURE__ */ e.jsx(H, {
            name: "address_proof_type",
            validate: Q,
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (f) => {
              var x, p, b, G, ne, _e;
              return /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(We, {
                    name: f.input.name,
                    value: f.input.value,
                    onChange: (l) => {
                      var ae, Ce, ye, D;
                      $(""), Se.saveAddressProofSelection((l == null ? void 0 : l.value) ?? "");
                      const y = () => {
                        r.form.mutators.mutateField("current_pincode", void 0), r.form.mutators.mutateField("current_residence_line1", void 0), r.form.mutators.mutateField("current_residence_line2", void 0), r.form.mutators.mutateField("current_residence_line3", void 0), r.form.mutators.mutateField("address_proof_number", void 0), r.form.mutators.mutateField("address_proof_issue_date", void 0), r.form.mutators.mutateField("address_proof_expiry_date", void 0), r.form.mutators.mutateField("address_proof_front", void 0), r.form.mutators.mutateField("address_proof_back", void 0), r.form.mutators.mutateField("aadhaar_number", void 0), V.current.change("current_location", void 0);
                      };
                      if ((l == null ? void 0 : l.value) === "aadhaar" && v) {
                        const ee = et((ae = v == null ? void 0 : v.data) == null ? void 0 : ae.line_1, (Ce = v == null ? void 0 : v.data) == null ? void 0 : Ce.line_2, (ye = v == null ? void 0 : v.data) == null ? void 0 : ye.line_3), Ne = ((D = v == null ? void 0 : v.data) == null ? void 0 : D.pincode) || (se == null ? void 0 : se.current_pincode), Ee = ee || (se == null ? void 0 : se.current_pincode);
                        r.form.mutators.mutateField("current_pincode", Ne), r.form.mutators.mutateField("current_residence_line1", Ee);
                      } else
                        y();
                      f.input.onChange(l);
                    },
                    options: Pe,
                    status: f.meta.error && f.meta.touched ? "error" : void 0,
                    placeholder: "Select address proof type"
                  }),
                  (x = f.input.value) != null && x.value ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(zt, {
                    variant: "note",
                    message: "Provide communication address along with proof documents"
                  }),
                  f.meta.error && f.meta.touched && /* @__PURE__ */ e.jsx(S, {
                    type: "error",
                    variant: "field",
                    title: f.meta.error
                  }),
                  ((p = f.input.value) == null ? void 0 : p.value) !== "aadhaar" ? /* @__PURE__ */ e.jsx(e.Fragment, {
                    children: /* @__PURE__ */ e.jsxs("div", {
                      className: "mt-8",
                      children: [
                        /* @__PURE__ */ e.jsx(tt, {
                          countries: B,
                          addressType: "current",
                          formRef: V,
                          kycCheckData: T,
                          kycDetails: a,
                          addressProofTypeLabel: t
                        }),
                        /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            de.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(W, {
                                  message: n._({
                                    id: "YYnvo5",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(H, {
                                  name: "address_proof_number",
                                  parse: (l) => l.toUpperCase(),
                                  validate: le(Q, nr((b = f.input.value) == null ? void 0 : b.value, n._({
                                    id: "UqiTgL"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0
                                  },
                                  validateFields: [],
                                  children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                    children: [
                                      /* @__PURE__ */ e.jsx(be, {
                                        id: l.input.name,
                                        autoComplete: "off",
                                        name: l.input.name,
                                        value: l.input.value,
                                        onChange: l.input.onChange,
                                        onBlur: () => {
                                          l.input.onChange(l.input.value.trim());
                                        },
                                        onInput: (y) => {
                                          y.target.value = y.target.value.replace(je("A-Za-z0-9 \\-"), "");
                                        },
                                        maxLength: 16,
                                        status: l.meta.error && l.meta.touched ? "error" : void 0
                                      }),
                                      l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(S, {
                                        type: "error",
                                        variant: "field",
                                        title: l.meta.error
                                      })
                                    ]
                                  })
                                })
                              ]
                            }) : null,
                            de.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(W, {
                                  message: n._({
                                    id: "poOg/l",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(Oe, {
                                  type: "labelDesc",
                                  text: n._({
                                    id: "S+MkRj"
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(H, {
                                  name: "address_proof_front",
                                  validate: le(Q, bt(5, n._({
                                    id: "Jw6xX2"
                                  })), gt(ot, n._({
                                    id: "ULNkDS"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0,
                                    pristine: !0
                                  },
                                  validateFields: [],
                                  children: (l) => {
                                    var y;
                                    return X.current = {
                                      ...X.current,
                                      address_proof_front: ((y = l == null ? void 0 : l.meta) == null ? void 0 : y.pristine) ?? !1
                                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                                      children: [
                                        /* @__PURE__ */ e.jsx(_t, {
                                          inputName: "address_proof_front",
                                          customClass: "customClassName",
                                          handleFile: l.input.onChange,
                                          value: l.input.value
                                        }),
                                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx(S, {
                                          type: "error",
                                          variant: "field",
                                          title: l.meta.error
                                        }) : /* @__PURE__ */ e.jsx(S, {
                                          type: "helper",
                                          variant: "field",
                                          title: n._({
                                            id: "OIjqUC"
                                          })
                                        })
                                      ]
                                    });
                                  }
                                })
                              ]
                            }) : null,
                            de.address ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8",
                              children: [
                                /* @__PURE__ */ e.jsx(W, {
                                  message: n._({
                                    id: "MQgLMg",
                                    values: {
                                      addressProofTypeLabel: t
                                    }
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(Oe, {
                                  type: "labelDesc",
                                  text: n._({
                                    id: "kbnQeU"
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(H, {
                                  name: "address_proof_back",
                                  validate: le(Q, bt(5, n._({
                                    id: "Jw6xX2"
                                  })), gt(ot, n._({
                                    id: "ULNkDS"
                                  }))),
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0,
                                    pristine: !0
                                  },
                                  validateFields: [],
                                  children: (l) => {
                                    var y;
                                    return X.current = {
                                      ...X.current,
                                      address_proof_back: ((y = l == null ? void 0 : l.meta) == null ? void 0 : y.pristine) ?? !1
                                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                                      children: [
                                        /* @__PURE__ */ e.jsx(_t, {
                                          inputName: "address_proof_back",
                                          customClass: "customClassName",
                                          handleFile: l.input.onChange,
                                          value: l.input.value
                                        }),
                                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx(S, {
                                          type: "error",
                                          variant: "field",
                                          title: l.meta.error
                                        }) : /* @__PURE__ */ e.jsx(S, {
                                          type: "helper",
                                          variant: "field",
                                          title: n._({
                                            id: "OIjqUC"
                                          })
                                        })
                                      ]
                                    });
                                  }
                                })
                              ]
                            }) : null,
                            Nt[(G = f.input.value) == null ? void 0 : G.value] && /* @__PURE__ */ e.jsxs(e.Fragment, {
                              children: [
                                de.address ? null : /* @__PURE__ */ e.jsxs("div", {
                                  className: "mb-8",
                                  children: [
                                    /* @__PURE__ */ e.jsx(W, {
                                      message: n._({
                                        id: "cLP1gb",
                                        values: {
                                          addressProofTypeLabel: t
                                        }
                                      })
                                    }),
                                    /* @__PURE__ */ e.jsx(H, {
                                      name: "address_proof_issue_date",
                                      validate: le(Q, jt, ar(n._({
                                        id: "DsBANV"
                                      }))),
                                      subscription: {
                                        touched: !0,
                                        error: !0,
                                        value: !0
                                      },
                                      validateFields: [],
                                      children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                        children: [
                                          /* @__PURE__ */ e.jsx(mt, {
                                            name: l.input.name,
                                            variant: "keyboardInputDOB",
                                            format: "dd-MMM-yyyy",
                                            placeholder: n._({
                                              id: "cJxwfA"
                                            }),
                                            selectedDate: l.input.value || null,
                                            error: l.meta.error && l.meta.touched,
                                            helperText: null,
                                            onKeyDown: (y) => y.preventDefault(),
                                            handleDateChange: (y, ae) => l.input.onChange(y)
                                          }),
                                          l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(S, {
                                            type: "error",
                                            variant: "field",
                                            title: l.meta.error
                                          })
                                        ]
                                      })
                                    })
                                  ]
                                }),
                                de.address ? null : /* @__PURE__ */ e.jsxs("div", {
                                  className: "mb-8",
                                  children: [
                                    /* @__PURE__ */ e.jsx(W, {
                                      message: n._({
                                        id: "t2Bz9f",
                                        values: {
                                          addressProofTypeLabel: t
                                        }
                                      })
                                    }),
                                    /* @__PURE__ */ e.jsx(H, {
                                      name: "address_proof_expiry_date",
                                      validate: le(Q, jt, ir(n._({
                                        id: "p11m02"
                                      }))),
                                      subscription: {
                                        touched: !0,
                                        error: !0,
                                        value: !0
                                      },
                                      validateFields: [],
                                      children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                        children: [
                                          /* @__PURE__ */ e.jsx(mt, {
                                            name: l.input.name,
                                            variant: "keyboardInputDOB",
                                            format: "dd-MMM-yyyy",
                                            placeholder: n._({
                                              id: "Rr5/yP"
                                            }),
                                            selectedDate: l.input.value || null,
                                            disablePast: !0,
                                            disableFuture: !1,
                                            error: l.meta.error && l.meta.touched,
                                            helperText: null,
                                            onKeyDown: (y) => y.preventDefault(),
                                            handleDateChange: (y, ae) => l.input.onChange(y)
                                          }),
                                          l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(S, {
                                            type: "error",
                                            variant: "field",
                                            title: l.meta.error
                                          })
                                        ]
                                      })
                                    })
                                  ]
                                })
                              ]
                            }),
                            de.aadhaar_number ? null : t ? /* @__PURE__ */ e.jsxs("div", {
                              className: "mb-8 mt-8",
                              children: [
                                /* @__PURE__ */ e.jsx(W, {
                                  message: "Last 4 digits of Aadhaar number"
                                }),
                                /* @__PURE__ */ e.jsx(H, {
                                  name: "aadhaar_number",
                                  validate: Q,
                                  subscription: {
                                    touched: !0,
                                    error: !0,
                                    value: !0
                                  },
                                  validateFields: [],
                                  children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                    children: [
                                      /* @__PURE__ */ e.jsx(be, {
                                        id: l.input.name,
                                        autoComplete: "off",
                                        name: l.input.name,
                                        value: l.input.value,
                                        onChange: l.input.onChange,
                                        placeholder: "Enter the last 4 digits of your aadhaar number",
                                        onInput: (y) => {
                                          y.target.value = y.target.value.replace(je("0-9"), "");
                                        },
                                        onBlur: () => {
                                          l.input.onChange(l.input.value.trim());
                                        },
                                        maxLength: 4,
                                        status: l.meta.error && l.meta.touched ? "error" : void 0
                                      }),
                                      l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(S, {
                                        type: "error",
                                        variant: "field",
                                        title: l.meta.error
                                      })
                                    ]
                                  })
                                })
                              ]
                            }) : null
                          ]
                        })
                      ]
                    })
                  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsxs("div", {
                        className: "border-neutral-10 dark:border-neutral-dark-10 bg-neutral-5 dark:bg-neutral-dark-5 relative mb-4 rounded-b-md border border-solid px-4 py-4",
                        style: {
                          top: "-4px"
                        },
                        children: [
                          /* @__PURE__ */ e.jsx("p", {
                            className: "text-neutral-60 text-size-12 mb-2",
                            children: ((ne = v == null ? void 0 : v.fetch) == null ? void 0 : ne.status) !== "successful" ? n._({
                              id: "SmHTo1"
                            }) : n._({
                              id: "EGxm1V"
                            })
                          }),
                          ((_e = v == null ? void 0 : v.fetch) == null ? void 0 : _e.status) !== "successful" ? /* @__PURE__ */ e.jsx("div", {
                            className: "text-center",
                            children: /* @__PURE__ */ e.jsx(Me, {
                              onClick: () => {
                                var l;
                                Vt((l = r.values) == null ? void 0 : l.email);
                              },
                              variant: "text",
                              type: "button",
                              children: "Allow"
                            })
                          }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                        ]
                      }),
                      /* @__PURE__ */ e.jsx(tt, {
                        countries: B,
                        addressType: "current",
                        formRef: V,
                        kycCheckData: T,
                        isPoaAadhaar: !0,
                        formInitialValues: se,
                        kycDetails: a,
                        addressProofTypeLabel: t
                      })
                    ]
                  })
                ]
              });
            }
          })
        ]
      })
    });
  }, Ut = async () => {
    try {
      z(!0);
      const r = o.id, t = o == null ? void 0 : o.annexure;
      L ? t.partner_completed_step = "financial_details" : t.completed_step = "financial_details", await C.updateUser(r, t, null, J), F();
    } catch (r) {
      window.debug.error(r), $(n._({
        id: "qMcu4/"
      })), M(r);
    } finally {
      z(!1);
    }
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "EWh4lo"
        }),
        icon: O ? null : "Back",
        onClick: Ut
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: L ? n._({
          id: "JK4xlJ"
        }) : n._({
          id: "h7ZLlY"
        })
      }),
      (Ge || Le) && /* @__PURE__ */ e.jsx(rt, {
        variant: "fullpage"
      }),
      Qe ? /* @__PURE__ */ e.jsx(sr, {
        title: n._({
          id: "pqPqrF"
        }),
        description: K,
        showAction: !0,
        actionText: "Retry",
        actionHandler: () => {
          $e(!1);
        }
      }) : /* @__PURE__ */ e.jsx(lt, {
        onSubmit: Rt,
        mutators: {
          mutateField: Gt
        },
        validate: (r) => {
          var s;
          const t = {};
          return ((s = r == null ? void 0 : r.address_proof_type) == null ? void 0 : s.value) !== "aadhaar" && (r.current_pincode ? r.perm_addr_is_corres_addr === "No" && !r.permanent_pincode && (t.permanent_pincode = n._({
            id: "7rkFUv"
          })) : t.current_pincode = n._({
            id: "7rkFUv"
          })), t;
        },
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        initialValues: se,
        children: (r) => (V.current = r.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: r.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-3",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "0VsDgp"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "mb-5",
                  children: /* @__PURE__ */ e.jsx(H, {
                    name: "phone",
                    initialValue: se.phone,
                    validate: le(Q, tr("Enter valid 10 digit mobile number")),
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(be, {
                          id: t.input.name,
                          autoComplete: "off",
                          name: t.input.name,
                          value: t.input.value,
                          disabled: !L,
                          onChange: t.input.onChange,
                          onBlur: () => {
                            t.input.onChange(t.input.value.trim());
                          },
                          onInput: (s) => {
                            s.target.value = s.target.value.replace(je("0-9"), "");
                          },
                          maxLength: 10,
                          status: t.meta.error && t.meta.touched ? "error" : void 0,
                          autoFocus: !0
                        }),
                        t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(S, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        })
                      ]
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-5",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "oyjr24"
                  })
                }),
                /* @__PURE__ */ e.jsx(H, {
                  name: "email",
                  initialValue: se.email,
                  validate: le(Q, rr(n._({
                    id: "kI4wAK"
                  }))),
                  validateFields: [],
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(be, {
                        id: t.input.name,
                        autoComplete: "off",
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: () => {
                          t.input.onChange(t.input.value.trim());
                        },
                        onInput: (s) => {
                          s.target.value = s.target.value.replace(je("A-Za-z0-9._\\-@+"), "");
                        },
                        placeholder: "Enter email address",
                        maxLength: 50,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        disabled: !(h || L)
                      }),
                      t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(S, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      }) : h ? /* @__PURE__ */ e.jsx(S, {
                        type: "helper",
                        variant: "field",
                        title: n._({
                          id: "Xu9u8c"
                        })
                      }) : null
                    ]
                  })
                })
              ]
            }),
            qt(r),
            K && /* @__PURE__ */ e.jsx(S, {
              type: "error",
              variant: "field",
              title: K
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const t = Object.keys(r.errors);
                t.length && at(t[0]);
              },
              customClass: "mb-12",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
}, tt = ({ countries: o, addressType: F, formRef: re, isPoaAadhaar: T = !1, kycDetails: L, addressProofTypeLabel: h }) => {
  const { fpapi: a } = he(Ie), U = {}, O = Yt(async (c) => {
    if (!c)
      return n._({
        id: "7rkFUv"
      });
    if (!/^[1-9]\d{5}$/.test(c))
      return n._({
        id: "FufyC1"
      });
    try {
      const d = await a.fpClient.master_data().fetchPincode(c);
      re.current.change(`${F}_location`, Kt(`${d.city}, ${d.state_name}`) + `, ${d.country_ansi_code}`);
      return;
    } catch (d) {
      return M(d), n._({
        id: "X83W5/"
      });
    }
  }), V = (c) => {
    const [d, N, P] = c.split(", ");
    return `${d}, ${N}, ${Ft(o, P)}`;
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mt-2"
          }),
          U.address ? null : h ? /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsx(W, {
                message: n._({
                  id: "PLMqte"
                })
              }),
              /* @__PURE__ */ e.jsx(H, {
                name: `${F}_pincode`,
                validate: O,
                validateFields: [],
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(be, {
                      id: c.input.name,
                      autoComplete: "off",
                      name: c.input.name,
                      value: c.input.value,
                      onChange: c.input.onChange,
                      disabled: T,
                      placeholder: n._({
                        id: "OBXszf"
                      }),
                      onBlur: () => {
                        c.input.onChange(c.input.value.trim());
                      },
                      onInput: (d) => {
                        d.target.value = d.target.value.replace(je("0-9"), "");
                      },
                      maxLength: 6,
                      status: c.meta.error && c.meta.touched ? "error" : void 0
                    }),
                    c.meta.error && c.meta.touched ? /* @__PURE__ */ e.jsx(S, {
                      type: "error",
                      variant: "field",
                      title: c.meta.error
                    }) : /* @__PURE__ */ e.jsx(H, {
                      name: `${F}_location`,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (d) => /* @__PURE__ */ e.jsx(e.Fragment, {
                        children: d.input.value && /* @__PURE__ */ e.jsx(S, {
                          type: "success",
                          variant: "field",
                          title: V(d.input.value)
                        })
                      })
                    })
                  ]
                })
              })
            ]
          }) : null
        ]
      }),
      U.address ? null : h ? /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(W, {
            message: n._({
              id: "Z2IJgc"
            })
          }),
          /* @__PURE__ */ e.jsx(H, {
            name: `${F}_residence_line1`,
            validate: le(Q, or(n._({
              id: "jJT4qV"
            })), lr(n._({
              id: "wCWfoc"
            }), 150)),
            validateFields: [],
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(Zt, {
                  id: c.input.name,
                  autoComplete: "off",
                  name: c.input.name,
                  value: c.input.value,
                  onChange: c.input.onChange,
                  disabled: T,
                  maxLength: 150,
                  onBlur: () => {
                    c.input.onChange(c.input.value.trim());
                  },
                  onInput: (d) => {
                    d.target.value = d.target.value.replace(je("A-Za-z0-9 #&/\\-.,"), "");
                  },
                  // placeholder={`Enter ${addressType} residence address`}
                  placeholder: n._({
                    id: "8Ueoht"
                  }),
                  status: c.meta.error && c.meta.touched ? "error" : void 0
                }),
                c.meta.error && c.meta.touched && /* @__PURE__ */ e.jsx(S, {
                  type: "error",
                  variant: "field",
                  title: c.meta.error
                })
              ]
            })
          })
        ]
      }) : null
    ]
  });
}, Er = ({ user: o, onCompletion: F, folioDetails: re, isGroupInvestment: T, isPartner: L, kycDetails: h, isKycCompliant: a, isKycOnly: U }) => {
  const O = He(null), { fpapi: V } = he(Ie), { AppState: { tenant: c } } = he(it), [d, N] = q("initial"), [P, I] = q([]), [C, J] = q(null), [w, A] = q(!1), [j, Re] = q(null), [Y, Ae] = q(!1), we = {};
  Ve(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const Be = (_) => [
    "second_tax_residency",
    "third_tax_residency",
    "fourth_tax_residency"
  ].some((g) => _[g]), ve = (_, i) => {
    const g = [
      "second_tax_residency",
      "third_tax_residency",
      "fourth_tax_residency"
    ], Z = [];
    return g.forEach((ce) => {
      const X = _[ce];
      X && Z.push({
        trc_taxid_type: X.taxid_type,
        trc_taxid_number: X.taxid_number,
        trc_ansi_code: X.country,
        trc_country_name: Ft(i, X.country)
      });
    }), Z;
  };
  Ve(() => {
    (async () => {
      try {
        if (A(!0), o) {
          const _ = await V.fpClient.investor_profiles().fetch(o.profile);
          if (Be(_)) {
            const { countries: i } = await V.fpClient.master_data().fetchCountries(), g = ve(_, i);
            Ae(!0), I(g);
          }
          Re(_);
        }
      } catch (_) {
        window.debug.error(_), J(n._({
          id: "J56bGZ"
        })), M(_);
      } finally {
        A(!1);
      }
    })();
  }, []);
  const B = Lt(() => {
    let _;
    const i = [
      {
        label: n._({
          id: "f0uGjY"
        }),
        value: {
          investorProfileValue: "government_service",
          kycValue: "government_sector"
        }
      },
      {
        label: n._({
          id: "Ex+hfX"
        }),
        value: {
          investorProfileValue: "private_sector_service",
          kycValue: "private_sector"
        }
      },
      {
        label: n._({
          id: "Cnq3xZ"
        }),
        value: {
          investorProfileValue: "professional",
          kycValue: "professional"
        }
      },
      {
        label: n._({
          id: "wlgrv+"
        }),
        value: {
          investorProfileValue: "public_sector_service",
          kycValue: "public_sector"
        }
      }
    ], g = [
      {
        label: n._({
          id: "knxqgB"
        }),
        value: {
          investorProfileValue: "business",
          kycValue: "business"
        }
      },
      {
        label: n._({
          id: "SOPYUU"
        }),
        value: {
          investorProfileValue: "retired",
          kycValue: "retired"
        }
      },
      {
        label: n._({
          id: "Asz58H"
        }),
        value: {
          investorProfileValue: "house_wife",
          kycValue: "housewife"
        }
      },
      {
        label: n._({
          id: "PKTltW"
        }),
        value: {
          investorProfileValue: "student",
          kycValue: "student"
        }
      },
      {
        label: n._({
          id: "a3VEdD"
        }),
        value: {
          investorProfileValue: "others",
          kycValue: "others"
        }
      },
      ...i
    ], Z = [
      {
        label: n._({
          id: "RkF9W+"
        }),
        value: {
          investorProfileValue: "agriculture",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "SHDdgK"
        }),
        value: {
          investorProfileValue: "doctor",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "R31w0n"
        }),
        value: {
          investorProfileValue: "forex_dealer",
          kycValue: "others"
        }
      },
      {
        label: n._({
          id: "WkXkt/"
        }),
        value: {
          investorProfileValue: "service",
          kycValue: "others"
        }
      },
      ...g
    ];
    return U ? _ = g : T ? _ = i : _ = Z, _.sort((ce, X) => ce.label === "Others" ? 1 : ce.label < X.label ? -1 : 1);
  }, [])(), R = [
    {
      label: n._({
        id: "C4v9uw"
      }),
      value: "ancestral_property"
    },
    {
      label: n._({
        id: "knxqgB"
      }),
      value: "business"
    },
    {
      label: n._({
        id: "vPOn7T"
      }),
      value: "gift"
    },
    {
      label: n._({
        id: "hbAj8o"
      }),
      value: "prize_money"
    },
    {
      label: n._({
        id: "pkrzlZ"
      }),
      value: "rental_income"
    },
    {
      label: n._({
        id: "i1r+jC"
      }),
      value: "royalty"
    },
    {
      label: n._({
        id: "fnpTrQ"
      }),
      value: "salary"
    },
    {
      label: n._({
        id: "a3VEdD"
      }),
      value: "others"
    }
  ], K = [
    {
      label: n._({
        id: "O0dUYL"
      }),
      value: "upto_1lakh"
    },
    {
      label: n._({
        id: "XHQhN/"
      }),
      value: "above_1lakh_upto_5lakh"
    },
    {
      label: n._({
        id: "VtWV5E"
      }),
      value: "above_5lakh_upto_10lakh"
    },
    {
      label: n._({
        id: "HZjZLV"
      }),
      value: "above_10lakh_upto_25lakh"
    },
    {
      label: n._({
        id: "o0UHku"
      }),
      value: "above_25lakh_upto_1cr"
    },
    {
      label: n._({
        id: "TRwS3/"
      }),
      value: "above_1cr"
    }
  ], $ = Ue(() => {
    let _ = {
      occupation: null,
      source_of_wealth: T ? R[6] : null,
      income_slab: null,
      no_other_tax_residences: null
    };
    if (re && (_.occupation = B[ge(B, Dt(re), {
      customProperty: "value.investorProfileValue"
    })]), h) {
      const i = {
        occupation: {
          initFieldName: "occupation",
          initFieldValue: B[ge(B, h == null ? void 0 : h.occupation, {
            customProperty: "value.kycValue"
          })]
        }
      }, g = wt(i, h);
      _ = {
        ..._,
        ...g
      };
    }
    if (j) {
      const i = {
        occupation: B[ge(B, j == null ? void 0 : j.occupation, {
          customProperty: "value.investorProfileValue"
        })],
        source_of_wealth: R[ge(R, j == null ? void 0 : j.source_of_wealth)],
        income_slab: K[ge(K, j == null ? void 0 : j.income_slab)],
        no_other_tax_residences: j.first_tax_residency && (Be(j) ? "Yes" : "No")
      };
      for (const g in i)
        i[g] && (_[g] = i[g]);
    }
    return _;
  }, [
    j,
    re
  ]), Ge = (_) => {
    I([
      ...P,
      _
    ]), N("initial");
  };
  async function z({ occupation: _, source_of_wealth: i, income_slab: g, no_other_tax_residences: Z }) {
    var de, Se;
    const ce = Z === "Yes", X = {
      id: o == null ? void 0 : o.profile,
      occupation: (de = _ == null ? void 0 : _.value) == null ? void 0 : de.investorProfileValue,
      source_of_wealth: i == null ? void 0 : i.value,
      income_slab: g == null ? void 0 : g.value,
      use_default_tax_residences: !ce
    };
    if (ce && P.length) {
      const Ye = (Se = o == null ? void 0 : o.username) == null ? void 0 : Se.toUpperCase();
      X.first_tax_residency = {
        country: "IN",
        taxid_type: "pan",
        taxid_number: Ye
      };
      const ze = {
        1: "second_tax_residency",
        2: "third_tax_residency",
        3: "fourth_tax_residency"
      };
      P.forEach((ue, Ze) => {
        X[`${ze[Ze + 1]}`] = {
          country: ue == null ? void 0 : ue.trc_ansi_code,
          // taxid_type: residency?.trc_taxid_type?.value,
          taxid_type: "others",
          taxid_number: ue == null ? void 0 : ue.trc_taxid_number
        };
      });
    }
    return V.fpClient.investor_profiles().update(X);
  }
  async function Le() {
    const _ = o.id, g = {
      ...Je(o == null ? void 0 : o.annexure),
      completed_step: "financial_details"
    };
    L && (g.partner_completed_step = "financial_details");
    const Z = await V.updateUser(_, g, null, c);
    if (Z)
      return Z;
    throw new Error(Z);
  }
  async function Fe({ occupation: _ }) {
    var g;
    const i = {
      occupation_type: (g = _ == null ? void 0 : _.value) == null ? void 0 : g.kycValue
    };
    return V.fpClient.kyc_requests().update(h == null ? void 0 : h.id, i);
  }
  const Qe = async (_) => {
    if (U)
      try {
        A(!0);
        const i = await Fe(_);
        F({
          updatedKycDetails: i
        });
      } catch (i) {
        J(n._({
          id: "cuvfTX"
        })), window.debug.error(i), M(i), fe(i) && window.location.reload();
      } finally {
        A(!1);
      }
    else if (a)
      try {
        A(!0), await z(_), await Le(), F();
      } catch (i) {
        J(n._({
          id: "cuvfTX"
        })), window.debug.error(i), M(i);
      } finally {
        A(!1);
      }
    else
      try {
        A(!0);
        const i = [
          Fe(_),
          z(_)
        ], [g] = await Promise.all(i);
        await Le(), F({
          updatedKycDetails: g
        });
      } catch (i) {
        window.debug.error(i), J(n._({
          id: "cuvfTX"
        })), M(i), fe(i) && window.location.reload();
      } finally {
        A(!1);
      }
  }, $e = async () => {
    try {
      A(!0);
      const _ = o.id, i = o == null ? void 0 : o.annexure;
      L ? delete i.partner_completed_step : delete i.completed_step, await V.updateUser(_, i, null, c), F();
    } catch (_) {
      window.debug.error(_), J(n._({
        id: "cuvfTX"
      })), M(_);
    } finally {
      A(!1);
    }
  }, v = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "AIr9uY"
        }),
        icon: U ? null : "Back",
        onClick: $e
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: L ? n._({
          id: "lc5Dv2"
        }) : n._({
          id: "zzvZc5"
        })
      }),
      /* @__PURE__ */ e.jsx(lt, {
        onSubmit: Qe,
        validate: (_) => {
          const i = {};
          return _.no_other_tax_residences === "Yes" && !P.length && (i.no_other_tax_residences = n._({
            id: "hC3Gqy"
          })), i;
        },
        initialValues: $,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (_) => (O.current = _.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: _.handleSubmit,
          children: [
            we.occupation_type ? null : /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "jHZRka"
                  })
                }),
                /* @__PURE__ */ e.jsx(H, {
                  name: "occupation",
                  validate: Q,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(We, {
                        name: i.input.name,
                        value: i.input.value,
                        onChange: (g) => i.input.onChange(g),
                        options: B,
                        status: i.meta.error && i.meta.touched ? "error" : void 0
                      }),
                      i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(S, {
                        type: "error",
                        variant: "field",
                        title: i.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            U ? null : /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(W, {
                      message: n._({
                        id: "LxJDmf"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(H, {
                      name: "source_of_wealth",
                      validate: Q,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (i) => {
                        var g;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            T ? /* @__PURE__ */ e.jsx(be, {
                              id: i.input.name,
                              autoComplete: "off",
                              name: i.input.name,
                              value: (g = $.source_of_wealth) == null ? void 0 : g.label,
                              disabled: !0
                            }) : /* @__PURE__ */ e.jsx(We, {
                              name: i.input.name,
                              value: i.input.value,
                              onChange: (Z) => i.input.onChange(Z),
                              options: R,
                              status: i.meta.error && i.meta.touched ? "error" : void 0
                            }),
                            i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(S, {
                              type: "error",
                              variant: "field",
                              title: i.meta.error
                            })
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(W, {
                      message: n._({
                        id: "eO0CVp"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(H, {
                      name: "income_slab",
                      validate: le(Q),
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(We, {
                            name: i.input.name,
                            value: i.input.value,
                            onChange: i.input.onChange,
                            options: K,
                            status: i.meta.error && i.meta.touched ? "error" : void 0
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(S, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(W, {
                      message: n._({
                        id: "Iz0gdi"
                      })
                    }),
                    /* @__PURE__ */ e.jsx(H, {
                      name: "no_other_tax_residences",
                      validate: Q,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(Wt, {
                            name: i.input.name,
                            value: $ == null ? void 0 : $.no_other_tax_residences,
                            checked: ge([
                              "Yes",
                              "No"
                            ], i.input.value, {
                              isNotArrayOfObjects: !0
                            }),
                            onChange: (g) => {
                              i.input.onChange(g.target.value);
                            },
                            group: [
                              n._({
                                id: "dhWZU+"
                              }),
                              n._({
                                id: "AYH7yb"
                              })
                            ],
                            disabled: Y ? i.input.value === "Yes" ? [
                              1
                            ] : [] : []
                          }),
                          i.meta.error && i.meta.touched && /* @__PURE__ */ e.jsx(S, {
                            type: "error",
                            variant: "field",
                            title: i.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                _.form.getState().values.no_other_tax_residences === "Yes" && /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(W, {
                      message: n._({
                        id: "SjWaBE"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-4",
                      children: /* @__PURE__ */ e.jsx(S, {
                        type: "important",
                        variant: "flash",
                        title: n._({
                          id: "csmDQB"
                        })
                      })
                    }),
                    P.map((i, g) => /* @__PURE__ */ e.jsx(Ht, {
                      children: /* @__PURE__ */ e.jsxs("div", {
                        className: "-mr-2 -ml-2 flex flex-wrap",
                        children: [
                          /* @__PURE__ */ e.jsx("div", {
                            className: "basis-1/2 pr-2 pl-2",
                            children: /* @__PURE__ */ e.jsx(ft, {
                              label: n._({
                                id: "UcmoRi"
                              }),
                              value: i == null ? void 0 : i.trc_country_name
                            })
                          }),
                          /* @__PURE__ */ e.jsx("div", {
                            className: "basis-1/2 pr-2 pl-2",
                            children: /* @__PURE__ */ e.jsx(ft, {
                              label: n._({
                                id: "2QDHp6"
                              }),
                              value: i == null ? void 0 : i.trc_taxid_number
                            })
                          })
                        ]
                      })
                    }, g)),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-4",
                      children: /* @__PURE__ */ e.jsx(Me, {
                        variant: P.length >= 3 ? "disabled" : "secondary",
                        fullwidth: !0,
                        onClick: (i) => {
                          i.preventDefault(), i.stopPropagation(), N("AddTRC");
                        },
                        children: n._({
                          id: "X6nmUX"
                        })
                      })
                    })
                  ]
                })
              ]
            }),
            C && /* @__PURE__ */ e.jsx(S, {
              type: "error",
              variant: "field",
              title: C
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const i = Object.keys(_.errors);
                i.length && at(i[0]);
              },
              customClass: "mb-12",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
  return w ? /* @__PURE__ */ e.jsx(rt, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx("div", {
        style: {
          display: d === "initial" ? "block" : "none"
        },
        children: v()
      }),
      U ? null : /* @__PURE__ */ e.jsx("div", {
        style: {
          display: d !== "initial" ? "block" : "none"
        },
        children: /* @__PURE__ */ e.jsx(yt, {
          taxResidenceCountries: P,
          handleAddTaxResidenceDetails: Ge,
          handleBack: () => N("initial")
        })
      })
    ]
  });
}, yt = /* @__PURE__ */ $t(({ handleAddTaxResidenceDetails: o, isPartner: F, handleBack: re, taxResidenceCountries: T }) => {
  const { fpapi: L } = he(Ie), [h, a] = q([]), U = He(null), O = Ue(() => ({
    trc_country: null
  }), []), V = (c) => {
    var d, N;
    U.current.reset(), o({
      trc_taxid_type: c == null ? void 0 : c.trc_taxid_type,
      trc_taxid_number: c == null ? void 0 : c.trc_taxid_number,
      trc_ansi_code: (d = c == null ? void 0 : c.trc_country) == null ? void 0 : d.trc_ansi_code,
      trc_country_name: (N = c == null ? void 0 : c.trc_country) == null ? void 0 : N.trc_country_name
    });
  };
  return Ve(() => {
    (async () => {
      try {
        const { countries: c } = await L.fpClient.master_data().fetchCountries();
        if (c.length) {
          const d = [], N = [];
          c.forEach((P) => {
            if (P.ansi_code !== "IN") {
              const I = {
                id: P.ansi_code,
                trc_ansi_code: P.ansi_code,
                trc_country_name: P.name
              };
              P.ansi_code === "US" ? N[0] = I : P.ansi_code === "GB" ? N[1] = I : P.ansi_code === "AE" ? N[2] = I : d.push(I);
            }
          }), a([
            ...N,
            ...d
          ]);
        }
      } catch (c) {
        window.debug.error(c), M(c);
      }
    })();
  }, []), /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(nt, {
        title: n._({
          id: "AIr9uY"
        }),
        icon: "Back",
        onClick: re
      }),
      /* @__PURE__ */ e.jsx(Oe, {
        type: "text",
        text: F ? n._({
          id: "lc5Dv2"
        }) : n._({
          id: "zzvZc5"
        })
      }),
      /* @__PURE__ */ e.jsx(lt, {
        onSubmit: V,
        validate: (c) => ({}),
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        initialValues: O,
        children: (c) => (U.current = c.form, /* @__PURE__ */ e.jsxs("form", {
          onSubmit: c.handleSubmit,
          children: [
            !!h.length && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "UcmoRi"
                  })
                }),
                /* @__PURE__ */ e.jsx(H, {
                  name: "trc_country",
                  validate: le(Q, dr(T, n._({
                    id: "7/wU9f"
                  }))),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Xt, {
                        value: d.input.value,
                        options: h,
                        onChange: (N) => d.input.onChange(N),
                        searchAttribute: "trc_country_name",
                        variant: "autocomplete",
                        placeholder: n._({
                          id: "Y88q9i"
                        })
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(S, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "DZCqsJ"
                  })
                }),
                /* @__PURE__ */ e.jsx(H, {
                  name: "trc_taxid_type",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: le(Q),
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(be, {
                        id: d.input.name,
                        autoComplete: "off",
                        name: d.input.name,
                        onChange: d.input.onChange,
                        onBlur: () => {
                          d.input.onChange(d.input.value.trim());
                        },
                        onInput: (N) => {
                          N.target.value = N.target.value.replace(je("A-Za-z "), "");
                        },
                        value: d.input.value,
                        placeholder: n._({
                          id: "Ny7pME"
                        }),
                        maxLength: 25,
                        status: d.meta.error && d.meta.touched ? "error" : void 0
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(S, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  message: n._({
                    id: "2QDHp6"
                  })
                }),
                /* @__PURE__ */ e.jsx(H, {
                  name: "trc_taxid_number",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: le(Q),
                  parse: (d) => d.toUpperCase(),
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(be, {
                        id: d.input.name,
                        autoComplete: "off",
                        name: d.input.name,
                        onChange: d.input.onChange,
                        onBlur: () => {
                          d.input.onChange(d.input.value.trim());
                        },
                        onInput: (N) => {
                          N.target.value = N.target.value.replace(je("A-Za-z0-9 /\\-"), "");
                        },
                        value: d.input.value,
                        placeholder: n._({
                          id: "uu7iMg"
                        }),
                        status: d.meta.error && d.meta.touched ? "error" : void 0,
                        maxLength: 25
                      }),
                      d.meta.error && d.meta.touched && /* @__PURE__ */ e.jsx(S, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(Me, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const d = Object.keys(c.errors);
                d.length && at(d[0]);
              },
              customClass: "mb-8",
              children: n._({
                id: "AtXdm9"
              })
            })
          ]
        }))
      })
    ]
  });
});
yt.displayName = "AddTaxResidenceDetails";
export {
  yr as B,
  Nr as C,
  Er as F
};
