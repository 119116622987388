import { j as i } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as h, useContext as es, useRef as Fs, useMemo as As, useEffect as ts } from "react";
import { S as _s, c as d, B as as, D as Es, a as fs, e as r, k as $s, T as ws, M as W, l as qs, F as Ss, b as Us, u as Rs, R as Vs, O as Bs } from "./index.esm-qyC9L-ym.js";
import { F as ns } from "./provider-CNUuOe_A.js";
import { f as O, r as ss } from "./validators-BNinzCrT.js";
import { u as ms } from "./useConfiguration-B-lyaBB9.js";
import { A as R, ad as Ts, f as hs, a1 as Ks, z as gs, $ as Is, m as us, a6 as Xs, a as ys, M as Cs, o as Ls, i as Hs, h as zs, k as xs, a3 as Ms, b as Ws, c as Zs } from "./utils-Bzskk-FI.js";
import { R as Ns, F as is } from "./react-final-form.es-B_J3uV0l.js";
import { r as Y } from "./sentry-Djb1pAvw.js";
import { i as o } from "./index-C-jpp3An.js";
import { m as ls, M as Js } from "./MandateListing-7eU3x5KZ.js";
import { ErrorPage as ks } from "./ErrorPage.js";
import { P as ps } from "./Product-DxVYHIQw.js";
import { S as Ds } from "./StatusMessage-CBzuZxyP.js";
function Ys({ mandate: s, handleMandateModalChange: L }) {
  var K;
  const { banks: w, tenantConfig: E } = ms(), H = E == null ? void 0 : E.fallbackLogo;
  return /* @__PURE__ */ i.jsx(i.Fragment, {
    children: s && s.bank ? /* @__PURE__ */ i.jsx(_s, {
      children: /* @__PURE__ */ i.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
        children: [
          /* @__PURE__ */ i.jsx("div", {
            className: "mb-3 w-full pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Bank",
              value: s ? `${s.bank.bank_name} - ****${s.bank.number.slice(-4)}` : "-",
              logo: w[(K = s.bank.ifsc) == null ? void 0 : K.slice(0, 4)] || H
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Amount limit",
              value: s ? `₹${R(s.mandate_limit)}` : "-"
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(d, {
              label: "Authorization",
              value: s ? ls[s.mandate_status].value : "-",
              icon: s ? ls[s.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ i.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ i.jsx(as, {
              variant: "text",
              onClick: L,
              children: `${o._({
                id: "ShFidp"
              })}`
            })
          })
        ]
      })
    }) : /* @__PURE__ */ i.jsx(Es, {
      type: "borderedNotes",
      text: "Setup mandate",
      children: /* @__PURE__ */ i.jsx(as, {
        variant: "text",
        onClick: L,
        children: `${o._({
          id: "JW7jyx"
        })}`
      })
    })
  });
}
function ei({ currentPlanObject: s, editField: L, handleEditCompletion: w, isPartner: E, assistedWorkflowPAN: H }) {
  var x;
  const [K, M] = h(!1), [G, A] = h("initial"), { tenantConfig: v, amcs: q } = ms(), z = v == null ? void 0 : v.fallbackLogo, S = v == null ? void 0 : v.showSchemeLogo, [y, Q] = h({
    name: "",
    investment_option: "",
    minInitialAmount: 0,
    maxInitialAmount: 0,
    schemeLogo: ""
  }), { fpapi: k } = es(ns), [u, D] = h({}), p = ((x = v == null ? void 0 : v.app_constants) == null ? void 0 : x.non_systematic_min_installments) || 3, F = /* @__PURE__ */ new Date(), [f, g] = h(null), [$, os] = h(null), [j, N] = h(!1), Z = Fs(null), m = {
    amount: {
      label: o._({
        id: "Dh9Eyz"
      }),
      value: "amount"
    },
    payment_method: {
      label: o._({
        id: "1sWe0g"
      }),
      value: "payment_method"
    },
    installment_day: {
      label: o._({
        id: "tnH8DU"
      }),
      value: "installment_day"
    },
    no_of_installment: {
      label: o._({
        id: "6wLMYD"
      }),
      value: "number_of_installments"
    }
  };
  s != null && s.systematic && (delete m.installment_day, delete m.no_of_installment), (s == null ? void 0 : s.object) !== "mf_purchase_plan" && delete m.payment_method, (s == null ? void 0 : s.frequency) === "daily" && m != null && m.installment_day && delete m.installment_day, L != null && L.length && Object.keys(m).forEach((t) => {
    L != null && L.includes(t) || delete m[t];
  });
  const I = As(() => ({
    edit_options: m[Object.keys(m)[0]],
    edit_start_date: new Date(F).setDate(F.getDate() + 2),
    edit_max_start_date: new Date(F).setDate(F.getDate() + 30),
    edit_allowed_dates: Ts(s == null ? void 0 : s.frequency).filter((t) => t !== (s == null ? void 0 : s.installment_day)),
    mandate: f
  }), [
    f
  ]);
  ts(() => {
    X(), b();
  }, [
    s
  ]);
  const X = async () => {
    const t = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    if (t) {
      let a;
      try {
        a = await k.fpClient.master_data().fetchScheme(t);
      } catch (c) {
        window.debug.error(c), Y(c);
      }
      let _;
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? _ = a == null ? void 0 : a.sip_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? _ = a == null ? void 0 : a.swp_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_switch_plan" && (_ = a == null ? void 0 : a.stp_frequency_specific_data), D(_);
      const B = S ? {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment,
        schemeLogo: q[a == null ? void 0 : a.amc_id] || z
      } : {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment
      };
      Q(B);
    }
  }, b = async () => {
    var t, a;
    if ((s == null ? void 0 : s.object) === "mf_purchase_plan")
      try {
        M(!0);
        let _ = await k.getInvestorProfile();
        if (E) {
          const n = await ((t = k == null ? void 0 : k.fpClient.investor_profiles()) == null ? void 0 : t.fetchAll({
            pan: H
          }));
          _ = (a = n == null ? void 0 : n.data) == null ? void 0 : a[0];
        }
        os(_);
        const B = await k.fpClient.bank_accounts().fetchAll({
          profile: _ == null ? void 0 : _.id
        }), c = B.data.map((n) => n.old_id), l = (await k.fpClient.mandates().fetchAll({
          bank_account_id: c.join(","),
          size: 100
        })).mandates;
        if (l.sort((n, P) => Number(new Date(P.created_at)) - Number(new Date(n.created_at))), l.length > 0) {
          B.data.forEach((J) => {
            l.forEach((ds) => {
              ds.bank_account_id === J.old_id && (ds.bank = {
                bank_name: J.bank_name,
                number: J.account_number,
                ifsc: J.ifsc_code
              });
            });
          });
          const n = [], P = [];
          if (l.forEach((J) => {
            J.mandate_status === "CREATED" || J.mandate_status === "SUBMITTED" ? n.push(J) : J.mandate_status === "APPROVED" && P.push(J);
          }), (s == null ? void 0 : s.payment_method) === "mandate" && (s != null && s.payment_source)) {
            const J = l.find((ds) => ds.id.toString() === s.payment_source);
            g(J);
          }
          M(!1);
        } else
          M(!1);
      } catch (_) {
        window.debug.error(_), Y(_);
      }
  }, V = async ({ editType: t, amount: a, installment_day: _, installments: B, mandate: c }) => {
    M(!0);
    try {
      let T = {}, l = {}, n = {};
      t.value === "amount" ? n = {
        id: s == null ? void 0 : s.id,
        amount: a
      } : t.value === "installment_day" ? n = {
        id: s == null ? void 0 : s.id,
        installment_day: new Date(_).getDate()
      } : t.value === "number_of_installments" ? n = {
        id: s == null ? void 0 : s.id,
        number_of_installments: B
      } : t.value === "payment_method" && (n = {
        id: s == null ? void 0 : s.id,
        payment_method: "mandate",
        payment_source: c.id
      }), (s == null ? void 0 : s.object) === "mf_purchase_plan" ? T = await k.fpClient.mf_purchase_plans().update(n) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? T = await k.fpClient.mf_redemption_plans().update(n) : (s == null ? void 0 : s.object) === "mf_switch_plan" && (T = await k.fpClient.mf_switch_plans().update(n)), t.value === "amount" ? l = s.amount ? {
        ...T,
        previousAmount: s.amount
      } : {
        ...T,
        previousUnits: s.units
      } : t.value === "installment_day" ? l = {
        ...T,
        previousInstallmentDay: s.installment_day
      } : t.value === "number_of_installments" ? l = {
        ...T,
        previousInstallments: s.number_of_installments
      } : t.value === "payment_method" && (l = {
        ...T,
        previousPaymentMethod: s.payment_method || "not_setup",
        previouosPaymentSource: s.payment_source
      }), w(l), M(!1);
    } catch (T) {
      M(!1), A("error"), Y(T);
    }
  };
  function U() {
    return /* @__PURE__ */ i.jsx(Us, {
      variant: "fullPage",
      title: "Select mandate",
      isOpen: j,
      toggleModal: () => {
        N(!1);
      },
      contentHeightMax: !0,
      children: /* @__PURE__ */ i.jsx(Js, {
        investorProfile: $,
        handleMandateSelection: (t) => {
          N(!1), Z.current.input.onChange(t);
        },
        isPartner: E
      })
    });
  }
  function C() {
    var t;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: y
        }),
        /* @__PURE__ */ i.jsx(_s, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "7sZGpa"
                  }) : o._({
                    id: "2pa2q1"
                  }),
                  value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : s != null && s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (t = hs(s.frequency)) == null ? void 0 : t.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(Ns, {
          onSubmit: V,
          validate: (a) => {
            const _ = {};
            return s != null && s.systematic && a.amount && a.amount > u[s == null ? void 0 : s.frequency].max_installment_amount && (_.amount = o._({
              id: "5K7M8h",
              values: {
                0: R(u[s == null ? void 0 : s.frequency].max_installment_amount)
              }
            })), s != null && s.systematic && a.amount && a.amount < u[s == null ? void 0 : s.frequency].min_installment_amount && (_.amount = o._({
              id: "AiK/vj",
              values: {
                0: R(u[s == null ? void 0 : s.frequency].min_installment_amount)
              }
            })), s != null && s.systematic && a.amount && u[s == null ? void 0 : s.frequency].amount_multiples && Ks(a.amount.toString(), u[s == null ? void 0 : s.frequency].amount_multiples.toString()) !== 0 && (_.amount = o._({
              id: "aXWCv/",
              values: {
                0: u[s == null ? void 0 : s.frequency].amount_multiples
              }
            })), !(s != null && s.systematic) && a.amount && a.amount > (y == null ? void 0 : y.maxInitialAmount) && (_.amount = o._({
              id: "5K7M8h",
              values: {
                0: R(y == null ? void 0 : y.maxInitialAmount)
              }
            })), !(s != null && s.systematic) && a.amount && a.amount < (y == null ? void 0 : y.minInitialAmount) && (_.amount = o._({
              id: "AiK/vj",
              values: {
                0: R(y == null ? void 0 : y.minInitialAmount)
              }
            })), !(s != null && s.systematic) && a.installments && a.installments < p && (_.installments = o._({
              id: "0GGsEN",
              values: {
                nonSipMinimumInstallments: p
              }
            })), !(s != null && s.systematic) && a.installments && a.installments > 1200 && (_.installments = o._({
              id: "j7AzBN"
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && (s != null && s.payment_source) && a.amount && a.amount > (f == null ? void 0 : f.mandate_limit) && (_.amount = o._({
              id: "E5Y5Dh",
              values: {
                0: R(f == null ? void 0 : f.mandate_limit)
              }
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && f && a.mandate && a.mandate.id === f.id && (_.mandate = o._({
              id: "Cp20Hl"
            })), _;
          },
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (a) => {
            var _, B, c, T;
            return /* @__PURE__ */ i.jsxs("form", {
              id: "editOrderForm",
              onSubmit: a.handleSubmit,
              children: [
                /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "5TUqdC"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "editType",
                      initialValue: I.edit_options,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: O(ss),
                      children: (l) => /* @__PURE__ */ i.jsx($s, {
                        name: l.input.name,
                        value: l.input.value,
                        onChange: (n) => {
                          l.input.onChange(n);
                        },
                        options: Object.values(m)
                      })
                    })
                  ]
                }),
                ((_ = a.values.editType) == null ? void 0 : _.value) === "amount" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "OFH0cI"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: O(ss),
                      children: (l) => {
                        var n;
                        return /* @__PURE__ */ i.jsxs(i.Fragment, {
                          children: [
                            /* @__PURE__ */ i.jsx(ws, {
                              id: l.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: l.input.name,
                              value: gs(l.input.value),
                              onChange: (P) => l.input.onChange(Is(P.target.value)),
                              onInput: (P) => {
                                P.target.value = P.target.value.replace(us("0-9"), "");
                              },
                              placeholder: o._({
                                id: "fK0Zpc"
                              }),
                              prefix: "₹",
                              status: l.meta.error && l.meta.touched ? "error" : void 0,
                              autoFocus: !0
                            }),
                            l.meta.error && l.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                              className: "mb-2 mt-2",
                              children: /* @__PURE__ */ i.jsx(W, {
                                type: "error",
                                variant: "field",
                                title: l.meta.error
                              })
                            }) : /* @__PURE__ */ i.jsx(W, {
                              type: "helper",
                              variant: "field",
                              title: o._({
                                id: "9v2zaG",
                                values: {
                                  0: s != null && s.systematic ? `${R(Number((n = u[s == null ? void 0 : s.frequency]) == null ? void 0 : n.min_installment_amount))}` : `${R(Number(y == null ? void 0 : y.minInitialAmount))}`
                                }
                              })
                            })
                          ]
                        });
                      }
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((B = a.values.editType) == null ? void 0 : B.value) === "payment_method" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "lFCwug"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "mandate",
                      initialValue: I.mandate,
                      validate: O(ss),
                      children: (l) => (Z.current = l, /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Ys, {
                            mandate: l.input.value,
                            handleMandateModalChange: (n) => {
                              n.preventDefault(), N(!j);
                            }
                          }),
                          l.meta.error && l.meta.touched && /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "error",
                              variant: "field",
                              title: l.meta.error
                            })
                          })
                        ]
                      }))
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((c = a.values.editType) == null ? void 0 : c.value) === "installment_day" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "VXfUoN"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "installment_day",
                      initialValue: I.edit_start_date,
                      validate: O(ss),
                      children: (l) => /* @__PURE__ */ i.jsx(i.Fragment, {
                        children: /* @__PURE__ */ i.jsx(qs, {
                          variant: "keyboardInputDOB",
                          onKeyDown: (n) => n.preventDefault(),
                          disableFuture: !1,
                          minDate: new Date(I.edit_start_date),
                          maxDate: new Date(I.edit_max_start_date),
                          shouldDisableDate: (n) => Xs(n, I.edit_allowed_dates),
                          name: l.input.name,
                          value: l.input.value,
                          format: "dd-MMM-yyyy",
                          selectedDate: l.input.value || null,
                          error: l.meta.error && l.meta.touched,
                          views: [
                            "date"
                          ],
                          handleDateChange: (n) => {
                            l.input.onChange(n);
                          }
                        })
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((T = a.values.editType) == null ? void 0 : T.value) === "number_of_installments" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "kGmYAl"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "installments",
                      validate: O(ss),
                      children: (l) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(ws, {
                            id: l.input.name,
                            autoComplete: "off",
                            type: "text",
                            name: l.input.name,
                            value: gs(l.input.value),
                            onChange: (n) => l.input.onChange(Is(n.target.value)),
                            onInput: (n) => {
                              n.target.value = n.target.value.replace(us("0-9"), "");
                            },
                            placeholder: o._({
                              id: "3Oj1cs"
                            }),
                            status: l.meta.error && l.meta.touched ? "error" : void 0
                          }),
                          l.meta.error && l.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "error",
                              variant: "field",
                              title: l.meta.error
                            })
                          }) : /* @__PURE__ */ i.jsx(W, {
                            type: "helper",
                            variant: "field",
                            title: o._({
                              id: "C40jmw",
                              values: {
                                0: R(Number(p))
                              }
                            })
                          })
                        ]
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx(Ss, {
                  children: /* @__PURE__ */ i.jsx("div", {
                    className: "mb-4 pt-1",
                    children: /* @__PURE__ */ i.jsx(as, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: o._({
                        id: "H/E6fZ"
                      })
                    })
                  })
                })
              ]
            });
          }
        }),
        j && U(),
        /* @__PURE__ */ i.jsx(W, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        })
      ]
    });
  }
  function e() {
    switch (G) {
      default:
        return C();
      case "initial":
        return C();
      case "error":
        return /* @__PURE__ */ i.jsx(ks, {
          title: o._({
            id: "rz8jSA"
          }),
          description: o._({
            id: "RxIw8D"
          })
        });
    }
  }
  return K ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : e();
}
function ti({ editOrder: s, handleGoToPlansList: L }) {
  const w = s == null ? void 0 : s.state.toLowerCase(), [E, H] = h(""), [K, M] = h(""), [G, A] = h(""), { fpapi: v } = es(ns), { tenantConfig: q, amcs: z, banks: S } = ms(), y = q == null ? void 0 : q.fallbackLogo, Q = q == null ? void 0 : q.showSchemeLogo, [k, u] = h({}), [D, p] = h(!1), [F, f] = h(null), [g, $] = h(null), os = async () => {
    p(!0);
    const m = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    v.fpClient.master_data().fetchScheme(m).then((I) => {
      j(I);
    }).catch((I) => {
      window.debug.log(I), Y(I);
    });
  };
  function j(m) {
    if (!m)
      u([]);
    else {
      const I = Q ? {
        ...m,
        schemeLogo: z[m == null ? void 0 : m.amc_id] || y
      } : {
        ...m
      };
      u(I);
    }
    p(!1);
  }
  ts(() => {
    w === "active" && (H(`${ys(s == null ? void 0 : s.id)} updated`), M("successInfo")), A(o._({
      id: "d9gGIK"
    })), os(), N();
  }, []);
  const N = async () => {
    if (s != null && s.previousPaymentMethod)
      try {
        p(!0);
        const m = [], I = v.fpClient.mandates().fetch(s == null ? void 0 : s.payment_source);
        if (m.push(I), s != null && s.previouosPaymentSource && (s == null ? void 0 : s.previousPaymentMethod) === "mandate") {
          const U = v.fpClient.mandates().fetch(s.previouosPaymentSource);
          m.push(U);
        }
        const X = await v.getInvestorProfile(), b = await v.fpClient.bank_accounts().fetchAll({
          profile: X == null ? void 0 : X.id
        }), V = await Promise.all(m);
        b.data.forEach((U) => {
          V[0].bank_account_id === U.old_id && (V[0].bank = {
            bank_name: U.bank_name,
            number: U.account_number,
            ifsc: U.ifsc_code
          }), V[1] && V[1].bank_account_id === U.old_id && (V[1].bank = {
            bank_name: U.bank_name,
            number: U.account_number,
            ifsc: U.ifsc_code
          });
        }), $(V[0]), V.length > 1 && f(V[1]), p(!1);
      } catch (m) {
        window.debug.error(m), Y(m);
      }
  };
  function Z() {
    var m, I, X, b, V, U;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: k
        }),
        /* @__PURE__ */ i.jsx(Ds, {
          title: E,
          description: `Your ${(m = ys(s == null ? void 0 : s.id)) == null ? void 0 : m.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: K
        }),
        /* @__PURE__ */ i.jsxs(Rs, {
          children: [
            /* @__PURE__ */ i.jsxs("div", {
              className: "flex flex-wrap items-center -mr-2 -ml-2 px-4 pt-2",
              children: [
                !(s != null && s.previousAmount) && !(s != null && s.previousUnits) ? /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "KqeLJ1"
                    }),
                    value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : "-"
                  })
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousAmount || s != null && s.previousUnits ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: s != null && s.previousAmount ? o._({
                          id: "aoh6Eh"
                        }) : o._({
                          id: "ATWL+3"
                        }),
                        value: s != null && s.previousAmount ? `₹ ${R(s == null ? void 0 : s.previousAmount, 2)}` : s.previousUnits ? s.previousUnits : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: s != null && s.amount ? o._({
                          id: "bjwBd3"
                        }) : o._({
                          id: "uQVwS2"
                        }),
                        value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallmentDay ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "3+LOee"
                        }),
                        value: s != null && s.previousInstallmentDay ? Cs(s == null ? void 0 : s.previousInstallmentDay) : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "VXfUoN"
                        }),
                        value: s != null && s.installment_day ? Cs(s == null ? void 0 : s.installment_day) : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallments ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "CClCym"
                        }),
                        value: s != null && s.previousInstallments ? s == null ? void 0 : s.previousInstallments : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "5q9LAN"
                        }),
                        value: s != null && s.number_of_installments ? s == null ? void 0 : s.number_of_installments : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? (I = hs(s.frequency)) == null ? void 0 : I.replace(/_/g, " ") : "-"
                  })
                })
              ]
            }),
            F ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "QU/PK0"
                    }),
                    background: !0,
                    value: `${F.bank.bank_name} - ****${F.bank.number.slice(-4)}`,
                    logo: S[F.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 px-4 pt-2 flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "5zmJqL"
                        }),
                        value: `₹ ${gs(F.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "RdQmLp"
                        }),
                        value: (X = ls[F.mandate_status]) == null ? void 0 : X.value,
                        icon: (b = ls[F.mandate_status]) == null ? void 0 : b.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
            g ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "40IXuE"
                    }),
                    background: !0,
                    value: `${g.bank.bank_name} - ****${g.bank.number.slice(-4)}`,
                    logo: S[g.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 px-4 pt-2 flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "SSgIhw"
                        }),
                        value: `₹ ${gs(g.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(d, {
                        label: o._({
                          id: "xtmxre"
                        }),
                        value: (V = ls[g.mandate_status]) == null ? void 0 : V.value,
                        icon: (U = ls[g.mandate_status]) == null ? void 0 : U.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
          ]
        }),
        /* @__PURE__ */ i.jsx(W, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        }),
        L && /* @__PURE__ */ i.jsx(as, {
          fullwidth: !0,
          onClick: L,
          children: G
        })
      ]
    });
  }
  return D ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : Z();
}
const vs = [
  {
    label: o._({
      id: "iHHiiF"
    }),
    value: "installments"
  },
  {
    label: o._({
      id: "cE8shf"
    }),
    value: "fixed"
  }
];
function _i({ currentPlanObject: s, handlePauseCompletion: L }) {
  const { tenantConfig: w, amcs: E } = ms(), H = w == null ? void 0 : w.fallbackLogo, K = w == null ? void 0 : w.showSchemeLogo, { fpapi: M } = es(ns), [G, A] = h(!1), v = /* @__PURE__ */ new Date(), [q, z] = h({}), [S, y] = h({}), [Q, k] = h("initial"), [u, D] = h([]), [p, F] = h(0), f = As(() => ({
    pause_plan_instl_type: vs[Ls(vs, "installments")] || null,
    pause_plan_start_date: u != null && u.includes(new Date((/* @__PURE__ */ new Date()).setDate(v.getDate() + 2)).toDateString()) ? null : new Date((/* @__PURE__ */ new Date()).setDate(v.getDate() + 2))
  }), [
    u
  ]);
  ts(() => {
    Z(), j();
  }, [
    s
  ]);
  function g(C, e) {
    const x = new Date(e);
    return x.setDate(x.getDate() + C), x;
  }
  function $(C, e) {
    const x = [];
    let t = new Date(C);
    for (; t.valueOf() <= new Date(e).valueOf(); )
      x.push(new Date(t).toDateString()), t = new Date(g(1, t));
    return x;
  }
  const os = (C) => C || (s == null ? void 0 : s.end_date), j = async () => {
    A(!0);
    try {
      (await M.fpClient.skip_instructions().fetchAll((s == null ? void 0 : s.object) + "s", s == null ? void 0 : s.id)).data.map((x) => {
        const t = $(new Date(x.from_date), new Date(os(x.to_date)));
        D((a) => a.concat(t)), F((a) => a + Number(x.remaining_installments) + Number(x.skipped_installments));
      }), A(!1);
    } catch {
      k("error"), A(!1);
    }
  }, N = () => {
    let C = 1;
    const e = s.start_date ? new Date(s.start_date) : /* @__PURE__ */ new Date();
    return s != null && s.systematic || (C = I()), new Date(e == null ? void 0 : e.getFullYear(), (e == null ? void 0 : e.getMonth()) + Ms[s == null ? void 0 : s.frequency] * C, e == null ? void 0 : e.getDate());
  }, Z = async () => {
    A(!0);
    const C = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let e;
    try {
      e = await M.fpClient.master_data().fetchScheme(C);
    } catch (a) {
      window.debug.error(a), Y(a);
    }
    let x = {};
    if ((s == null ? void 0 : s.object) === "mf_switch_plan")
      try {
        x = await M.fpClient.master_data().fetchScheme(s == null ? void 0 : s.switch_out_scheme), y(x);
      } catch (a) {
        window.debug.error(a), Y(a);
      }
    const t = K ? {
      name: e == null ? void 0 : e.name,
      investment_option: e == null ? void 0 : e.investment_option,
      schemeLogo: E[e == null ? void 0 : e.amc_id] || H
    } : {
      name: e == null ? void 0 : e.name,
      investment_option: e == null ? void 0 : e.investment_option
    };
    z(t), A(!1);
  }, m = async ({ installments: C, pause_plan_end_date: e, pause_plan_instl_type: x }) => {
    let t;
    const a = new Date(s == null ? void 0 : s.next_installment_date);
    (x == null ? void 0 : x.value) === "installments" ? (t = new Date(a.setMonth(a.getMonth() + (C - 1) * Ms[s == null ? void 0 : s.frequency])), t = xs(new Date(t), "yyyy-mm-dd")) : (x == null ? void 0 : x.value) === "fixed" && (t = xs(new Date(e), "yyyy-mm-dd"));
    const _ = xs(new Date((/* @__PURE__ */ new Date()).setDate(v.getDate() + 1)), "yyyy-mm-dd");
    try {
      A(!0);
      const B = await M.fpClient.skip_instructions().create(s.object + "s", s.id, {
        from: _,
        to: t
      });
      L(B), A(!1);
    } catch (B) {
      A(!1), k("error"), Y(B);
    }
  }, I = () => Math.floor((s == null ? void 0 : s.remaining_installments) / 2) > 10 ? 10 : Math.floor((s == null ? void 0 : s.remaining_installments) / 2), X = () => p >= 2 ? 0 : 2 - p, b = (C) => !!(u != null && u.includes(new Date(C).toDateString()));
  function V() {
    var C;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: q
        }),
        /* @__PURE__ */ i.jsx(_s, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              Hs(S) ? /* @__PURE__ */ i.jsx(i.Fragment, {}) : /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-full ",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "6Xqu8f"
                  }),
                  logo: K && (S != null && S.amc_id) ? E[S == null ? void 0 : S.amc_id] || H : "",
                  value: zs(S.name)
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (C = hs(s.frequency)) == null ? void 0 : C.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(W, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "uVHufo",
              values: {
                0: xs(new Date(s == null ? void 0 : s.next_installment_date), "dd-MMM-yyyy")
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(Ns, {
          onSubmit: m,
          validate: (e) => {
            const x = {};
            e.installments <= 0 && (x.installments = o._({
              id: "L9XIrm",
              values: {
                0: X()
              }
            })), s != null && s.remaining_installments && e.installments > X() && (s != null && s.systematic) && (x.installments = o._({
              id: "B29U8p",
              values: {
                0: X()
              }
            })), s != null && s.remaining_installments && e.installments > I() && !(s != null && s.systematic) && (x.installments = o._({
              id: "B29U8p",
              values: {
                0: I()
              }
            }));
            const t = f.pause_plan_start_date ? f.pause_plan_start_date : new Date((/* @__PURE__ */ new Date()).setDate(v.getDate() + 2));
            return (new Date(e.pause_plan_end_date).valueOf() < new Date(t).valueOf() || new Date(e.pause_plan_end_date).valueOf() > new Date(N()).valueOf()) && (s != null && s.systematic) && (x.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: X()
              }
            })), (new Date(e.pause_plan_end_date).valueOf() < new Date(t).valueOf() || new Date(e.pause_plan_end_date).valueOf() > new Date(N()).valueOf()) && !(s != null && s.systematic) && (x.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: I()
              }
            })), x;
          },
          initialValues: f,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (e) => {
            var x, t;
            return /* @__PURE__ */ i.jsxs("form", {
              onSubmit: e.handleSubmit,
              children: [
                /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "pgRnHV"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "pause_plan_instl_type",
                      initialValue: f.pause_plan_instl_type,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: O(ss),
                      children: (a) => {
                        var _, B;
                        return /* @__PURE__ */ i.jsx(Vs, {
                          name: a.input.name,
                          group: vs,
                          size: "sm",
                          checked: Ls(vs, (B = (_ = a == null ? void 0 : a.input) == null ? void 0 : _.value) == null ? void 0 : B.value),
                          onChange: (c, T) => {
                            a.input.onChange(T);
                          },
                          disabled: []
                        });
                      }
                    })
                  ]
                }),
                ((x = e.values.pause_plan_instl_type) == null ? void 0 : x.value) === "installments" && /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "ZS3Gyi"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "installments",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: O(ss),
                      children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(ws, {
                            id: a.input.name,
                            autoComplete: "off",
                            name: a.input.name,
                            onChange: a.input.onChange,
                            placeholder: o._({
                              id: "eEWdWP"
                            }),
                            status: a.meta.error && a.meta.touched ? "error" : void 0,
                            onInput: (_) => {
                              _.target.value = _.target.value.replace(us("0-9"), "");
                            },
                            autoFocus: !0
                          }),
                          a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "helper",
                              variant: "field",
                              title: `Maximum installments that can be skipped is ${X()}`
                            })
                          }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                        ]
                      })
                    })
                  ]
                }),
                ((t = e.values.pause_plan_instl_type) == null ? void 0 : t.value) === "fixed" && /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(r, {
                      message: o._({
                        id: "BrsP9r"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(is, {
                      name: "pause_plan_end_date",
                      initialValue: f.pause_plan_start_date,
                      allowNull: !0,
                      validate: O(ss),
                      children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(qs, {
                            variant: "keyboardInputDOB",
                            onKeyDown: (_) => _.preventDefault(),
                            disableFuture: !1,
                            name: a.input.name,
                            value: a.input.value,
                            format: "dd-MMM-yyyy",
                            minDate: f.pause_plan_start_date ?? new Date((/* @__PURE__ */ new Date()).setDate(v.getDate() + 2)),
                            maxDate: N(),
                            placeholder: f.pause_plan_start_date ? "" : "Select skip end date",
                            selectedDate: a.input.value,
                            shouldDisableDate: b,
                            error: a.meta.error && a.meta.touched,
                            views: [
                              "date"
                            ],
                            handleDateChange: (_) => {
                              a.input.onChange(_);
                            }
                          }),
                          a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(W, {
                              type: "helper",
                              variant: "field",
                              title: `Maximum installments that can be skipped is ${X()}`
                            })
                          }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ i.jsx(Ss, {
                  children: /* @__PURE__ */ i.jsx("div", {
                    className: "mb-4 pt-1",
                    children: /* @__PURE__ */ i.jsx(as, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: o._({
                        id: "H/E6fZ"
                      })
                    })
                  })
                })
              ]
            });
          }
        })
      ]
    });
  }
  function U() {
    switch (Q) {
      default:
        return V();
      case "initial":
        return V();
      case "error":
        return /* @__PURE__ */ i.jsx(ks, {
          title: o._({
            id: "fgTdCR"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return G ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : U();
}
function fi({ pauseOrder: s, handleGoToPlansList: L }) {
  const w = s == null ? void 0 : s.state.toLowerCase(), [E, H] = h(""), [K, M] = h(""), [G, A] = h(""), [v, q] = h(""), { fpapi: z } = es(ns), { tenantConfig: S, amcs: y } = ms(), Q = S == null ? void 0 : S.fallbackLogo, k = S == null ? void 0 : S.showSchemeLogo, [u, D] = h({}), [p, F] = h(!1), [f, g] = h({}), $ = async () => {
    F(!0);
    let N = "";
    const Z = Zs(s == null ? void 0 : s.plan);
    if (Z === "mf_purchase_plan") {
      const m = await z.fpClient.mf_purchase_plans().fetch(s == null ? void 0 : s.plan);
      g(m), N = m == null ? void 0 : m.scheme;
    } else if (Z === "mf_redemption_plan") {
      const m = await z.fpClient.mf_redemption_plans().fetch(s == null ? void 0 : s.plan);
      g(m), N = m == null ? void 0 : m.scheme;
    } else if (Z === "mf_switch_plan") {
      const m = await z.fpClient.mf_switch_plans().fetch(s == null ? void 0 : s.plan);
      g(m), N = m == null ? void 0 : m.switch_in_scheme;
    }
    z.fpClient.master_data().fetchScheme(N).then((m) => {
      os(m);
    }).catch((m) => {
      window.debug.log(m), Y(m);
    });
  };
  function os(N) {
    if (!N)
      D({});
    else {
      const Z = k ? {
        ...N,
        schemeLogo: y[N == null ? void 0 : N.amc_id] || Q
      } : {
        ...N
      };
      D(Z);
    }
    F(!1);
  }
  ts(() => {
    (w === "active" || w === "pending") && (H(`${Ws(s == null ? void 0 : s.plan)} plan skipped`), M(o._({
      id: "xz7+py",
      values: {
        0: s == null ? void 0 : s.id
      }
    })), q(o._({
      id: "d9gGIK"
    })), A("successInfo")), $();
  }, []);
  function j() {
    var N;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: u
        }),
        /* @__PURE__ */ i.jsxs(Ds, {
          title: E,
          description: K,
          iconName: G,
          children: [
            /* @__PURE__ */ i.jsx(_s, {
              children: /* @__PURE__ */ i.jsxs("div", {
                className: "flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(d, {
                      label: f != null && f.amount ? o._({
                        id: "KqeLJ1"
                      }) : o._({
                        id: "6ssn5V"
                      }),
                      value: f != null && f.amount ? `₹ ${R(f == null ? void 0 : f.amount, 2)}` : f.units ? f.units : "-"
                    })
                  }),
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(d, {
                      label: o._({
                        id: "UMXPdU"
                      }),
                      value: f && f.frequency ? (N = hs(f.frequency)) == null ? void 0 : N.replace(/_/g, " ") : "-"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ i.jsx(Bs, {
              tableData: [
                {
                  label: o._({
                    id: "iFYyXY"
                  }),
                  value: s != null && s.to_date ? `${xs(new Date(s == null ? void 0 : s.to_date), "dd-MMM-yyyy")}` : "-"
                },
                {
                  label: o._({
                    id: "ZS3Gyi"
                  }),
                  value: s != null && s.remaining_installments ? `${s == null ? void 0 : s.remaining_installments}` : "-"
                }
              ]
            })
          ]
        }),
        L && /* @__PURE__ */ i.jsx(as, {
          fullwidth: !0,
          onClick: L,
          children: v
        })
      ]
    });
  }
  return p ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : j();
}
function ni({ currentPlanObject: s, handleStopCompletion: L }) {
  const { tenantConfig: w, amcs: E } = ms(), H = w == null ? void 0 : w.fallbackLogo, K = w == null ? void 0 : w.showSchemeLogo, { fpapi: M } = es(ns), [G, A] = h(!1), [v, q] = h({}), [z, S] = h("initial");
  ts(() => {
    y();
  }, [
    s
  ]);
  const y = async () => {
    A(!0);
    const D = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let p;
    try {
      p = await M.fpClient.master_data().fetchScheme(D);
    } catch (f) {
      window.debug.error(f), Y(f);
    }
    const F = K ? {
      name: p == null ? void 0 : p.name,
      investment_option: p == null ? void 0 : p.investment_option,
      schemeLogo: E[p == null ? void 0 : p.amc_id] || H
    } : {
      name: p == null ? void 0 : p.name,
      investment_option: p == null ? void 0 : p.investment_option
    };
    q(F), A(!1);
  }, Q = async () => {
    A(!0);
    try {
      let D = {};
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? D = await M.fpClient.mf_purchase_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? D = await M.fpClient.mf_redemption_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_switch_plan" && (D = await M.fpClient.mf_switch_plans().cancel(s == null ? void 0 : s.id)), A(!1), L(D);
    } catch (D) {
      A(!1), S("error"), Y(D);
    }
  };
  function k() {
    var D;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: v
        }),
        /* @__PURE__ */ i.jsx(_s, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(d, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (D = hs(s.frequency)) == null ? void 0 : D.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(W, {
          type: "alert",
          variant: "flash",
          title: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "qULvXd",
              values: {
                0: s != null && s.object ? s == null ? void 0 : s.object.split("_")[1] : ""
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(Ns, {
          onSubmit: Q,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (p) => /* @__PURE__ */ i.jsx("form", {
            onSubmit: p.handleSubmit,
            children: /* @__PURE__ */ i.jsx(Ss, {
              children: /* @__PURE__ */ i.jsx("div", {
                className: "mb-4 pt-1",
                children: /* @__PURE__ */ i.jsx(as, {
                  variant: "primary",
                  type: "submit",
                  fullwidth: !0,
                  children: o._({
                    id: "H/E6fZ"
                  })
                })
              })
            })
          })
        })
      ]
    });
  }
  function u() {
    switch (z) {
      default:
        return k();
      case "initial":
        return k();
      case "error":
        return /* @__PURE__ */ i.jsx(ks, {
          title: o._({
            id: "sxmdml"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return G ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : u();
}
function hi({ currentPlanObject: s, handleGoToPlansList: L }) {
  const w = s == null ? void 0 : s.state.toLowerCase(), [E, H] = h(""), [K, M] = h(""), [G, A] = h(""), { fpapi: v } = es(ns), { tenantConfig: q, amcs: z } = ms(), S = q == null ? void 0 : q.fallbackLogo, y = q == null ? void 0 : q.showSchemeLogo, [Q, k] = h({}), [u, D] = h(!1), p = async () => {
    D(!0);
    const g = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    v.fpClient.master_data().fetchScheme(g).then(($) => {
      F($);
    }).catch(($) => {
      window.debug.log($), Y($);
    });
  };
  function F(g) {
    if (!g)
      k([]);
    else {
      const $ = y ? {
        ...g,
        schemeLogo: z[g == null ? void 0 : g.amc_id] || S
      } : {
        ...g
      };
      k($);
    }
    D(!1);
  }
  ts(() => {
    (w === "failed" || w === "cancelled") && (H(o._({
      id: "2i30Zx",
      values: {
        0: ys(s == null ? void 0 : s.id)
      }
    })), A(o._({
      id: "9jt2i+"
    })), M("successInfo")), p();
  }, []);
  function f() {
    var g, $;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ps, {
          scheme: Q
        }),
        /* @__PURE__ */ i.jsx(Ds, {
          title: E,
          description: `Your ${(g = ys(s == null ? void 0 : s.id)) == null ? void 0 : g.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: K,
          children: /* @__PURE__ */ i.jsx(_s, {
            children: /* @__PURE__ */ i.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: s != null && s.amount ? o._({
                      id: "KqeLJ1"
                    }) : o._({
                      id: "6ssn5V"
                    }),
                    value: s != null && s.amount ? `₹ ${R(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                  })
                }),
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(d, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? ($ = hs(s.frequency)) == null ? void 0 : $.replace(/_/g, " ") : "-"
                  })
                })
              ]
            })
          })
        }),
        /* @__PURE__ */ i.jsx(W, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "wxJxUZ"
            })
          }),
          customClass: "mb-5"
        }),
        L && /* @__PURE__ */ i.jsx(as, {
          fullwidth: !0,
          onClick: L,
          children: G
        })
      ]
    });
  }
  return u ? /* @__PURE__ */ i.jsx(fs, {
    variant: "fullpage"
  }) : f();
}
export {
  ti as E,
  fi as P,
  hi as S,
  ni as a,
  _i as b,
  ei as c
};
