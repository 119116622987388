import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { O as l, M as r } from "./index.esm-qyC9L-ym.js";
import { t as m } from "./utils-Bzskk-FI.js";
import { i } from "./index-C-jpp3An.js";
import { S as o } from "./StatusMessage-CBzuZxyP.js";
function v({ kycCheckObject: a, customMessage: n }) {
  const t = (n == null ? void 0 : n.unverified) || i._({
    id: "Y6TECe"
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: a ? /* @__PURE__ */ s.jsxs(s.Fragment, {
      children: [
        /* @__PURE__ */ s.jsx(o, {
          title: a != null && a.status ? i._({
            id: "yhgXfS"
          }) : i._({
            id: "w3UjV0"
          }),
          description: i._({
            id: "zaJV3W",
            values: {
              0: a.id
            }
          }),
          iconName: a != null && a.status ? "successInfo" : "Alert",
          children: /* @__PURE__ */ s.jsx(l, {
            tableData: [
              {
                label: i._({
                  id: "m9snku"
                }),
                value: a == null ? void 0 : a.pan
              }
            ]
          })
        }),
        !(a != null && a.status) && /* @__PURE__ */ s.jsx(r, {
          type: "important",
          variant: "flash",
          title: i._({
            id: "FAbQbf",
            values: {
              notVerifiedMessage: t
            }
          }),
          customClass: "mb-5"
        }),
        m.isStagingOrSandbox && (a != null && a.status) ? /* @__PURE__ */ s.jsx(r, {
          type: "important",
          variant: "flash",
          title: i._({
            id: "C3ThQR"
          }),
          customClass: "mb-5"
        }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
      ]
    }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
  });
}
export {
  v as K
};
