import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as d, useState as i, useEffect as $ } from "react";
import { AppContext as y } from "./AppContext.js";
import { P as D, a as M } from "./index.esm-qyC9L-ym.js";
import { i as o } from "./index-C-jpp3An.js";
import { r as T } from "./sentry-Djb1pAvw.js";
import { F as b } from "./provider-CNUuOe_A.js";
import { LoginContext as N } from "./LoginContext.js";
import { c as Y } from "./checkMigratedUser-D-TLMNz-.js";
import { u as H } from "./useMigratedUser-B10BRJ8r.js";
import { ErrorPage as Z } from "./ErrorPage.js";
import { F as z } from "./FolioListing-CacH-7o2.js";
import { A as _ } from "./Auth-Phxt4zVs.js";
import { P as J } from "./PartnerLogin-DLwbTpzJ.js";
import { W as O } from "./Welcome-HpuiFPhk.js";
function dt({ isPartner: s }) {
  const { fpapi: r } = d(b), { loggedInUser: f, loggedInPartner: h } = d(N), { AppState: { showWelcomePage: m, tenant: x }, setShowWelcomePage: v } = d(y), [L, q] = i({}), [A, n] = i(""), [j, g] = i(null), [a, P] = i(null), [U, I] = i(!0), [c, w] = i(null);
  H({
    user: L
  });
  const k = s ? [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "fEEk23"
      })
    },
    {
      step: 3,
      content: o._({
        id: "GgDGIk"
      })
    }
  ] : [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "zFuF7E"
      })
    },
    {
      step: 3,
      content: o._({
        id: "3oltp5"
      })
    }
  ];
  $(() => {
    E();
  }, [
    m,
    a
  ]);
  const E = async () => {
    var l;
    try {
      if (m)
        n("welcome");
      else if (!(s ? h : f))
        n(s ? "partner_login" : "investor_login");
      else if (s && !a)
        n("assisted_investor_login");
      else {
        const G = s ? a : f.profile.preferred_username, t = (await r.fetchUser(G, x))[0], { isMigratedUser: R } = await Y(t, r), S = ((l = t == null ? void 0 : t.annexure) == null ? void 0 : l.isonboarded) !== "true";
        if (R) {
          const p = await r.fpClient.investor_profiles().fetch(t == null ? void 0 : t.profile);
          g(p), n("folio_listing");
          return;
        }
        if (S)
          w({
            title: o._({
              id: "4piYag"
            }),
            description: o._({
              id: "5WNMUH"
            })
          });
        else {
          const p = await r.fpClient.investor_profiles().fetch(t == null ? void 0 : t.profile);
          g(p), n("folio_listing");
        }
      }
    } catch (u) {
      w({
        title: o._({
          id: "1Y0lw6"
        })
      }), T(u);
    } finally {
      I(!1);
    }
  }, F = () => {
    v(!1);
  }, W = ({ pan: l }) => {
    P(l);
  }, C = () => {
    switch (A) {
      case "welcome":
        return /* @__PURE__ */ e.jsx(O, {
          onAck: F,
          welcomeSteps: k
        });
      case "partner_login":
        return /* @__PURE__ */ e.jsx(J, {
          context: `${window.location.pathname}${window.location.search}`
        });
      case "investor_login":
        return /* @__PURE__ */ e.jsx(_, {
          redirectURL: `${window.location.pathname}${window.location.search}`,
          allowRegistration: !1,
          isGroupInvestment: !1,
          showTestPan: {
            onboarded: !0
          }
        });
      case "assisted_investor_login":
        return /* @__PURE__ */ e.jsx(_, {
          onPreviewData: W,
          allowRegistration: !1,
          isPartner: !0,
          isGroupInvestment: !1,
          redirectURL: `${window.location.pathname}${window.location.search}`,
          showTestPan: {
            onboarded: !0
          }
        });
      case "folio_listing":
        return /* @__PURE__ */ e.jsx(z, {
          investorProfile: j,
          isPartner: s,
          assistedWorkflowPAN: a
        });
      default:
        return;
    }
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(D, {
        title: o._({
          id: "J5xvYY"
        })
      }),
      U ? /* @__PURE__ */ e.jsx(M, {
        variant: "fullpage"
      }) : c ? /* @__PURE__ */ e.jsx(Z, {
        title: c.title,
        description: c.description,
        showDetails: !1,
        showAction: !0,
        actionText: "Retry",
        actionHandler: () => window.location.reload()
      }) : C()
    ]
  });
}
export {
  dt as Folios
};
