import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as u, useMemo as ve, useContext as J, useEffect as le } from "react";
import { a as ce, e as ie, T as ye, M as Q, q as Ee, B as te, k as Xe, l as Re, R as Ge, r as Ue, b as be, P as Se, S as qe, c as se, D as Pe } from "./index.esm-qyC9L-ym.js";
import { AppContext as Ie } from "./AppContext.js";
import { u as xe } from "./useConfiguration-B-lyaBB9.js";
import { F as ue } from "./provider-CNUuOe_A.js";
import { r as ee } from "./sentry-Djb1pAvw.js";
import { LoginContext as we } from "./LoginContext.js";
import { i as W, A as X, a0 as Ye, z as $e, $ as Te, m as ke, a1 as Me, a2 as et, o as Fe, a3 as tt, a4 as nt, a5 as at, a6 as it, k as _e, a7 as Be, a8 as Ae, a9 as rt, aa as st, ab as ot, B as De, ac as he, d as He } from "./utils-Bzskk-FI.js";
import { i as t } from "./index-C-jpp3An.js";
import { c as ze } from "./checkMigratedUser-D-TLMNz-.js";
import { p as We } from "./partiallyOnboardMigratedUser-CSHFHhW4.js";
import { H as Ve } from "./Holdings-Bf_ch-qT.js";
import { ErrorPage as oe } from "./ErrorPage.js";
import { O as Qe } from "./OrderStatus-CRO1IwVe.js";
import { F as Ce } from "./FolioListing-CacH-7o2.js";
import { A as Je } from "./Auth-Phxt4zVs.js";
import { P as lt } from "./PartnerLogin-DLwbTpzJ.js";
import { f as fe, r as ge } from "./validators-BNinzCrT.js";
import { R as Ne, F as re } from "./react-final-form.es-B_J3uV0l.js";
import { u as Oe } from "./useMigratedUser-B10BRJ8r.js";
import { R as dt, a as ct } from "./RedemptionPlanConsent-C4oIfJOj.js";
import { P as Le } from "./Product-DxVYHIQw.js";
import { W as ut } from "./Welcome-HpuiFPhk.js";
function mt({ scheme_details: i, investment_account_id: n, user: j, holdings: k, selectedFolio: h, isPartner: f, onSuccess: x }) {
  const [O, M] = u(!1), [T, R] = u("initial"), [b, r] = u(!1), D = ve(() => E(k), [
    k
  ]), { fpapi: N } = J(ue);
  xe();
  const { loggedInPartner: p, nonPartnerDashboardUser: Z } = J(we), { isMigratedUser: A } = Oe({
    user: j
  }), [L, B] = u(!1), G = (s) => {
    var d, v;
    if (A) {
      const g = (d = k == null ? void 0 : k.folios) == null ? void 0 : d.filter((F) => F.folio_number === s), c = g.length ? (v = g[0].schemes) == null ? void 0 : v.find((F) => F.isin === i.isin) : [];
      W(c) ? B(!0) : B(!1);
    }
  };
  le(() => {
    G(h);
  }, [
    h,
    A
  ]);
  function E(s) {
    if (s) {
      const d = {};
      return s.folios.forEach((v) => {
        v.schemes.forEach((g) => {
          g.isin === i.isin && (d[v.folio_number] = g);
        });
      }), d;
    }
  }
  async function U({ amount: s }) {
    M(!0);
    try {
      const d = {
        mf_investment_account: n,
        scheme: i.isin,
        amount: b ? null : s,
        folio_number: h,
        order_gateway: "rta"
      };
      f && p && !Z && (d.partner = p);
      const v = await N.fpClient.mf_redemptions().create(d);
      M(!1), x({
        createdOrder: v,
        shouldOnboardMigratedUser: L
      });
    } catch (d) {
      M(!1), R("error"), ee(d);
    }
  }
  const q = (s) => {
    s.stopPropagation(), r(!b);
  };
  function I() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: U,
      validate: (s) => {
        const d = {};
        return b || (s.amount || (d.amount = t._({
          id: "7rkFUv"
        })), s.amount && s.amount < i.min_withdrawal_amount && (d.amount = t._({
          id: "u4n9vI",
          values: {
            0: X(i.min_withdrawal_amount)
          }
        })), s.amount && s.amount > i.max_withdrawal_amount && (d.amount = t._({
          id: "uMQeWd",
          values: {
            0: X(i.max_withdrawal_amount)
          }
        })), s.amount && D && h && s.amount > D[h].market_value.redeemable_amount && (d.amount = t._({
          id: "IJDIUX",
          values: {
            0: X(D[h].market_value.redeemable_amount)
          }
        })), Ye(s.amount, i.withdrawal_multiples) === !1 && (d.amount = t._({
          id: "k9cLeP",
          values: {
            0: X(i.withdrawal_multiples)
          }
        }))), d;
      },
      children: (s) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: s.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ie, {
                message: t._({
                  id: "KqeLJ1"
                })
              }),
              /* @__PURE__ */ e.jsx(re, {
                name: "amount",
                children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ye, {
                      id: d.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: d.input.name,
                      value: b ? "" : $e(d.input.value),
                      onChange: (v) => d.input.onChange(Te(v.target.value)),
                      onInput: (v) => {
                        v.target.value = v.target.value.replace(ke("0-9"), "");
                      },
                      placeholder: t._({
                        id: "fkzBBC"
                      }),
                      prefix: "₹",
                      disabled: b,
                      status: d.meta.error && d.meta.touched ? "error" : void 0
                    }),
                    d.meta.error && d.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Q, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    }) : h && /* @__PURE__ */ e.jsx(Q, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "AfhYy2",
                        values: {
                          0: X(Number(D && D[h].market_value.redeemable_amount))
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ee, {
            id: "redeem-all-id",
            checked: b,
            onChange: q,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!h || D && D[h].market_value.redeemable_amount <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function C() {
    switch (T) {
      case "initial":
        return I();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (s) => R("initial")
        });
      default:
        return I();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: O ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: C()
    })
  });
}
function ft({ scheme_details: i, investment_account_id: n, user: j, holdings: k, selectedFolio: h, onSuccess: f, isPartner: x }) {
  const [O, M] = u(!1), [T, R] = u("initial"), [b, r] = u(!1), D = ve(() => q(), [
    k
  ]), { fpapi: N } = J(ue);
  xe();
  const { loggedInPartner: p, nonPartnerDashboardUser: Z } = J(we), { isMigratedUser: A, migratedMfiaList: L, migratedFolioList: B } = Oe({
    user: j
  }), [G, E] = u(!1), U = (g) => {
    var c, F;
    if (A) {
      const o = (c = k == null ? void 0 : k.folios) == null ? void 0 : c.filter((y) => y.folio_number === g), l = o.length ? (F = o[0].schemes) == null ? void 0 : F.find((y) => y.isin === i.isin) : [];
      W(l) ? E(!0) : E(!1);
    }
  };
  le(() => {
    U(h);
  }, [
    h,
    A
  ]);
  function q(g) {
    if (k) {
      const c = {};
      return k.folios.forEach((F) => {
        F.schemes.forEach((o) => {
          o.isin === i.isin && (c[F.folio_number] = o);
        });
      }), c;
    }
  }
  async function I({ units: g }) {
    M(!0);
    try {
      const c = {
        mf_investment_account: n,
        scheme: i.isin,
        units: b ? null : g,
        folio_number: h,
        order_gateway: "rta"
      };
      x && p && !Z && (c.partner = p);
      const F = await N.fpClient.mf_redemptions().create(c);
      M(!1), f({
        createdOrder: F,
        shouldOnboardMigratedUser: G
      });
    } catch (c) {
      M(!1), R("error"), ee(c);
    }
  }
  const C = (g) => {
    g.stopPropagation(), r(!b);
  }, s = (g, c) => {
    const F = g.target.value;
    Me(F, i.withdrawal_multiples_units) !== 0 ? g.preventDefault() : c.input.onChange(F);
  };
  function d() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: I,
      validate: (g) => {
        const c = {};
        return b || (g.units || (c.units = t._({
          id: "7rkFUv"
        })), g.units && g.units < i.min_withdrawal_units && (c.units = t._({
          id: "8Drd8Q",
          values: {
            0: i.min_withdrawal_units
          }
        })), g.units && g.units > i.max_withdrawal_units && (c.units = t._({
          id: "qpXVn6",
          values: {
            0: i.max_withdrawal_units
          }
        })), g.units && D && h && g.units > D[h].holdings.redeemable_units && (c.units = t._({
          id: "XuD/KQ",
          values: {
            0: D[h].holdings.redeemable_units
          }
        })), g.units && Me(g.units, i.withdrawal_multiples_units) !== 0 && (c.units = t._({
          id: "Cj+4Fk",
          values: {
            0: i.withdrawal_multiples_units.toString().split(".")[1].length
          }
        }))), c;
      },
      children: (g) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: g.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ie, {
                message: t._({
                  id: "6ssn5V"
                })
              }),
              /* @__PURE__ */ e.jsx(re, {
                name: "units",
                children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ye, {
                      id: c.input.name,
                      autoComplete: "off",
                      type: "number",
                      step: "any",
                      name: c.input.name,
                      value: b ? "" : c.input.value,
                      onChange: (F) => s(F, c),
                      placeholder: t._({
                        id: "wRfQ3q"
                      }),
                      disabled: b,
                      status: c.meta.error && c.meta.touched ? "error" : void 0
                    }),
                    c.meta.error && c.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(Q, {
                        type: "error",
                        variant: "field",
                        title: c.meta.error
                      })
                    }) : h && /* @__PURE__ */ e.jsx(Q, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "+fgc+n",
                        values: {
                          0: D ? D[h].holdings.redeemable_units : 0
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ee, {
            id: "redeem-all-id",
            checked: b,
            onChange: C,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!h || D && D[h].holdings.redeemable_units <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function v() {
    switch (T) {
      case "initial":
        return d();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (g) => R("initial")
        });
      default:
        return d();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: O ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: v()
    })
  });
}
const je = [
  {
    label: t._({
      id: "4JqC6N"
    }),
    value: "perpetual"
  },
  {
    label: t._({
      id: "/VDmvv"
    }),
    value: "installments"
  },
  {
    label: t._({
      id: "IUSFui"
    }),
    value: "fixed"
  }
];
function gt({ selectedFolio: i, scheme_details: n, investment_account_id: j, onSuccess: k, isPartner: h, investorProfile: f, holdings: x, user: O }) {
  const [M, T] = u("initial"), R = /* @__PURE__ */ new Date(), b = et(n == null ? void 0 : n.swp_frequency_specific_data), [r, D] = u(b[0].value), [N, p] = u(!1), { fpapi: Z } = J(ue), { tenantConfig: A } = xe(), { loggedInPartner: L, nonPartnerDashboardUser: B } = J(we);
  ve(() => I(), [
    x
  ]);
  const { isMigratedUser: G } = Oe({
    user: O
  }), [E, U] = u(!1), q = (o) => {
    var l, y;
    if (G) {
      const a = (l = x == null ? void 0 : x.folios) == null ? void 0 : l.filter((z) => z.folio_number === o), m = a.length ? (y = a[0].schemes) == null ? void 0 : y.find((z) => z.isin === n.isin) : [];
      W(m) ? U(!0) : U(!1);
    }
  };
  le(() => {
    q(i);
  }, [
    i,
    G
  ]);
  function I(o) {
    if (x) {
      const l = {};
      return x.folios.forEach((y) => {
        y.schemes.forEach((a) => {
          a.isin === n.isin && (l[y.folio_number] = a);
        });
      }), l;
    }
  }
  const C = ve(() => {
    var o;
    return {
      frequency: b[0],
      swp_instl_type: je[Fe(je, "perpetual")] || null,
      swp_start_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 1)),
      swp_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + (((o = A == null ? void 0 : A.app_constants) == null ? void 0 : o.plan_max_start_days) || 0))),
      swp_end_date: r === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 2)) : new Date(R.getFullYear(), R.getMonth() + tt[r] * (n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments), R.getDate() + 1),
      swp_end_max_date: nt(r),
      swp_max_date: new Date(2099, 11, 31)
    };
  }, [
    r,
    b,
    n,
    R
  ]);
  async function s({ amount: o, frequency: l, swp_start_date: y, swp_instl_type: a, installments: m, swp_end_date: z }) {
    (a == null ? void 0 : a.value) === "perpetual" ? m = 1200 : (a == null ? void 0 : a.value) === "fixed" && (m = Ae(y, z, l.value, n == null ? void 0 : n.swp_frequency_specific_data[l.value].dates));
    let Y = rt(y, l.value);
    Y !== null && (Y = _e(Y)), await Z.fetchEmailAndPhone(f);
    const ne = {
      mf_investment_account: j,
      scheme: n.isin,
      frequency: l.value,
      folio_number: i,
      amount: o,
      systematic: !0,
      installment_day: l.value === "daily" ? null : st(y, l.value),
      activate_after: Y,
      number_of_installments: +m,
      order_gateway: "rta"
    };
    if (h && L) {
      p(!0);
      let K = {
        ...ne
      };
      K = {
        ...K
      }, B || (K.partner = L);
      try {
        const S = await Z.fpClient.mf_redemption_plans().create(K);
        p(!1), k({
          createdOrder: S,
          shouldOnboardMigratedUser: E
        });
      } catch (S) {
        window.debug.error(S), p(!1), T("error"), ee(S);
      }
    } else {
      p(!0);
      try {
        const K = await Z.fpClient.mf_redemption_plans().create(ne);
        k({
          createdOrder: K,
          shouldOnboardMigratedUser: E
        }), p(!1);
      } catch (K) {
        window.debug.error(K), p(!1), T("error"), p(!1), ee(K);
      }
    }
  }
  const d = ([o, l], y, { changeValue: a }) => {
    l[0] = l[0] === null ? C.swp_start_date : l[0], l[0] = Be(l[0], l[1], n == null ? void 0 : n.swp_frequency_specific_data[l[1]].min_installments), a(y, o, () => l[0]);
  }, v = ([o, l], y, { changeValue: a }) => {
    l[0] !== null && (l[0] = ot(l[1], n == null ? void 0 : n.swp_frequency_specific_data[l[1]].dates)), a(y, o, () => l[0]);
  }, g = (o, l) => Ae(o || C.swp_start_date, l, r, n == null ? void 0 : n.swp_frequency_specific_data[r].dates) <= n.swp_frequency_specific_data[r].min_installments;
  function c() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: s,
      mutators: {
        updateSwpEndDate_mutator: d,
        updateSwpStartDate_mutator: v
      },
      validate: (o) => {
        var y;
        const l = {};
        return o.amount || (l.amount = t._({
          id: "7rkFUv"
        })), o.amount && Me(o.amount, (y = n == null ? void 0 : n.swp_frequency_specific_data[r]) == null ? void 0 : y.amount_multiples) !== 0 && (l.amount = t._({
          id: "k9cLeP",
          values: {
            0: n.swp_frequency_specific_data[r].amount_multiples
          }
        })), o.amount && o.amount < (n == null ? void 0 : n.swp_frequency_specific_data[r].min_installment_amount) && (l.amount = t._({
          id: "xegGnh",
          values: {
            0: n.swp_frequency_specific_data[r].min_installment_amount
          }
        })), o.amount && i && o.amount > (n == null ? void 0 : n.swp_frequency_specific_data[r].max_installment_amount) && (l.amount = t._({
          id: "lR8aoB",
          values: {
            0: X(Number(n == null ? void 0 : n.swp_frequency_specific_data[r].max_installment_amount))
          }
        })), o.installments < (n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments) && (l.installments = t._({
          id: "MpguFb",
          values: {
            0: n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments
          }
        })), o.installments > 1200 && (l.installments = t._({
          id: "j7AzBN"
        })), o.swp_end_date < o.swp_start_date && (l.swp_end_date = t._({
          id: "T31Ev8"
        })), l;
      },
      initialValues: C,
      subscription: {
        submitting: !0,
        values: !0
      },
      children: (o) => {
        var l, y;
        return /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: o.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "ydIU1z"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "frequency",
                  initialValue: C.frequency,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Xe, {
                        name: a.input.name,
                        value: a.input.value,
                        onChange: (m) => {
                          const z = [
                            o.values.swp_start_date,
                            m.value,
                            o.values.swp_end_date
                          ];
                          o.form.mutators.updateSwpStartDate_mutator("swp_start_date", z), o.form.mutators.updateSwpEndDate_mutator("swp_end_date", z), D(m.value), a.input.onChange(m);
                        },
                        options: b
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(Q, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      }),
                      a.input.value.value === "daily" && /* @__PURE__ */ e.jsx(Q, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "pKTj3U"
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "/DJBcB"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "amount",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ye, {
                        type: "text",
                        name: a.input.name,
                        value: $e(a.input.value),
                        onChange: (m) => a.input.onChange(Te(m.target.value)),
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(ke("0-9"), "");
                        },
                        placeholder: t._({
                          id: "fkzBBC"
                        }),
                        prefix: "₹",
                        autoComplete: "off",
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Q, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : i && /* @__PURE__ */ e.jsx(Q, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "ZdOgV8",
                          values: {
                            0: X(Number(n.swp_frequency_specific_data[r].min_installment_amount), 2)
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "jvN2aB"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_start_date",
                  initialValue: null,
                  validate: fe(ge),
                  allowNull: !0,
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Re, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (m) => m.preventDefault(),
                        disableFuture: !1,
                        name: a.input.name,
                        value: a.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: C.swp_start_date,
                        maxDate: C.swp_start_max_date,
                        selectedDate: a.input.value || null,
                        error: a.meta.error && a.meta.touched,
                        views: [
                          "date"
                        ],
                        placeholder: "Select start date",
                        labelFunc: (m) => at(m, r),
                        shouldDisableDate: (m) => it(m, n == null ? void 0 : n.swp_frequency_specific_data[r].dates, r),
                        handleDateChange: (m) => {
                          g(m, o.values.swp_end_date || C.swp_end_date) && o.form.mutators.updateSwpEndDate_mutator("swp_end_date", [
                            m,
                            r
                          ]), a.input.onChange(m);
                        }
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(Q, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      }),
                      a.input.value && /* @__PURE__ */ e.jsx(Q, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "GLAJXE",
                          values: {
                            0: _e(a.input.value, "dd-MMM-yyyy")
                          }
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "G7dHtg"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_instl_type",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => {
                    var m, z;
                    return /* @__PURE__ */ e.jsx(Ge, {
                      name: a.input.name,
                      value: a.input.value,
                      group: je,
                      size: "sm",
                      checked: Fe(je, (z = (m = a == null ? void 0 : a.input) == null ? void 0 : m.value) == null ? void 0 : z.value),
                      onChange: (Y, ne) => {
                        a.input.onChange(ne);
                      }
                    });
                  }
                })
              ]
            }),
            ((l = o.values.swp_instl_type) == null ? void 0 : l.value) === "installments" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "ZS3Gyi"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "installments",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ye, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        onChange: a.input.onChange,
                        placeholder: t._({
                          id: "M1QsOZ"
                        }),
                        status: a.meta.error && a.meta.touched ? "error" : void 0,
                        onInput: (m) => {
                          m.target.value = m.target.value.replace(ke("0-9"), "");
                        },
                        inputMode: "numeric"
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Q, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : i && /* @__PURE__ */ e.jsx(Q, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "gRc0h2",
                          values: {
                            0: n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            ((y = o.values.swp_instl_type) == null ? void 0 : y.value) === "fixed" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "BrsP9r"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_end_date",
                  initialValue: null,
                  allowNull: !0,
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Re, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (m) => m.preventDefault(),
                        disableFuture: !1,
                        name: a.input.name,
                        value: a.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: Be(o.values.swp_start_date, r, n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments),
                        maxDate: C.swp_end_max_date,
                        selectedDate: a.input.value,
                        labelFunc: (m) => m ? _e(m, "dd-MMM-yyyy") : "Select end date",
                        error: a.meta.error && a.meta.touched,
                        views: [
                          "date"
                        ],
                        handleDateChange: (m) => {
                          a.input.onChange(m);
                        }
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Q, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : i && /* @__PURE__ */ e.jsx(Q, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "gRc0h2",
                          values: {
                            0: n == null ? void 0 : n.swp_frequency_specific_data[r].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ e.jsx(te, {
                type: "submit",
                variant: i ? "primary" : "disabled",
                fullwidth: !0,
                children: t._({
                  id: "pZ49hE"
                })
              })
            })
          ]
        });
      }
    });
  }
  function F() {
    switch (M) {
      case "initial":
        return c();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (o) => {
            D(b[0].value), T("initial");
          }
        });
      default:
        return c();
    }
  }
  return N ? /* @__PURE__ */ e.jsx(ce, {
    variant: "fullpage"
  }) : F();
}
function Ze(i) {
  const { tenantConfig: n } = xe(), j = i.delivery_mode, k = () => /* @__PURE__ */ e.jsx(gt, {
    ...i
  }), h = () => {
    const x = () => /* @__PURE__ */ e.jsx(mt, {
      ...i
    }), O = () => /* @__PURE__ */ e.jsx(ft, {
      ...i
    });
    return j === "Demat" ? O() : /* @__PURE__ */ e.jsx(Ue, {
      variant: "pills",
      group: [
        {
          title: t._({
            id: "9eWti6"
          }),
          content: x()
        },
        {
          title: t._({
            id: "3saf/Z"
          }),
          content: O()
        }
      ]
    });
  }, f = () => {
    var M, T, R, b, r, D;
    const x = (R = (T = (M = n == null ? void 0 : n.app_constants) == null ? void 0 : M.disabled_orders_mode) == null ? void 0 : T.withdraw) == null ? void 0 : R.one_time, O = (D = (r = (b = n == null ? void 0 : n.app_constants) == null ? void 0 : b.disabled_orders_mode) == null ? void 0 : r.withdraw) == null ? void 0 : D.recurring;
    return j === "Demat" ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      }
    ] : x && O ? [] : x ? [
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: k()
      }
    ] : O ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      }
    ] : [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      },
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: k()
      }
    ];
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Ue, {
      group: f()
    })
  });
}
function _t({ scheme_details: i, folio: n, isPartner: j }) {
  const { AppState: { tenant: k } } = J(Ie), [h, f] = u(() => sessionStorage.getItem("redemption_workflow_stage") || "folio_listing"), [x, O] = u(null), [M, T] = u(null), [R, b] = u(null), [r, D] = u(null), [N, p] = u(null), [Z, A] = u(""), [L, B] = u(!1), [G, E] = u(""), [U, q] = u(() => {
    const _ = sessionStorage.getItem("redemption_workflow_order_id");
    return _ ? JSON.parse(_) : null;
  }), [I, C] = u(!1), [s, d] = u(n), [v, g] = u(null), [c, F] = u(null), { fpapi: o } = J(ue), { loggedInPartner: l } = J(we), [y, a] = u(null);
  le(() => {
    (async () => j && await m())();
  }, [
    c
  ]);
  async function m() {
    if (!l)
      f("login");
    else if (!c)
      f("investor_pan_input");
    else {
      C(!0);
      let _;
      try {
        const w = await o.fpClient.investor_profiles().fetchAll({
          pan: c
        });
        _ = w == null ? void 0 : w.data[0], O(_);
        const V = await o.fetchUser(_ == null ? void 0 : _.pan, k), de = V == null ? void 0 : V[0], { isMigratedUser: me, migratedMfiaList: pe, migratedFolioList: Ke } = await ze(de, o);
        if (me && await We(o, de, pe, Ke), a(de), !_) {
          C(!1), A(t._({
            id: "Pe37pS"
          })), E(t._({
            id: "S40aCP"
          })), f("error");
          return;
        }
      } catch (w) {
        A(t._({
          id: "BnnSJR"
        })), E(t._({
          id: "NHmBXI"
        })), f("error"), C(!1), ee(w);
        return;
      }
      if (W(i)) {
        C(!1), f("holdings");
        return;
      }
      const P = {};
      try {
        const w = await o.fpClient.mf_investment_accounts().fetchAll({
          investor: c
        });
        if (!w.data || !w.data.length) {
          C(!1), A(t._({
            id: "Pe37pS"
          })), E(t._({
            id: "S40aCP"
          })), f("error");
          return;
        }
        T(w.data);
        const V = await o.fpClient.investor_reports().fetchHoldings({
          investment_account_id: w.data[0].old_id
        });
        let de = !1;
        if (V.folios.forEach((me) => {
          me.folio_number === n && (de = !0), me.schemes.forEach((pe) => {
            pe.isin === i.isin && (P[me.folio_number] = pe);
          });
        }), de || (g(null), d(null)), W(P)) {
          C(!1), f("no_holdings");
          return;
        }
        b(V), D(P);
      } catch (w) {
        C(!1), A(t._({
          id: "BnnSJR"
        })), E(t._({
          id: "rAP48C"
        })), f("error"), ee(w);
        return;
      }
      if (U != null && U.id) {
        if (U.object === "mf_redemption")
          try {
            const w = await o.fpClient.mf_redemptions().fetch(U == null ? void 0 : U.id);
            p(w), d(w.folio_number);
          } catch (w) {
            window.debug.error(w), ee(w);
          }
        else if (U.object === "mf_redemption_plan")
          try {
            const w = await o.fpClient.mf_redemption_plans().fetch(U == null ? void 0 : U.id);
            p(w), d(w.folio_number);
          } catch (w) {
            window.debug.error(w), ee(w);
          }
      }
      C(!1);
    }
  }
  function z(_) {
    const { createdOrder: P, shouldOnboardMigratedUser: w } = _, V = {
      id: P.id,
      object: P.object
    };
    if (sessionStorage.setItem("redemption_workflow_order_id", JSON.stringify(V)), q(V), p(P), w) {
      p(P), f("onboard_migrated_user");
      return;
    }
    sessionStorage.setItem("redemption_workflow_stage", "finished"), f("finished");
  }
  function Y() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.removeItem("redemption_workflow_stage");
    const P = window.location.href.replace("withdraw", "checkout");
    window.location.href = P;
  }
  function ne() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.setItem("redemption_workflow_stage", "order");
  }
  function K({ pan: _ }) {
    j && (f("folio_listing"), F(_));
  }
  const S = () => {
    var _;
    return !W(v) && (v != null && v.dp_id) || !He(M) && ((_ = M[0].folio_defaults) != null && _.demat_account) ? "Demat" : "Physical";
  };
  function $() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        ae(),
        !W(r) && /* @__PURE__ */ e.jsx(Ze, {
          investorProfile: x,
          scheme_details: i,
          investment_account_id: M && M[0].id,
          delivery_mode: S(),
          holdings: R,
          user: y,
          selectedFolio: s,
          onSuccess: z,
          isPartner: j
        })
      ]
    });
  }
  function ae() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        s ? /* @__PURE__ */ e.jsxs(qe, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "t6MpeI"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: s || "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (_) => {
                    _.preventDefault(), B(!L);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: r && s ? `₹ ${X(r[s].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: r && s ? r[s].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: r && s ? `₹ ${X(r[s].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: r && s ? t._({
                      id: "/glBYd",
                      values: {
                        0: _e(new Date(r[s].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: r && s ? `₹ ${X(r[s].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Pe, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (_) => {
              _.preventDefault(), B(!L);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        x && /* @__PURE__ */ e.jsx(be, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: L,
          toggleModal: B,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Ce, {
            investorProfile: x,
            scheme_details: i,
            isPartner: j,
            handleFolioSelection: (_) => {
              g(_), d(_.number), B(!L);
            },
            assistedWorkflowPAN: c
          })
        })
      ]
    });
  }
  function H() {
    switch (h) {
      case "order":
        return $();
      case "login":
        return /* @__PURE__ */ e.jsx(lt, {
          context: `${window.location.pathname}${window.location.search}`
        });
      case "investor_pan_input":
        return /* @__PURE__ */ e.jsx(Je, {
          onPreviewData: K,
          allowRegistration: !0,
          isPartner: j,
          redirectURL: window.location.href,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: Z,
          description: G
        });
      case "folio_listing":
        return x && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(be, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              f("initial"), B(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(Ce, {
              investorProfile: x,
              scheme_details: i,
              isPartner: j,
              handleFolioSelection: (_) => {
                B(!1), g(_), d(_.number), f("order");
              },
              assistedWorkflowPAN: c
            })
          })
        });
      case "finished":
        return !W(i) && !W(N) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(Qe, {
            scheme: i,
            mf_order: N,
            isPartner: j,
            handleInvestAgain: Y,
            handleRetry: ne
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "hfWrIS"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: Y
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(Ve, {
          investorProfile: x,
          onSchemeSelection: (_) => {
            var P;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${_}${(P = he(window.location.search)) != null && P.length ? "&" + he(window.location.search) : ""}`;
          },
          isPartner: j,
          assistedWorkflowPAN: c
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(ht, {
          onContinue: () => {
            window.location.href = `${window.location.origin + De}/onboarding?isPartner=${j}&redirectURL=${encodeURIComponent(`${window.location.origin + De}/assisted-withdraw/?isin=${i.isin}&isPartner=${j}`)}`;
          }
        });
      default:
        return $();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: I ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: H()
    })
  });
}
const ht = ({ onContinue: i }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Se, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => i(),
        children: "Continue"
      })
    })
  ]
});
function xt({ scheme_details: i, folio: n, isGroupInvestment: j }) {
  const { AppState: { tenant: k } } = J(Ie), [h, f] = u("folio_listing"), [x, O] = u(null), [M, T] = u(null), [R, b] = u(null), [r, D] = u(null), [N, p] = u(null), [Z, A] = u(""), [L, B] = u(!1), [G, E] = u(""), [U, q] = u(!1), [I, C] = u(n), [s, d] = u(null), { fpapi: v } = J(ue), { loggedInUser: g } = J(we), [c, F] = u(null), l = new URLSearchParams(window.location.search).get("employer");
  le(() => {
    async function S() {
      if (!g)
        f("login");
      else {
        q(!0);
        let $;
        try {
          $ = await v.getInvestorProfile(), O($);
          const H = await v.fetchUser(g.profile.preferred_username, k), _ = H == null ? void 0 : H[0], { isMigratedUser: P, migratedMfiaList: w, migratedFolioList: V } = await ze(_, v);
          P && await We(v, _, w, V), F(_);
        } catch (H) {
          A(t._({
            id: "XHIfHi"
          })), E(t._({
            id: "ycsXEB"
          })), f("error"), q(!1), ee(H);
          return;
        }
        if (W(i)) {
          q(!1), f("holdings");
          return;
        }
        const ae = {};
        try {
          const H = await v.fpClient.mf_investment_accounts().fetchAll({
            investor: $.pan
          });
          if (!H.data || !H.data.length) {
            q(!1), A(t._({
              id: "qmRkny"
            })), E(t._({
              id: "0f0Xqs"
            })), f("error");
            return;
          }
          T(H.data);
          const _ = await v.fpClient.investor_reports().fetchHoldings({
            investment_account_id: H.data[0].old_id
          });
          let P = !1;
          if (_.folios.forEach((w) => {
            w.folio_number === n && (P = !0), w.schemes.forEach((V) => {
              V.isin === i.isin && (ae[w.folio_number] = V);
            });
          }), P || (d(null), C(null)), W(ae)) {
            q(!1), f("no_holdings");
            return;
          }
          b(_), D(ae);
        } catch (H) {
          q(!1), A(t._({
            id: "XHIfHi"
          })), E(t._({
            id: "6p5tt4"
          })), f("error"), ee(H);
          return;
        }
        q(!1);
      }
    }
    S();
  }, [
    g,
    v,
    i,
    n
  ]);
  const y = (S) => {
    p(S), f("finished");
  };
  function a(S) {
    const { createdOrder: $, shouldOnboardMigratedUser: ae } = S;
    if (ae) {
      p($), f("onboard_migrated_user");
      return;
    }
    $.object === "mf_redemption" ? (p($), f("redeem_confirmation")) : $.object === "mf_redemption_plan" && (p($), f("redeem_plan_confirmation"));
  }
  function m() {
    const $ = he(window.location.search) ? "group-checkout" : "checkout", H = window.location.href.replace("withdraw", $);
    window.location.href = H;
  }
  const z = () => {
    var S;
    return !W(s) && (s != null && s.dp_id) || !He(M) && ((S = M[0].folio_defaults) != null && S.demat_account) ? "Demat" : "Physical";
  };
  function Y() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        ne(),
        !W(r) && /* @__PURE__ */ e.jsx(Ze, {
          investorProfile: x,
          scheme_details: i,
          investment_account_id: M && M[0].id,
          delivery_mode: z(),
          holdings: R,
          user: c,
          selectedFolio: I,
          onSuccess: a
        })
      ]
    });
  }
  function ne() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        I ? /* @__PURE__ */ e.jsxs(qe, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "5WmZx5"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: I ?? "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (S) => {
                    S.preventDefault(), B(!L);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: r && I ? `₹ ${X(r[I].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: r && I ? r[I].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: r && I ? `₹ ${X(r[I].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: r && I ? t._({
                      id: "/glBYd",
                      values: {
                        0: _e(new Date(r[I].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: r && I ? `₹ ${X(r[I].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Pe, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (S) => {
              S.preventDefault(), B(!L);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        x && /* @__PURE__ */ e.jsx(be, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: L,
          toggleModal: B,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Ce, {
            investorProfile: x,
            scheme_details: i,
            handleFolioSelection: (S) => {
              d(S), C(S.number), B(!L);
            }
          })
        })
      ]
    });
  }
  function K() {
    switch (h) {
      case "order":
        return Y();
      case "login":
        return /* @__PURE__ */ e.jsx(Je, {
          redirectURL: window.location.href,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: Z,
          description: G
        });
      case "redeem_confirmation":
        return /* @__PURE__ */ e.jsx(ct, {
          investorProfile: x,
          id: N == null ? void 0 : N.id,
          showSchemeDetails: !1,
          onSuccess: y,
          action: "confirm",
          objectType: N == null ? void 0 : N.object
        });
      case "redeem_plan_confirmation":
        return /* @__PURE__ */ e.jsx(dt, {
          investorProfile: x,
          id: N == null ? void 0 : N.id,
          onSuccess: y,
          showSchemeDetails: !1,
          action: "confirm"
        });
      case "folio_listing":
        return x && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(be, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              f("initial"), B(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(Ce, {
              investorProfile: x,
              scheme_details: i,
              handleFolioSelection: (S) => {
                B(!1), d(S), C(S.number), f("order");
              },
              workflow: "withdraw"
            })
          })
        });
      case "finished":
        return !W(i) && !W(N) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(Qe, {
            scheme: i,
            mf_order: N,
            handleInvestAgain: m,
            handleRetry: () => f("order")
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "idb6Q+"
          }),
          description: t._({
            id: "t7nOza"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: m
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(Ve, {
          investorProfile: x,
          onSchemeSelection: (S) => {
            var $;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${S}${($ = he(window.location.search)) != null && $.length ? "&" + he(window.location.search) : ""}`;
          }
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(wt, {
          onContinue: () => {
            window.location.href = `${window.location.origin + De}/onboarding?${j ? `isGroupInvestment=${j}` : ""}${j ? `&employer=${l}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + De}/${j ? "group-checkout" : "checkout"}/?isin=${i.isin}${j ? `&employer=${l}` : ""}`)}`;
          }
        });
      default:
        return Y();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: U ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: K()
    })
  });
}
const wt = ({ onContinue: i }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Se, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => i(),
        children: "Continue"
      })
    })
  ]
});
function Ht({ isin: i, folio: n, isPartner: j, isGroupInvestment: k }) {
  const { fpapi: h } = J(ue), { AppState: { showWelcomePage: f }, setShowWelcomePage: x } = J(Ie), { tenantConfig: O, amcs: M } = xe(), T = O == null ? void 0 : O.fallbackLogo, R = O == null ? void 0 : O.showSchemeLogo, [b, r] = u("initial"), [D, N] = u(q), [p, Z] = u(null), [A, L] = u(!1), B = j ? [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "qc1MEz"
      })
    },
    {
      step: 3,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 4,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 5,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ] : [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 3,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 4,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ];
  le(() => {
    i && (L(!0), h.fpClient.master_data().fetchScheme(i).then((s) => {
      G(s);
    }).catch((s) => {
      window.debug.log(s), L(!1), ee(s);
    }));
  }, []);
  function G(s) {
    if (!s)
      r("schemeNotFound");
    else {
      const d = R ? {
        ...s,
        schemeLogo: M[s == null ? void 0 : s.amc_id] || T
      } : {
        ...s
      };
      Z(d);
    }
    L(!1);
  }
  function E() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(Se, {
          title: p ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Le, {
          scheme: p
        }),
        j ? /* @__PURE__ */ e.jsx(_t, {
          scheme_details: p,
          folio: n,
          isPartner: j,
          isGroupInvestment: k
        }) : /* @__PURE__ */ e.jsx(xt, {
          scheme_details: p,
          folio: n,
          isGroupInvestment: k
        })
      ]
    });
  }
  function U() {
    switch (b) {
      case "initial":
        return E();
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "1f+uYj"
          }),
          description: t._({
            id: "3MzgnE"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return E();
    }
  }
  le(() => {
    N(q());
  }, [
    f
  ]);
  function q() {
    return f ? "welcome" : "folio";
  }
  function I() {
    x(!1), N("withdraw");
  }
  function C() {
    return D === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(Se, {
          title: p ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Le, {
          scheme: p
        }),
        /* @__PURE__ */ e.jsx(ut, {
          onAck: I,
          welcomeSteps: B
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: A ? /* @__PURE__ */ e.jsx(ce, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: U()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: C()
  });
}
export {
  Ht as Withdraw
};
