import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Y, useState as v, useEffect as A } from "react";
import { R as q, F as ee } from "./react-final-form.es-B_J3uV0l.js";
import { i as l } from "./index-C-jpp3An.js";
import { a as te, P as se, D as ne, T as re, M as P, B as U, f as ae, h as oe } from "./index.esm-qyC9L-ym.js";
import { u as ie } from "./useConfiguration-B-lyaBB9.js";
import { C as de } from "./CopyButton-SQqhrKz9.js";
import { F as $ } from "./provider-CNUuOe_A.js";
import { a as me } from "./Auth-Phxt4zVs.js";
import { O as le, m as ce, P as ue, s as pe, t as fe, f as he } from "./utils-Bzskk-FI.js";
import { r as z } from "./sentry-Djb1pAvw.js";
function ge() {
  const { fpapi: E } = Y($), f = E.fpClient, [y, r] = v(null), [k, c] = v([]), [i, u] = v(), b = async (a) => f.consents().createConsent(a), x = async (a) => f.consents().createAcquisition(a), F = async (a) => f.consents().collectAcquisition(a), w = async (a) => {
    var h, g;
    if ((i == null ? void 0 : i.status) === "pending" && ((h = i == null ? void 0 : i.acquisition_details) == null ? void 0 : h.pending_retry) !== 0)
      return f.consents().collectAcquisition(a);
    {
      const m = (g = (await x({
        consents: [
          y == null ? void 0 : y.id
        ]
      })).data[0]) == null ? void 0 : g.id;
      return f.consents().collectAcquisition(m);
    }
  }, I = async (a) => {
    const _ = sessionStorage.getItem("consent-acquisition");
    if (_) {
      const [h, g] = _.split("|");
      try {
        const d = await f.consents().fetchConsent(h), m = await f.consents().fetchAcquisition(g);
        if (d.given_for.sort().join(",") === a.given_for.sort().join(",") && (r(d), u(m), (d == null ? void 0 : d.status) === "pending" && (m == null ? void 0 : m.status) === "pending" || !(m != null && m.acquisition_details)))
          return !1;
      } catch {
        return !0;
      }
    }
    return !0;
  };
  return {
    consentObj: y,
    acquisitions: k,
    collectedAcquisition: i,
    initiateConsent: b,
    initiateAcquisition: x,
    initiateCollect: F,
    shouldInitiateSendOTP: I,
    initiateSendOTP: async (a) => {
      var _, h;
      try {
        if (await I(a)) {
          sessionStorage.removeItem("consent-acquisition");
          const d = await b(a);
          r(d);
          const m = {
            consents: [
              d.id
            ]
          }, { data: C } = await x(m);
          c(C);
          const O = (_ = C[0]) == null ? void 0 : _.id;
          if (O) {
            const S = (h = C[0]) == null ? void 0 : h.acquisition_details;
            if (u(C[0]), !S) {
              const e = await F(O);
              u(e);
            }
            sessionStorage.setItem("consent-acquisition", `${d.id}|${O}`);
          }
        }
      } catch (g) {
        throw z(g), new Error("Something went wrong. Couldn't initiate OTP");
      }
    },
    validatedInitiateCollect: w
  };
}
const Ie = ({ handleSubmit: E, pageTitle: f, investorProfile: y, collectAcquisition: r }) => {
  var D, G;
  const { fpapi: k } = Y($), { tenantConfig: c } = ie(), [i, u] = v(!0), [b, x] = v(""), [F, w] = v(!0), [I, L] = v(""), a = (D = c == null ? void 0 : c.app_constants) == null ? void 0 : D.otp_challenge, _ = r != null && r.acquisition_details ? r == null ? void 0 : r.acquisition_details : {}, { initiateCollect: h } = ge(), { pending_retry: g, retry_after: d, expires_at: m, email: C, mobile_number: O, mode: S } = _, [e, H] = v({
    otpRetries: g,
    retryAfterTime: d,
    expiredAt: m,
    emailAddress: C,
    mobileNumber: O,
    mode: S
  }), W = () => new Date(e == null ? void 0 : e.expiredAt).valueOf() < (/* @__PURE__ */ new Date()).valueOf() ? 0 : e != null && e.retryAfterTime && new Date(e == null ? void 0 : e.retryAfterTime).valueOf() > (/* @__PURE__ */ new Date()).valueOf() ? Math.round(Math.abs(((/* @__PURE__ */ new Date()).valueOf() - new Date(e == null ? void 0 : e.retryAfterTime).valueOf()) / 1e3)) : c && c.app_constants.otp_cooldown_interval / 1e3, K = W(), [M, B] = v(K);
  A(() => {
    if (new Date(e == null ? void 0 : e.expiredAt).valueOf() < (/* @__PURE__ */ new Date()).valueOf())
      u(!1), B(0);
    else {
      const s = W();
      B(s);
    }
  }, [
    e == null ? void 0 : e.retryAfterTime,
    e == null ? void 0 : e.expiredAt
  ]);
  const j = me(), R = "123456";
  (e == null ? void 0 : e.otpRetries) ?? ((G = c == null ? void 0 : c.app_constants) == null || G.otp_maximum_resend_attempts), A(() => {
    y && Q();
  }, [
    y
  ]), A(() => {
    j.hasStopped && u(!1);
  }, [
    j.hasStopped
  ]), A(() => {
    i && M && j.start(M), !i && !j.hasStopped && j.stop();
  }, [
    i,
    M
  ]);
  const Q = async () => {
    try {
      let s = "";
      const n = le(e == null ? void 0 : e.emailAddress);
      switch (a) {
        case "sms_or_email_otp":
          s = l._({
            id: "qSpD1c",
            values: {
              maskedEmail: n
            }
          });
          break;
        case "email_otp":
          s = l._({
            id: "qSpD1c",
            values: {
              maskedEmail: n
            }
          });
          break;
        case "sms_otp":
          s = l._({
            id: "8WbYAg"
          });
          break;
        default:
          s = l._({
            id: "qSpD1c",
            values: {
              maskedEmail: n
            }
          });
          break;
      }
      L(s), w(!1);
    } catch (s) {
      window.debug.error(s), x(l._({
        id: "i5nd2d"
      })), u(!1), w(!1), z(s);
    }
  }, J = async (s) => {
    var o, T;
    const n = {
      mode: S,
      otp: s == null ? void 0 : s.otp
    };
    w(!0);
    try {
      const p = await k.fpClient.consents().verifyAcquisition(r == null ? void 0 : r.id, n);
      (p == null ? void 0 : p.status) === "completed" ? (u(!1), E()) : x("Invalid OTP"), sessionStorage.removeItem("consent-acquisition"), w(!1);
    } catch (p) {
      sessionStorage.removeItem("consent-acquisition");
      const N = (T = (o = p == null ? void 0 : p.data) == null ? void 0 : o.error) != null && T.message.length ? he(p.data.error.message) : "Something went wrong";
      x(N), w(!1);
    }
  };
  function V([s, n], o, { changeValue: T }) {
    T(o, s, () => n);
  }
  const X = async () => {
    u(!0);
    try {
      const s = await h(r == null ? void 0 : r.id), { pending_retry: n, retry_after: o, expires_at: T, email: p, mobile_number: N, mode: Z } = s.acquisition_details;
      H({
        otpRetries: n,
        retryAfterTime: o,
        expiredAt: T,
        emailAddress: p,
        mobileNumber: N,
        mode: Z
      });
    } catch (s) {
      x(s.data.error.message), B(0), u(!1);
    }
  };
  return F ? /* @__PURE__ */ t.jsx(te, {
    variant: "fullpage"
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(se, {
        title: f,
        semiBold: !0
      }),
      /* @__PURE__ */ t.jsx(ne, {
        type: "text",
        text: I
      }),
      /* @__PURE__ */ t.jsx(q, {
        onSubmit: J,
        mutators: {
          prepopulateOtp: V
        },
        validate: (s) => {
          const n = {};
          return s.otp || (n.otp = l._({
            id: "7rkFUv"
          })), s.otp && new Date(e == null ? void 0 : e.expiredAt).valueOf() < (/* @__PURE__ */ new Date()).valueOf() && (n.otp = l._({
            id: "UKvhWN"
          })), n;
        },
        children: (s) => /* @__PURE__ */ t.jsxs("form", {
          onSubmit: s.handleSubmit,
          children: [
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-8",
              children: /* @__PURE__ */ t.jsx(ee, {
                name: "otp",
                subscription: {
                  touched: !0,
                  error: !0,
                  value: !0
                },
                validateFields: [],
                children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                  children: [
                    /* @__PURE__ */ t.jsx(re, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: n.input.value,
                      onChange: (o) => {
                        n.input.onChange(o.target.value);
                      },
                      status: n.meta.error && n.meta.touched ? "error" : void 0,
                      placeholder: l._({
                        id: "QtWqop"
                      }),
                      maxLength: 6,
                      onInput: (o) => {
                        o.target.value = o.target.value.replace(ce("0-9"), "");
                      },
                      onBlur: () => {
                        n.input.onChange(n.input.value.trim());
                      },
                      autoFocus: !0
                    }),
                    /* @__PURE__ */ t.jsxs("div", {
                      className: "flex justify-between",
                      children: [
                        /* @__PURE__ */ t.jsxs("div", {
                          children: [
                            n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(t.Fragment, {
                              children: /* @__PURE__ */ t.jsx(P, {
                                type: "error",
                                variant: "field",
                                title: n.meta.error
                              })
                            }),
                            i ? /* @__PURE__ */ t.jsx(P, {
                              variant: "field",
                              type: "important",
                              title: l._({
                                id: "2i4hU/",
                                values: {
                                  0: ue(j.value)
                                }
                              })
                            }) : /* @__PURE__ */ t.jsx(t.Fragment, {})
                          ]
                        }),
                        !i && j.value === 0 ? /* @__PURE__ */ t.jsx("div", {
                          children: /* @__PURE__ */ t.jsx(U, {
                            type: "button",
                            customClass: "text-size-14",
                            variant: "text",
                            fullwidth: !0,
                            onClick: (o) => {
                              o.preventDefault(), o.stopPropagation(), X(), s.form.resetFieldState("otp");
                            },
                            children: l._({
                              id: "+BdH9W"
                            })
                          })
                        }) : /* @__PURE__ */ t.jsx(t.Fragment, {})
                      ]
                    })
                  ]
                })
              })
            }),
            b ? /* @__PURE__ */ t.jsx(P, {
              type: "error",
              variant: "flash",
              title: b,
              customClass: "mb-5"
            }) : /* @__PURE__ */ t.jsx(t.Fragment, {}),
            /* @__PURE__ */ t.jsx(U, {
              type: "submit",
              variant: "primary",
              fullwidth: !0,
              onClick: () => {
                const n = Object.keys(s.errors);
                n.length && pe(n[0]);
              },
              children: l._({
                id: "2iVSoY"
              })
            }),
            fe.isStagingOrSandbox ? /* @__PURE__ */ t.jsx(ae, {
              customClass: "mt-4",
              children: /* @__PURE__ */ t.jsx(oe, {
                title: l._({
                  id: "9qdkM4"
                }),
                label: R,
                children: /* @__PURE__ */ t.jsx(de, {
                  value: R,
                  onClick: () => s.form.mutators.prepopulateOtp("otp", R)
                })
              })
            }) : null
          ]
        })
      })
    ]
  });
};
export {
  Ie as O,
  ge as u
};
