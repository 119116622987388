import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as s, useState as o, useEffect as c } from "react";
import { AppContext as C } from "./AppContext.js";
import { F as S } from "./provider-CNUuOe_A.js";
import { LoginContext as F } from "./LoginContext.js";
import { P as I, a as N } from "./index.esm-qyC9L-ym.js";
import { r as v } from "./sentry-Djb1pAvw.js";
import { i } from "./index-C-jpp3An.js";
import { W } from "./Welcome-HpuiFPhk.js";
import { N as _ } from "./NomineeListing-DE-X1-lf.js";
import { A as E } from "./Auth-Phxt4zVs.js";
function G() {
  const { fpapi: n } = s(S), { loggedInUser: p } = s(F), { AppState: { showWelcomePage: a }, setShowWelcomePage: d } = s(C), [g, m] = o(f), [u, x] = o(null), [h, l] = o(!0), [j, w] = o([]), P = [
    {
      step: 1,
      content: i._({
        id: "NsNdUa"
      })
    },
    {
      step: 2,
      content: i._({
        id: "5ukLfI"
      })
    }
  ];
  c(() => {
    n && n.getInvestorProfile().then(async (t) => {
      if (t) {
        x(t);
        const r = await n.fpClient.related_parties().fetchAll({
          profile: t == null ? void 0 : t.id
        });
        w((r == null ? void 0 : r.data) ?? []);
      }
      l(!1);
    }).catch((t) => {
      l(!1), v(t);
    });
  }, []), c(() => {
    m(f());
  }, [
    a
  ]);
  function f() {
    return a ? "welcome" : "nominee";
  }
  function A() {
    d(!1), m("nominee");
  }
  function L() {
    return g === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(W, {
        onAck: A,
        welcomeSteps: P
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: h ? /* @__PURE__ */ e.jsx(N, {
        variant: "fullpage"
      }) : p ? /* @__PURE__ */ e.jsx(_, {
        investorProfile: u,
        nomineeList: j
      }) : /* @__PURE__ */ e.jsx(E, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(I, {
        title: i._({
          id: "xBj6DA"
        })
      }),
      L()
    ]
  });
}
export {
  G as ManageNominees
};
