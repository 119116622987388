import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as c, useState as n, useEffect as w } from "react";
import { P as m, a as T, D } from "./index.esm-qyC9L-ym.js";
import { AppContext as G } from "./AppContext.js";
import { LoginContext as M } from "./LoginContext.js";
import { i as r } from "./index-C-jpp3An.js";
import { F as N } from "./provider-CNUuOe_A.js";
import { r as k } from "./sentry-Djb1pAvw.js";
import { W as V } from "./Welcome-HpuiFPhk.js";
import { O as X } from "./OrderHistoryListing-DyCbmi5P.js";
import { A as Y } from "./Auth-Phxt4zVs.js";
function re() {
  const { loggedInUser: t } = c(M), { fpapi: i } = c(N), [v, _] = n({}), [g, y] = n(!1), [P, a] = n(!0), [A, S] = n({}), { AppState: { showWelcomePage: f, tenant: C }, setShowWelcomePage: F } = c(G), [L, l] = n(p), b = [
    {
      step: 1,
      content: r._({
        id: "SYXp7g"
      })
    },
    {
      step: 2,
      content: r._({
        id: "EI9fU0"
      })
    }
  ];
  w(() => {
    (async () => {
      var d, u, x;
      if (i && t)
        try {
          a(!0);
          const o = await i.fetchUser((d = t == null ? void 0 : t.profile) == null ? void 0 : d.preferred_username, C);
          if (o != null && o[0].annexure.isonboarded && (o == null ? void 0 : o[0].annexure.isonboarded) === "false")
            return;
          const E = ((x = (u = t == null ? void 0 : t.profile) == null ? void 0 : u.preferred_username) == null ? void 0 : x.toUpperCase()) ?? "", H = i.getInvestorProfile(), R = i.fpClient.mf_investment_accounts().fetchAll({
            investor: E
          }), [s, I] = await Promise.all([
            H,
            R
          ]);
          s != null && s.employer && y(!0);
          const h = I.data.filter((O) => {
            var j;
            return O.primary_investor === ((j = t == null ? void 0 : t.profile) == null ? void 0 : j.fp_identifier);
          });
          if (!h.length)
            return;
          _(s), S(h[0]);
        } catch (o) {
          window.debug.error(o), k(o);
        } finally {
          a(!1);
        }
      else
        a(!1);
    })();
  }, []), w(() => {
    l(p());
  }, [
    f
  ]);
  function p() {
    return f ? "welcome" : "transactionHistory";
  }
  function J() {
    F(!1), l("transactionHistory");
  }
  function W() {
    return L === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(m, {
          title: r._({
            id: "ol5JJc"
          })
        }),
        /* @__PURE__ */ e.jsx(V, {
          onAck: J,
          welcomeSteps: b
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx("div", {
        className: "mb-20",
        children: P ? /* @__PURE__ */ e.jsx(T, {
          variant: "fullpage"
        }) : t ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(m, {
              title: r._({
                id: "ol5JJc"
              })
            }),
            /* @__PURE__ */ e.jsx(D, {
              type: "text",
              text: r._({
                id: "/iaRLN"
              })
            }),
            /* @__PURE__ */ e.jsx(X, {
              isGroupInvestment: g,
              investorProfile: v,
              mfInvestmentAccount: A
            })
          ]
        }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(m, {
              title: r._({
                id: "+VJ95o"
              })
            }),
            /* @__PURE__ */ e.jsx(Y, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                onboarded: !0
              }
            })
          ]
        })
      })
    });
  }
  return W();
}
export {
  re as ManageOrderHistory
};
