import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Y, useState as i, useMemo as ge, useLayoutEffect as le, useEffect as fe } from "react";
import { a as ne, M as K, B as I, O as m, c as he } from "./index.esm-qyC9L-ym.js";
import { ae as Ce, E as G, D as Me, A as _, k as J, U as Le, S as ke, i as Ne, f as ve, B as ye, ac as Ee, h as je, l as De } from "./utils-Bzskk-FI.js";
import { u as de } from "./useConfiguration-B-lyaBB9.js";
import { F as ue } from "./provider-CNUuOe_A.js";
import { r as se } from "./sentry-Djb1pAvw.js";
import { i as t } from "./index-C-jpp3An.js";
import { AppContext as p } from "./AppContext.js";
import { S as Q } from "./StatusMessage-CBzuZxyP.js";
import { M as Ie } from "./MandateListing-7eU3x5KZ.js";
const Fe = [
  "pending",
  "cancelled",
  "confirmed",
  "submitted",
  "successful",
  "failed",
  "reversed"
], Be = [
  "pending",
  "success",
  "failed",
  "timedout",
  "initiated",
  "approved",
  "rejected",
  "submitted",
  ""
], qe = [
  [
    "awaitingPayment",
    "underProcess",
    "",
    "",
    "awaitingPayment",
    "",
    "",
    "",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "",
    "reversed"
  ]
], Pe = [
  [
    "awaitingPayment",
    "underProcess",
    "",
    "",
    "awaitingPayment",
    "",
    "",
    "underProcess",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed"
  ]
], $e = (b) => {
  const e = `Your purchase reference is ${b}`;
  return {
    paymentNotFound: {
      title: "Awaiting payment",
      description: e,
      buttonText: "Complete payment",
      icon: "Alert",
      helpText: "Your order will be processed after payment completion",
      helpTextType: "alert"
    },
    awaitingPayment: {
      title: "Payment processing",
      description: e,
      buttonText: "Invest again",
      icon: "Alert",
      helpText: "We are waiting for payment confirmation from your bank. You will be able to complete payment after 10 minutes if the payment is not successful.",
      helpTextType: "alert"
    },
    underProcess: {
      title: "Under process",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    successful: {
      title: "Successful",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    cancelled: {
      title: "Cancelled",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    reversed: {
      title: "Reversed",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    paymentUnsuccessful: {
      title: "Payment failed",
      description: e,
      buttonText: "Retry payment",
      icon: "Error",
      helpText: "The amount debited from your bank account will be refunded within 5 working days. You can retry payment after 30 mins to complete this order.",
      helpTextType: "alert"
    },
    expired: {
      title: "Expired",
      description: e,
      buttonText: "Invest again",
      icon: "Error",
      helpText: "Order expired due to pending payment",
      helpTextType: "important"
    },
    failed: {
      title: "Failed",
      description: e,
      buttonText: "Invest again",
      icon: "Error"
    }
  };
};
function Se({ investorProfile: b, scheme: e, mf_purchase: o, payment: d, handleInvestAgain: r, handleRetry: D, showSchemeName: a, isGroupInvestment: l, isPartner: u, isFotOrder: N }) {
  var $;
  const { AppState: { tenant: n } } = Y(p), x = o.state.toLowerCase(), C = d ? ($ = d == null ? void 0 : d.status) == null ? void 0 : $.toLowerCase() : "", L = o.failure_code, [F, v] = i(t._({
    id: "s0Estp"
  })), [B, y] = i(""), [c, E] = i("Error"), [M, A] = i(t._({
    id: "wd9ucl"
  })), [H, V] = i(""), [O, R] = i(""), [U, w] = i(null), [P, q] = i(!1), [ee, Z] = i(null), { fpapi: te } = Y(ue), { tenantConfig: S, banks: re, amcs: we } = de(), z = ge(() => {
    var h, j, k, X, ie, ae;
    const f = (j = (h = S == null ? void 0 : S.fundList) == null ? void 0 : h.find((Te) => Te.isin === e.isin)) == null ? void 0 : j.nfo;
    return ((k = S == null ? void 0 : S.groupInvestment) == null ? void 0 : k.days) && ((X = S == null ? void 0 : S.groupInvestment) == null ? void 0 : X.cutOffDays) && Ce({
      salaryDay: (ie = S == null ? void 0 : S.groupInvestment) == null ? void 0 : ie.days[0],
      salaryCutoffDay: (ae = S == null ? void 0 : S.groupInvestment) == null ? void 0 : ae.cutOffDays[0],
      NFO: f,
      purchaseDate: o.created_at
    });
  }, [
    e
  ]);
  le(() => {
    l ? oe() : be();
  }, []);
  const xe = (f) => l ? "Salary" : f ? Le(f) : "-";
  async function be() {
    if (d && b) {
      q(!0);
      try {
        const { data: X } = await te.fpClient.bank_accounts().fetchAll({
          profile: b == null ? void 0 : b.id
        }), ie = X.find((ae) => ae.old_id === (d == null ? void 0 : d.from_bank_account_id));
        Z(ie);
      } catch (X) {
        se(X);
      } finally {
        q(!1);
      }
    }
    const f = Fe.indexOf(x), h = Be.indexOf(C);
    if (f === -1 || h === -1) return;
    let j;
    (d == null ? void 0 : d.method) === "EMANDATE" ? j = Pe[f][h] : j = qe[f][h], j === "failed" && L === "order_expiry" && (j = "expired");
    const k = $e(o == null ? void 0 : o.id)[j];
    k && (v(k.title), y(u ? t._({
      id: "znLBZG",
      values: {
        0: o.id
      }
    }) : t._({
      id: "sNrEAc",
      values: {
        0: o.id
      }
    })), A(u ? t._({
      id: "quOB9l"
    }) : k.buttonText), E(k.icon), V(u ? t._({
      id: "5SyDtU"
    }) : k.helpText), R(k.helpTextType));
  }
  async function oe() {
    q(!0);
    try {
      const f = await te.fpClient.mf_settlement_details().fetchAll({
        mf_purchase: o.id
      });
      w(f);
    } catch (f) {
      window.debug.error(f), se(f);
    }
    q(!1), x === "created" || x === "pending" || x === "submitted" || x === "confirmed" ? (y(`Your purchase reference is ${o.id}`), v(t._({
      id: "oiliCI"
    })), A(t._({
      id: "quOB9l"
    })), E("successInfo")) : x === "successful" ? (v(t._({
      id: "EbM80r"
    })), y(t._({
      id: "sNrEAc",
      values: {
        0: o.id
      }
    })), A(t._({
      id: "quOB9l"
    })), E("successInfo")) : x === "failed" && (v(t._({
      id: "mVq3N+"
    })), y(t._({
      id: "WX839d"
    })), A(t._({
      id: "quOB9l"
    })), E("Error"));
  }
  function ce() {
    return l ? x === "failed" ? D : r : u ? r : x === "pending" && !C || x === "failed" && C === "failed" ? D : r;
  }
  function g() {
    return !!(l && !U && x === "pending");
  }
  function T(f, h, j) {
    var X;
    const k = [
      {
        label: t._({
          id: "4Zu577"
        }),
        value: `₹ ${_(f.amount)}`
      },
      {
        label: t._({
          id: "rs9/UI"
        }),
        value: "One time"
      },
      {
        label: t._({
          id: "ItmT+q"
        }),
        value: f.created_at ? J(new Date(f.created_at), "dd-MMM-yyyy") : "-"
      },
      {
        label: t._({
          id: "cXcPd1"
        }),
        value: f.folio_number ? f.folio_number : "New folio"
      },
      !u && {
        label: t._({
          id: "lFCwug"
        }),
        value: xe(h == null ? void 0 : h.method)
      },
      !u && {
        label: t._({
          id: "0EjvnG"
        }),
        value: l ? U ? U.id : "-" : h != null && h.id ? h.id : "-"
      }
    ];
    return j && !l && k.push({
      label: t._({
        id: "/dl6Or"
      }),
      colSpan: "basis-full",
      value: j ? `${j.bank_name} (*****${j.account_number.slice(-4)})` : "-",
      logo: re[(X = j == null ? void 0 : j.ifsc_code) == null ? void 0 : X.slice(0, 4)] || (S == null ? void 0 : S.fallbackLogo)
    }), k;
  }
  function W() {
    return /* @__PURE__ */ s.jsx(m, {
      title: a && e.name,
      tableData: T(o, d, ee)
    });
  }
  return P ? /* @__PURE__ */ s.jsx(ne, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      " ",
      /* @__PURE__ */ s.jsx(Q, {
        title: F,
        description: B,
        iconName: c,
        children: W()
      }),
      g() && /* @__PURE__ */ s.jsx(K, {
        type: "helper",
        variant: "flash",
        title: t._({
          id: "Gi+PAV",
          values: {
            0: z != null && z.consideredSalaryDate ? `in ${new Date(z == null ? void 0 : z.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(z == null ? void 0 : z.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      H && /* @__PURE__ */ s.jsx(K, {
        type: O,
        variant: "flash",
        title: H,
        customClass: "mb-5"
      }),
      u && !N && /* @__PURE__ */ s.jsx(I, {
        variant: "primary",
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_purchase&action=confirm&id=${o.id}`, n), "_blank", "noopener=true"),
        customClass: "mb-2",
        children: t._({
          id: "pcAQRl"
        })
      }),
      (r || D && Me(d == null ? void 0 : d.created_at)) && /* @__PURE__ */ s.jsx(I, {
        variant: "secondary",
        fullwidth: !0,
        onClick: ce(),
        customClass: "mb-2",
        children: M
      })
    ]
  });
}
function Ae({ scheme: b, mf_purchase_plan: e, payment: o, handleInvestAgain: d, handleRetry: r, showSchemeName: D, isGroupInvestment: a, isPartner: l, investorProfile: u, isFotOrder: N }) {
  var oe, ce;
  let n = e.state.toLowerCase();
  const x = o != null && o.status ? (oe = o == null ? void 0 : o.status) == null ? void 0 : oe.toLowerCase() : "", [C, L] = i(""), [F, v] = i(""), [B, y] = i(""), [c, E] = i(t._({
    id: "wd9ucl"
  })), [M, A] = i(null), [H, V] = i(!1), [O, R] = i(!1), { fpapi: U } = Y(ue), { tenantConfig: w } = de(), { AppState: { tenant: P } } = Y(p), [q, ee] = i(t._({
    id: "jG/4Ps"
  })), Z = ge(() => {
    var g, T, W, $, f, h;
    if (a) {
      const j = (T = (g = w == null ? void 0 : w.fundList) == null ? void 0 : g.find((k) => k.isin === b.isin)) == null ? void 0 : T.nfo;
      return ((W = w == null ? void 0 : w.groupInvestment) == null ? void 0 : W.days) && (($ = w == null ? void 0 : w.groupInvestment) == null ? void 0 : $.cutOffDays) && ke({
        salaryDay: (f = w == null ? void 0 : w.groupInvestment) == null ? void 0 : f.days[0],
        salaryCutoffDay: (h = w == null ? void 0 : w.groupInvestment) == null ? void 0 : h.cutOffDays[0],
        NFO: j,
        purchaseDate: e == null ? void 0 : e.created_at
      });
    }
  }, [
    b
  ]);
  le(() => {
    a ? re() : xe();
  }, []), fe(() => {
    te(O) && ee("Plan will be activated after investor provides consent and authorizes the mandate");
  }, [
    O
  ]);
  const te = (g) => !!(g && (g.mandate_status.toUpperCase() === "CREATED" || g.mandate_status.toUpperCase() === "RECEIVED")), S = async (g) => {
    if (g)
      try {
        return await U.fpClient.mandates().fetch(Number(g));
      } catch (T) {
        window.debug.log(T), se(T);
      }
  }, re = () => {
    n === "created" || n === "active" || n === "completed" ? (L("Plan registered"), v(t._({
      id: "w+DUPJ",
      values: {
        0: e.id
      }
    })), E(t._({
      id: "quOB9l"
    })), y("successInfo")) : n === "failed" && (L("Plan Failed"), v(t._({
      id: "buRUzo"
    })), E(t._({
      id: "quOB9l"
    })), y("Error"));
  }, we = (g) => {
    const T = new Date(g);
    return T.getDay() === 5 ? 3 : T.getDay() === 6 ? 2 : 1;
  }, z = async (g) => {
    let T;
    try {
      T = await U.fpClient.mf_purchases().fetchAll({
        plan: g == null ? void 0 : g.id
      });
    } catch (k) {
      window.debug.log(k), se(k);
    }
    if (!T.data.length)
      return null;
    const W = T.data[0], $ = new Date(g == null ? void 0 : g.created_at), f = new Date(new Date($).setDate($.getDate() + 1)), h = new Date(new Date($).setDate($.getDate() + we($))), j = new Date(W == null ? void 0 : W.scheduled_on);
    return j.toDateString() === $.toDateString() || j.toDateString() === f.toDateString() || j.toDateString() === h.toDateString() ? T.data[0] : null;
  }, xe = async () => {
    V(!0), n = e.state.toLowerCase();
    const g = e == null ? void 0 : e.payment_source, T = await z(e);
    Ne(T) ? n === "created" || n === "active" || n === "completed" ? (L(t._({
      id: "M0nG+B"
    })), E(t._({
      id: "quOB9l"
    })), v(l ? t._({
      id: "znLBZG",
      values: {
        0: e.id
      }
    }) : t._({
      id: "w+DUPJ",
      values: {
        0: e.id
      }
    })), y("successInfo")) : n === "failed" && (L(t._({
      id: "jcgQHg"
    })), v(t._({
      id: "buRUzo"
    })), E(t._({
      id: "quOB9l"
    })), y("Error")) : (A(T), n === "created" || n === "active" || n === "completed" ? (L(t._({
      id: "M0nG+B"
    })), E(t._({
      id: "quOB9l"
    })), v(l ? t._({
      id: "znLBZG",
      values: {
        0: e.id
      }
    }) : t._({
      id: "w+DUPJ",
      values: {
        0: e.id
      }
    })), y("successInfo")) : n === "failed" && (L(t._({
      id: "jcgQHg"
    })), v(t._({
      id: "buRUzo"
    })), E(t._({
      id: "quOB9l"
    })), y("Error")));
    const W = await S(g);
    R(W), x === "failed" && E(t._({
      id: "vgDUPm"
    })), V(!1);
  };
  function be() {
    x === "failed" ? r() : d();
  }
  return H ? /* @__PURE__ */ s.jsx(ne, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Q, {
        title: C,
        description: F,
        iconName: B,
        children: /* @__PURE__ */ s.jsx(m, {
          title: D && b.name,
          tableData: [
            {
              label: t._({
                id: "4Zu577"
              }),
              value: `₹ ${_(e.amount)}`
            },
            {
              label: t._({
                id: "UMXPdU"
              }),
              value: (ce = ve(e.frequency)) == null ? void 0 : ce.replace(/_/g, " ")
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: e.folio_number ? e.folio_number : "New folio"
            },
            {
              label: t._({
                id: "lFCwug"
              }),
              value: a ? "Salary" : e.payment_method === "mandate" ? "Mandate" : "Not setup"
            },
            {
              label: t._({
                id: "osv0u0"
              }),
              value: e.created_at ? J(new Date(e.created_at), "dd-MMM-yyyy") : "-"
            }
          ]
        })
      }),
      M && !l && /* @__PURE__ */ s.jsxs(s.Fragment, {
        children: [
          /* @__PURE__ */ s.jsx(he, {
            value: t._({
              id: "R2xBSf"
            }),
            background: !0,
            customClass: "mb-4 items-center"
          }),
          /* @__PURE__ */ s.jsx(Se, {
            scheme: b,
            mf_purchase: M,
            isFotOrder: N,
            payment: o,
            showSchemeName: D,
            isPartner: l
          })
        ]
      }),
      te(O) ? /* @__PURE__ */ s.jsx("div", {
        style: {
          marginBottom: "-4rem"
        },
        children: /* @__PURE__ */ s.jsx(Q, {
          title: "Mandate details",
          iconName: "",
          children: /* @__PURE__ */ s.jsx(Ie, {
            investorProfile: u,
            mandate_id: e == null ? void 0 : e.payment_source,
            status: [
              ""
            ],
            limit: null,
            isPartner: l
          })
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      q && l && /* @__PURE__ */ s.jsx(K, {
        type: "alert",
        variant: "flash",
        title: q,
        customClass: "mb-5"
      }),
      a && /* @__PURE__ */ s.jsx(K, {
        type: "helper",
        variant: "flash",
        title: t._({
          id: "/tWtLh",
          values: {
            0: Z != null && Z.consideredSalaryDate ? ` ${new Date(Z == null ? void 0 : Z.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(Z == null ? void 0 : Z.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      l && !N && /* @__PURE__ */ s.jsx(I, {
        variant: "primary",
        customClass: "mb-2",
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_purchase_plan&action=confirm&id=${e.id}`, P), "_blank", "noopener=true"),
        children: t._({
          id: "31q1Lm"
        })
      }),
      l && N && /* @__PURE__ */ s.jsx(I, {
        variant: "primary",
        customClass: "mb-2",
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/payment?order_id=${e.id}`, P), "_blank", "noopener=true"),
        children: t._({
          id: "pcAQRl"
        })
      }),
      !(e != null && e.payment_method) && !a && !l && /* @__PURE__ */ s.jsx(I, {
        variant: "primary",
        customClass: "mb-2",
        fullwidth: !0,
        onClick: () => {
          window.location.href = window.location.origin + ye + `/plan-update?id=${e.id}&type=edit&edit_field=payment_method`;
        },
        children: t._({
          id: "2r8S3W"
        })
      }),
      (d || r) && /* @__PURE__ */ s.jsxs("div", {
        className: "flex items-center",
        children: [
          /* @__PURE__ */ s.jsx(I, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => be(),
            customClass: "flex-1 px-6 mb-4",
            children: c
          }),
          a && /* @__PURE__ */ s.jsx(I, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              window.location.href = window.location.origin + ye + `/plans-list?isGroupInvestment=true&${Ee(window.location.search)}`;
            },
            customClass: "flex-1 ml-2 mb-4",
            children: t._({
              id: "d9gGIK"
            })
          })
        ]
      })
    ]
  });
}
function Oe({ scheme: b, mf_redemption_plan: e, handleRetry: o, handleInvestAgain: d, showSchemeName: r, isPartner: D }) {
  var c;
  const { AppState: { tenant: a } } = Y(p), l = e.state.toLowerCase(), [u, N] = i(""), [n, x] = i(""), [C, L] = i(""), [F, v] = i(""), [B] = i("Plan will be activated after investor provides consent");
  fe(() => {
    l === "active" || l === "pending" || l === "confirmed" || l === "submitted" || l === "successful" || l === "created" ? (N(t._({
      id: "M0nG+B"
    })), x(D ? t._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), v(t._({
      id: "K7WMZD"
    })), L("successInfo")) : l === "failed" && (N(t._({
      id: "jcgQHg"
    })), x(D ? t._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), v(t._({
      id: "JGhVxc"
    })), L("Error"));
  }, []);
  function y() {
    return l === "failed" ? o : d;
  }
  return /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Q, {
        title: u,
        description: n,
        iconName: C,
        children: /* @__PURE__ */ s.jsx(m, {
          title: r && b.name,
          tableData: [
            {
              label: t._({
                id: "uzz6vZ"
              }),
              value: e != null && e.amount ? `₹ ${_(e.amount)}` : "-"
            },
            {
              label: t._({
                id: "Sgxaf5"
              }),
              value: e.frequency ? `${(c = ve(e.frequency)) == null ? void 0 : c.replace(/_/g, " ")}` : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: t._({
                id: "osv0u0"
              }),
              value: e != null && e.created_at ? `${J(new Date(e.created_at), "dd-MMM-yyyy")}` : "-"
            },
            e != null && e.end_date ? {
              label: t._({
                id: "w3AOkt"
              }),
              value: e != null && e.end_date && (e != null && e.end_date) ? new Date(e == null ? void 0 : e.end_date).getFullYear() >= 2099 ? "Until you stop" : J(new Date(e == null ? void 0 : e.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      B && D && /* @__PURE__ */ s.jsx(K, {
        type: "alert",
        variant: "flash",
        title: B,
        customClass: "mb-5"
      }),
      D && /* @__PURE__ */ s.jsx(I, {
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_redemption_plan&action=confirm&id=${e == null ? void 0 : e.id}`, a), "_blank", "noopener=true"),
        customClass: "mb-2",
        children: t._({
          id: "31q1Lm"
        })
      }),
      (o || d) && /* @__PURE__ */ s.jsxs(I, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: y(),
        children: [
          F,
          " "
        ]
      })
    ]
  });
}
function Ue({ scheme: b, mf_redemption: e, handleInvestAgain: o, showSchemeName: d, isPartner: r }) {
  const { AppState: { tenant: D } } = Y(p), a = e.state.toLowerCase(), [l, u] = i(""), [N, n] = i(""), [x, C] = i(""), [L, F] = i(""), [v] = i("Order will be processed after investor provides consent");
  return fe(() => {
    a === "pending" || a === "confirmed" || a === "submitted" || a === "successful" ? (u(t._({
      id: "9CS0ZY"
    })), n(r ? t._({
      id: "lKqNAW",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "/8rqV/",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), F(t._({
      id: "K7WMZD"
    })), C("successInfo")) : a === "failed" && (u(t._({
      id: "4qtFW3"
    })), n(r ? t._({
      id: "FvapA5"
    }) : t._({
      id: "CsBuck"
    })), F(t._({
      id: "K7WMZD"
    })), C("Error"));
  }, []), /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Q, {
        title: l,
        description: N,
        iconName: x,
        children: /* @__PURE__ */ s.jsx(m, {
          title: d && b.name,
          tableData: [
            {
              label: e != null && e.amount ? t._({
                id: "uzz6vZ"
              }) : t._({
                id: "7P4OtI"
              }),
              value: e.amount ? `₹ ${_(e.amount)}` : e.units ? e.units : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: t._({
                id: "1/YYB1"
              }),
              value: e.created_at ? J(new Date(e.created_at), "dd-MMM-yyyy") : "-"
            }
          ]
        })
      }),
      v && r && /* @__PURE__ */ s.jsx(K, {
        type: "alert",
        variant: "flash",
        title: v,
        customClass: "mb-5"
      }),
      r && /* @__PURE__ */ s.jsx(I, {
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_redemption&action=confirm&id=${e.id}`, D), "_blank", "noopener=true"),
        customClass: "mb-2",
        children: t._({
          id: "31q1Lm"
        })
      }),
      o && /* @__PURE__ */ s.jsx(I, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: o,
        children: L
      })
    ]
  });
}
function Ze({ scheme: b, mf_switch: e, handleInvestAgain: o, showSchemeName: d, isPartner: r }) {
  const { AppState: { tenant: D } } = Y(p), a = e.state.toLowerCase(), [l, u] = i(""), [N, n] = i(""), [x, C] = i(""), [L, F] = i(!1), [v, B] = i(""), { fpapi: y } = Y(ue), [c, E] = i({}), { tenantConfig: M, amcs: A } = de(), H = M == null ? void 0 : M.fallbackLogo, V = M == null ? void 0 : M.showSchemeLogo, [O] = i("Order will be processed after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return le(() => {
    async function U() {
      F(!0);
      try {
        const w = await y.fpClient.master_data().fetchScheme(e.switch_in_scheme), P = await y.fpClient.master_data().fetchScheme(e.switch_out_scheme), q = V && A[P == null ? void 0 : P.amc_id] || H;
        E({
          ...e,
          schemeLogo: q,
          switch_in_scheme_name: w.name,
          switch_out_scheme_name: P.name
        }), a === "pending" || a === "confirmed" || a === "submitted" || a === "successful" ? (u(t._({
          id: "9CS0ZY"
        })), n(r ? t._({
          id: "QZntO/",
          values: {
            0: e == null ? void 0 : e.id
          }
        }) : t._({
          id: "7q2PJy",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), B(t._({
          id: "K7WMZD"
        })), C("successInfo")) : a === "failed" && (u(t._({
          id: "4qtFW3"
        })), n(t._({
          id: "h3ZVI0"
        })), B(t._({
          id: "Oem4yT"
        })), C("Error")), F(!1);
      } catch (w) {
        u(t._({
          id: "LnNz4Q"
        })), n(t._({
          id: "F05jOJ"
        })), B(t._({
          id: "Oem4yT"
        })), C("Error"), se(w);
      }
    }
    U();
  }, [
    y,
    e,
    a
  ]), L ? /* @__PURE__ */ s.jsx(ne, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Q, {
        title: l,
        description: N,
        iconName: x,
        children: /* @__PURE__ */ s.jsx(m, {
          title: d && b.name,
          tableData: [
            {
              label: t._({
                id: "6Xqu8f"
              }),
              value: c != null && c.switch_out_scheme_name ? /* @__PURE__ */ s.jsx(s.Fragment, {
                children: /* @__PURE__ */ s.jsxs("div", {
                  className: "mb-2 flex",
                  children: [
                    /* @__PURE__ */ s.jsx("img", {
                      src: c == null ? void 0 : c.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ s.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ s.jsx("div", {
                          children: `${je(c.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ s.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: b.investment_option && De(b.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: c.amount ? t._({
                id: "TSMEuV"
              }) : t._({
                id: "hKtDqN"
              }),
              value: c.amount ? `₹ ${_(c.amount)}` : c.units ? c.units : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: c.folio_number ? c.folio_number : "New folio"
            }
          ]
        })
      }),
      O && r && /* @__PURE__ */ s.jsx(K, {
        type: "alert",
        variant: "flash",
        title: O,
        customClass: "mb-5"
      }),
      r && /* @__PURE__ */ s.jsx(I, {
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_switch&action=confirm&id=${e == null ? void 0 : e.id}`, D), "_blank", "noopener=true"),
        customClass: "mt-2",
        children: t._({
          id: "31q1Lm"
        })
      }),
      o && /* @__PURE__ */ s.jsx(I, {
        variant: "secondary",
        fullwidth: !0,
        onClick: o,
        customClass: "mt-2 mb-4",
        children: v
      })
    ]
  });
}
function ze({ scheme: b, mf_switch: e, handleInvestAgain: o, showSchemeName: d, isPartner: r }) {
  var U;
  const { AppState: { tenant: D } } = Y(p), a = e.state.toLowerCase(), [l, u] = i(""), [N, n] = i(""), [x, C] = i(""), [L, F] = i(!1), [v, B] = i(""), { fpapi: y } = Y(ue), [c, E] = i({}), { tenantConfig: M, amcs: A } = de(), H = M == null ? void 0 : M.fallbackLogo, V = M == null ? void 0 : M.showSchemeLogo, [O] = i("Plan will be activated after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return le(() => {
    async function w() {
      F(!0);
      try {
        const P = await y.fpClient.master_data().fetchScheme(e.switch_in_scheme), q = await y.fpClient.master_data().fetchScheme(e.switch_out_scheme), ee = V && A[q == null ? void 0 : q.amc_id] || H;
        E({
          ...e,
          schemeLogo: ee,
          switch_in_scheme_name: P.name,
          switch_out_scheme_name: q.name
        }), a === "active" || a === "created" || a === "pending" || a === "confirmed" || a === "submitted" || a === "successful" ? (u(t._({
          id: "PAOIq9"
        })), n(t._({
          id: "1DWHoM",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), B(t._({
          id: "K7WMZD"
        })), C("successInfo")) : a === "failed" && (u(t._({
          id: "rP1ZG1"
        })), n(t._({
          id: "jLiWEA"
        })), B(t._({
          id: "Oem4yT"
        })), C("Error")), F(!1);
      } catch {
        u(t._({
          id: "LnNz4Q"
        })), n(t._({
          id: "F05jOJ"
        })), B(t._({
          id: "Oem4yT"
        })), C("Error");
      }
    }
    w();
  }, [
    y,
    e,
    a
  ]), L ? /* @__PURE__ */ s.jsx(ne, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Q, {
        title: l,
        description: N,
        iconName: x,
        children: /* @__PURE__ */ s.jsx(m, {
          title: d && b.name,
          tableData: [
            {
              label: t._({
                id: "6Xqu8f"
              }),
              value: c != null && c.switch_out_scheme_name ? /* @__PURE__ */ s.jsx(s.Fragment, {
                children: /* @__PURE__ */ s.jsxs("div", {
                  className: "flex",
                  children: [
                    /* @__PURE__ */ s.jsx("img", {
                      src: c == null ? void 0 : c.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ s.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ s.jsx("div", {
                          children: `${je(c.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ s.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: b.investment_option && De(b.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: t._({
                id: "TSMEuV"
              }),
              value: c.amount ? `₹ ${_(c.amount)}` : "-"
            },
            {
              label: t._({
                id: "12iDzS"
              }),
              value: c.frequency ? (U = ve(c.frequency)) == null ? void 0 : U.replace(/_/g, " ") : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: c.folio_number ? c.folio_number : "New folio"
            },
            c.end_date ? {
              label: t._({
                id: "w3AOkt"
              }),
              value: c.end_date && (c != null && c.end_date) ? new Date(c.end_date).getFullYear() >= 2099 ? "Until you stop" : J(new Date(c.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      O && r && /* @__PURE__ */ s.jsx(K, {
        type: "alert",
        variant: "flash",
        title: O,
        customClass: "mb-5"
      }),
      r && /* @__PURE__ */ s.jsx(I, {
        fullwidth: !0,
        onClick: () => window.open(G(window.location.origin + `/consents?object_type=mf_switch_plan&action=confirm&id=${e == null ? void 0 : e.id}`, D), "_blank", "noopener=true"),
        customClass: "mb-2",
        children: t._({
          id: "31q1Lm"
        })
      }),
      o ? /* @__PURE__ */ s.jsx(I, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: o,
        children: v
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
    ]
  });
}
function _e({ investorProfile: b, scheme: e, mf_order: o, payment: d, showSchemeName: r, isGroupInvestment: D, handleInvestAgain: a, handleRetry: l, isPartner: u, isFotOrder: N }) {
  const n = o == null ? void 0 : o.object;
  function x() {
    switch (n) {
      case "mf_purchase":
        return /* @__PURE__ */ s.jsx(Se, {
          investorProfile: b,
          scheme: e,
          mf_purchase: o,
          payment: d,
          handleInvestAgain: a,
          handleRetry: l,
          showSchemeName: r,
          isGroupInvestment: D,
          isPartner: u
        });
      case "mf_switch":
        return /* @__PURE__ */ s.jsx(Ze, {
          scheme: e,
          mf_switch: o,
          handleInvestAgain: a,
          showSchemeName: r,
          isPartner: u
        });
      case "mf_redemption":
        return /* @__PURE__ */ s.jsx(Ue, {
          scheme: e,
          mf_redemption: o,
          handleInvestAgain: a,
          showSchemeName: r,
          isPartner: u
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ s.jsx(Ae, {
          scheme: e,
          mf_purchase_plan: o,
          payment: d,
          handleInvestAgain: a,
          handleRetry: l,
          showSchemeName: r,
          isGroupInvestment: D,
          isPartner: u,
          investorProfile: b,
          isFotOrder: N
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ s.jsx(ze, {
          scheme: e,
          mf_switch: o,
          handleInvestAgain: a,
          showSchemeName: r,
          isPartner: u
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ s.jsx(Oe, {
          scheme: e,
          mf_redemption_plan: o,
          handleInvestAgain: a,
          handleRetry: l,
          showSchemeName: r,
          isPartner: u
        });
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx(Q, {
            title: t._({
              id: "1nq6/U"
            }),
            description: t._({
              id: "AHVSVV"
            }),
            iconName: "Alert"
          })
        });
    }
  }
  return x();
}
export {
  _e as O
};
