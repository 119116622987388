import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as x, useContext as je, useMemo as nt, useCallback as Tt, useEffect as Se, useLayoutEffect as Yt } from "react";
import { a as qe, e as ue, T as Ve, M as z, R as st, c as Ce, D as Re, B as pe, i as zt, b as Pe, k as At, l as rt, q as lt, S as jt, r as Oe, P as Jt } from "./index.esm-qyC9L-ym.js";
import { u as Et, A as Zt } from "./Auth-Phxt4zVs.js";
import { u as Je } from "./useConfiguration-B-lyaBB9.js";
import { F as Le } from "./provider-CNUuOe_A.js";
import { LoginContext as ft } from "./LoginContext.js";
import { r as me } from "./sentry-Djb1pAvw.js";
import { ae as Qt, i as K, d as bt, A as G, z as mt, $ as _t, m as We, k as Ae, a2 as Pt, S as Ot, f as Wt, o as ot, a4 as Lt, a3 as Ze, a0 as dt, af as Ye, ad as ze, a5 as It, a7 as ut, a6 as Qe, ag as Mt, a8 as ct, a9 as gt, aa as Ut, ab as Ht, a1 as Nt, h as $t, w as Xt, V as Gt, J as ea, B as St } from "./utils-Bzskk-FI.js";
import { i as t } from "./index-C-jpp3An.js";
import { AppContext as wt } from "./AppContext.js";
import { c as ta } from "./checkMigratedUser-D-TLMNz-.js";
import { p as aa } from "./partiallyOnboardMigratedUser-CSHFHhW4.js";
import { ErrorPage as ce } from "./ErrorPage.js";
import { K as ia } from "./KycCheckStatus-D3o67TJr.js";
import { S as na, a as sa } from "./SwitchPlanConsent-DwOqPwLo.js";
import { O as ra } from "./OrderStatus-CRO1IwVe.js";
import { b as la, P as oa, a as da } from "./PurchasePlanConsent-B0mO4SkT.js";
import { R as Xe, F as ge } from "./react-final-form.es-B_J3uV0l.js";
import { u as Ge } from "./useMigratedUser-B10BRJ8r.js";
import { FolioDetails as Bt } from "./FolioDetails.js";
import { F as Rt } from "./FolioListing-CacH-7o2.js";
import { P as ua } from "./PurchaseOrderList-v_Q8wRLx.js";
import { M as ca, m as qt } from "./MandateListing-7eU3x5KZ.js";
import { f as Me, r as Ne, C as fa } from "./validators-BNinzCrT.js";
import { H as Vt } from "./Holdings-Bf_ch-qT.js";
import { B as ma } from "./BankAccountListing-21nhFdVG.js";
import { O as _a } from "./Onboard-BcW8x_A5.js";
const Ee = (a, c, D, f) => {
  var m;
  const d = {
    descriptionText: "",
    descriptionButton: !1,
    footerText: ""
  };
  return (m = a == null ? void 0 : a.folio_defaults) != null && m.demat_account ? c ? d.descriptionText = t._({
    id: "SQcv3y",
    values: {
      selectedFolio: c
    }
  }) : (d.descriptionText = t._({
    id: "+vqJrJ"
  }), d.footerText = t._({
    id: "EAV83v",
    values: {
      0: f ? "investor" : "your"
    }
  })) : (d.descriptionButton = !!D.length, c ? d.descriptionText = t._({
    id: "//dlk6",
    values: {
      selectedFolio: c
    }
  }) : (d.descriptionText = t._({
    id: "zygJu8"
  }), d.footerText = t._({
    id: "EAV83v",
    values: {
      0: f ? "investor" : "your"
    }
  }))), d;
};
function pa({ scheme: a, investment_account_id: c, investorProfile: D, onSuccess: f, isGroupInvestment: d, isPartner: m, investment_accounts: h, user: oe, holdings: O, assistedWorkflowPAN: ae }) {
  const [C, n] = x(null), [ee, T] = x(!1), [ie, be] = x(!1), [I, le] = x(!1), [V, P] = x(null), [J, L] = x(null), [q, g] = x(null), [j, A] = x([]);
  Et();
  const { fpapi: Z } = je(Le), { tenantConfig: k } = Je(), { loggedInPartner: E, nonPartnerDashboardUser: fe } = je(ft), [te, _] = x(null), [y, M] = x(!1), [o, F] = x(null), [W, Y] = x(!1), [ne, Q] = x("initial"), { isMigratedUser: B } = Ge({
    user: oe
  }), [R, we] = x(!1), { AppState: { employer: de } } = je(wt), se = nt(() => {
    var i, u, w, H, $, re;
    const r = (u = (i = k == null ? void 0 : k.fundList) == null ? void 0 : i.find((ye) => ye.isin === a.isin)) == null ? void 0 : u.nfo;
    return ((w = k == null ? void 0 : k.groupInvestment) == null ? void 0 : w.days) && ((H = k == null ? void 0 : k.groupInvestment) == null ? void 0 : H.cutOffDays) && Qt({
      salaryDay: ($ = k == null ? void 0 : k.groupInvestment) == null ? void 0 : $.days[0],
      salaryCutoffDay: (re = k == null ? void 0 : k.groupInvestment) == null ? void 0 : re.cutOffDays[0],
      NFO: r,
      purchaseDate: null
    });
  }, [
    a
  ]), ve = k == null ? void 0 : k.groupInvestment, Te = Tt((r, i) => {
    const u = [];
    return r.data.forEach((w) => {
      w.amc === i && u.push(w);
    }), u;
  }, []);
  Se(() => {
    var r;
    if (!d && o) {
      const i = o.filter((u) => {
        const w = u.scheme === a.isin && !u.plan && (u.state === "pending" || u.state === "failed" && u.failure_code === "payment_failure");
        return V ? w && u.folio_number === V : w;
      });
      M(!!i.length);
    }
    (r = a == null ? void 0 : a.delivery_mode) != null && r.includes(U().toUpperCase()) || Q("scheme_not_allowed");
  }, [
    V
  ]), Se(() => {
    (async () => {
      le(!0);
      try {
        const r = Z.fpClient.mf_folios().fetchAll({
          mf_investment_account: c
        }), i = Z.fpClient.master_data().fetchAmcs();
        let u;
        d || (u = Z.fpClient.mf_purchases().fetchAll({
          mf_investment_account: c,
          states: "pending,failed"
        }));
        const w = await Promise.all([
          r,
          i,
          u
        ]), H = w[1].amcs.find((re) => re.amc_id === a.amc_id), $ = Te(w[0], H.amc_code);
        if (!d) {
          const re = w[2].data.filter((he) => {
            const He = he.scheme === a.isin && !he.plan && (he.state === "pending" || he.state === "failed" && he.failure_code === "payment_failure");
            return $.length ? He && he.folio_number === $[0].number : He;
          }), ye = re.length ? re[0] : null;
          M(!!ye), F(w[2].data);
        }
        A($), g(H.amc_code), _(H == null ? void 0 : H.amc_id), $.length ? (L($[0]), P($[0].number), xe($[0])) : B && we(!0);
      } catch (r) {
        me(r);
      } finally {
        le(!1);
      }
    })();
  }, [
    B,
    O
  ]);
  const U = () => {
    var r;
    return !K(J) && (J != null && J.dp_id) || !bt(h) && ((r = h[0].folio_defaults) != null && r.demat_account) ? "Demat" : "Physical";
  }, xe = (r) => {
    var i, u;
    if (B) {
      const w = (i = O == null ? void 0 : O.folios) == null ? void 0 : i.filter(($) => $.folio_number === (r == null ? void 0 : r.number)), H = w.length ? (u = w[0].schemes) == null ? void 0 : u.find(($) => $.isin === a.isin) : [];
      K(H) ? we(!0) : we(!1);
    }
  };
  async function De({ amount: r }) {
    var u, w, H;
    const i = {
      mf_investment_account: c,
      scheme: a == null ? void 0 : a.isin,
      amount: +r,
      folio_number: V,
      order_gateway: "rta"
    };
    d && (se != null && se.scheduled_on) && (i.scheduled_on = Ae(se == null ? void 0 : se.scheduled_on, "yyyy-mm-dd")), m && E && !fe && (i.partner = E), d && !K((u = k == null ? void 0 : k.groupInvestment) == null ? void 0 : u.employerPartner) && ((w = k == null ? void 0 : k.groupInvestment) != null && w.employerPartner[de == null ? void 0 : de.id]) && (i.partner = (H = k == null ? void 0 : k.groupInvestment) == null ? void 0 : H.employerPartner[de == null ? void 0 : de.id]), Z.fpClient.mf_purchases().create(i).then(($) => {
      f({
        mf_order: $,
        shouldOnboardMigratedUser: R
      });
    }).catch(function($) {
      n(t._({
        id: "HbQ3GE"
      })), me($);
    });
  }
  function Ie() {
    return /* @__PURE__ */ e.jsx(Pe, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "50eZGd"
      })}`,
      isOpen: ie,
      toggleModal: be,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(Bt, {
        investorProfile: D,
        investment_accounts: h
      })
    });
  }
  function v() {
    return /* @__PURE__ */ e.jsx(Pe, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "845eUq"
      })}`,
      isOpen: ee,
      toggleModal: T,
      contentHeightMax: !1,
      children: /* @__PURE__ */ e.jsx(Rt, {
        investorProfile: D,
        amc_code: q,
        amcId: te,
        handleFolioSelection: (r) => {
          xe(r), L(r), P(r.number), T(!ee);
        },
        handleFolioCreation: () => {
          B && we(!0), P(null), L(null), T(!ee);
        },
        scheme_details: void 0,
        isPartner: m,
        assistedWorkflowPAN: ae
      })
    });
  }
  function ke() {
    return /* @__PURE__ */ e.jsx(Pe, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${t._({
        id: "ohPawJ"
      })}`,
      isOpen: W,
      toggleModal: Y,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(ua, {
        isGroupInvestment: d,
        investor: D,
        mfInvestmentAccount: h == null ? void 0 : h[0],
        isPartner: m,
        searchParams: {
          type: "purchase",
          status: "pending,failed",
          folio_number: V,
          isin: a.isin
        }
      })
    });
  }
  const S = () => C ? /* @__PURE__ */ e.jsx(ce, {
    title: C,
    description: `${t._({
      id: "I4jL42"
    })}`,
    showDetails: !1,
    showAction: !0,
    actionText: `${t._({
      id: "Oem4yT"
    })}`,
    actionHandler: () => {
      n(null);
    }
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Xe, {
        onSubmit: De,
        validate: (r) => {
          const i = {};
          return r.amount || (i.amount = t._({
            id: "c0D/2W"
          })), r.amount && r.amount < a.min_initial_investment && (i.amount = t._({
            id: "9LeqUy",
            values: {
              0: G(a.min_initial_investment)
            }
          })), r.amount && r.amount > a.max_initial_investment && (i.amount = t._({
            id: "DJzP4H",
            values: {
              0: G(a.max_initial_investment)
            }
          })), i;
        },
        children: (r) => /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: r.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: `${t._({
                    id: "4NN3aK"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "amount",
                  children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Ve, {
                        id: i.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: i.input.name,
                        value: mt(i.input.value),
                        onChange: (u) => i.input.onChange(_t(u.target.value)),
                        onInput: (u) => {
                          u.target.value = u.target.value.replace(We("0-9"), "");
                        },
                        placeholder: `${t._({
                          id: "cRSGbK"
                        })}`,
                        prefix: "₹",
                        status: i.meta.error && i.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(z, {
                          type: "error",
                          variant: "field",
                          title: i.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "bYw+YE",
                          values: {
                            0: G(Number(a.min_initial_investment))
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            d && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: `${t._({
                    id: "5vGD9a"
                  })}`
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "flex items-start",
                  children: [
                    /* @__PURE__ */ e.jsx(st, {
                      variant: "iconBased",
                      name: "paymentMethodRadio",
                      group: [
                        {
                          icon: "Salary",
                          label: "Salary"
                        }
                      ],
                      disabled: []
                    }),
                    /* @__PURE__ */ e.jsx(Ce, {
                      border: !0,
                      symbol: (ve == null ? void 0 : ve.groupPartnerLogo) || (k == null ? void 0 : k.fallbackLogo),
                      label: ve == null ? void 0 : ve.groupPartnerName,
                      value: `${t._({
                        id: "yFOTgq",
                        values: {
                          0: se != null && se.consideredSalaryDate ? ` in ${new Date(se == null ? void 0 : se.consideredSalaryDate).toLocaleString("default", {
                            month: "long"
                          })} ${new Date(se == null ? void 0 : se.consideredSalaryDate).getFullYear()}` : ""
                        }
                      })}`,
                      customClass: "ml-3 flex-1"
                    })
                  ]
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pt-8",
              children: [
                /* @__PURE__ */ e.jsx(Re, {
                  type: "notes",
                  customClass: "items-baseline",
                  text: Ee(h == null ? void 0 : h[0], V, j, m).descriptionText,
                  footer: Ee(h == null ? void 0 : h[0], V, j, m).footerText ? /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-baseline justify-between",
                    children: [
                      /* @__PURE__ */ e.jsx("span", {
                        className: "text-size-14 text-neutral-60 dark:text-neutral-40 leading-tight",
                        children: Ee(h == null ? void 0 : h[0], V, j, m).footerText
                      }),
                      /* @__PURE__ */ e.jsx(pe, {
                        variant: "text",
                        onClick: (i) => {
                          i.preventDefault(), be(!ie);
                        },
                        customClass: "ml-2",
                        children: `${t._({
                          id: "QVxGwf"
                        })}`
                      })
                    ]
                  }) : null,
                  children: Ee(h == null ? void 0 : h[0], V, j, m).descriptionButton ? /* @__PURE__ */ e.jsx(pe, {
                    variant: "text",
                    type: "button",
                    onClick: (i) => {
                      i.preventDefault(), T(!ee);
                    },
                    children: t._({
                      id: "ShFidp"
                    })
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                }),
                y && !B && /* @__PURE__ */ e.jsx(zt, {
                  variant: "note",
                  message: m ? "The investor have order(s) which are awaiting payment." : "You have order(s) which are awaiting payment.",
                  status: "warning",
                  children: /* @__PURE__ */ e.jsx("div", {
                    className: "text-center",
                    children: /* @__PURE__ */ e.jsx(pe, {
                      type: "button",
                      variant: "text",
                      onClick: (i) => {
                        i.preventDefault(), Y(!W);
                      },
                      children: "View Orders"
                    })
                  })
                }),
                /* @__PURE__ */ e.jsx(pe, {
                  type: "submit",
                  fullwidth: !0,
                  children: !d && !m ? t._({
                    id: "Ffad/b"
                  }) : t._({
                    id: "H/E6fZ"
                  })
                })
              ]
            })
          ]
        })
      }),
      v(),
      Ie(),
      ke()
    ]
  }), Ue = () => {
    switch (ne) {
      case "initial":
        return S();
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "WddszM"
          }),
          description: t._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return S();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: I ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : Ue()
  });
}
const at = [
  {
    label: t._({
      id: "56g9kZ"
    }),
    value: "perpetual"
  },
  {
    label: t._({
      id: "vEYjLl"
    }),
    value: "installments"
  },
  {
    label: t._({
      id: "g9rYAC"
    }),
    value: "fixed"
  }
];
function xa({ scheme: a, investment_account_id: c, onSuccess: D, investorProfile: f, isGroupInvestment: d, isPartner: m, investment_accounts: h, user: oe, holdings: O, assistedWorkflowPAN: ae }) {
  var Dt, kt, Ft;
  const { fpapi: C } = je(Le), { tenantConfig: n, banks: ee } = Je(), T = n == null ? void 0 : n.fallbackLogo, { loggedInPartner: ie, nonPartnerDashboardUser: be } = je(ft), { AppState: { employer: I } } = je(wt), [le, V] = x(""), [P, J] = x(null), [L, q] = x("initial"), [g, j] = x(""), [A, Z] = x(!1), [k, E] = x(!1), [fe, te] = x(!1), [_, y] = x(""), [M, o] = x(null), [F, W] = x(null), [Y, ne] = x(!1), [Q, B] = x([]), [R, we] = x(!1), de = /* @__PURE__ */ new Date(), { isMigratedUser: se } = Ge({
    user: oe
  }), [ve, Te] = x(!1), U = (Dt = n == null ? void 0 : n.app_constants) == null ? void 0 : Dt.plans_mode, xe = ((kt = n == null ? void 0 : n.app_constants) == null ? void 0 : kt.non_systematic_min_installments) || 3;
  let De = Pt(a.sip_frequency_specific_data, U);
  const Ie = ((Ft = n == null ? void 0 : n.groupInvestment) == null ? void 0 : Ft.frequencyOptions) || [], v = nt(() => {
    var s, p, N, l, b, X;
    if (d) {
      const _e = (p = (s = n == null ? void 0 : n.fundList) == null ? void 0 : s.find((Fe) => Fe.isin === a.isin)) == null ? void 0 : p.nfo;
      return ((N = n == null ? void 0 : n.groupInvestment) == null ? void 0 : N.days) && ((l = n == null ? void 0 : n.groupInvestment) == null ? void 0 : l.cutOffDays) && Ot({
        salaryDay: (b = n == null ? void 0 : n.groupInvestment) == null ? void 0 : b.days[0],
        salaryCutoffDay: (X = n == null ? void 0 : n.groupInvestment) == null ? void 0 : X.cutOffDays[0],
        NFO: _e,
        purchaseDate: null
      });
    }
  }, [
    a
  ]);
  if (d) {
    const s = [];
    U === "systematic" ? (Object.keys(a == null ? void 0 : a.sip_frequency_specific_data).forEach((p) => {
      var N;
      Ie.includes(p) && s.push({
        label: (N = Wt(p)) == null ? void 0 : N.replace(/_/g, " "),
        value: p
      });
    }), De = s) : U === "nonSystematic" && (Object.values(De).forEach((p) => {
      Ie.includes(p == null ? void 0 : p.value) && s.push(p);
    }), De = s);
  }
  const ke = n == null ? void 0 : n.groupInvestment;
  Se(() => {
    var s;
    if (!((s = a == null ? void 0 : a.delivery_mode) != null && s.includes(Kt().toUpperCase()))) {
      q("scheme_not_allowed");
      return;
    }
    d || re(), !K(M) && i(M), $();
  }, [
    se
  ]);
  const [S, Ue] = x(De[0].value), r = () => S === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(de.getDate() + 2)) : U === "nonSystematic" ? new Date(de.getFullYear(), de.getMonth() + Ze[S] * xe, de.getDate() + 1) : new Date(de.getFullYear(), de.getMonth() + Ze[S] * (a == null ? void 0 : a.sip_frequency_specific_data[S].min_installments), de.getDate() + 1), i = (s) => {
    var p, N;
    if (se) {
      const l = (p = O == null ? void 0 : O.folios) == null ? void 0 : p.filter((X) => X.folio_number === (s == null ? void 0 : s.number)), b = l.length ? (N = l[0].schemes) == null ? void 0 : N.find((X) => X.isin === a.isin) : [];
      K(b) ? Te(!0) : Te(!1);
    }
  }, u = nt(() => {
    var s;
    return {
      frequency: De[0],
      sip_start_date: new Date((/* @__PURE__ */ new Date()).setDate(de.getDate() + 1)),
      sip_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(de.getDate() + (((s = n == null ? void 0 : n.app_constants) == null ? void 0 : s.plan_max_start_days) || 0))),
      sip_end_date: r(),
      sip_instl_type: at[ot(at, "perpetual")] || null,
      sip_max_date: new Date(2099, 11, 31),
      sip_end_max_date: Lt(S),
      group_sip_start_date: v == null ? void 0 : v.installment_date,
      group_sip_end_date: U === "nonSystematic" ? new Date(v == null ? void 0 : v.installment_date.getFullYear(), (v == null ? void 0 : v.installment_date.getMonth()) + Ze[S] * xe, v == null ? void 0 : v.installment_date.getDate()) : new Date(v == null ? void 0 : v.installment_date.getFullYear(), (v == null ? void 0 : v.installment_date.getMonth()) + Ze[S] * (a == null ? void 0 : a.sip_frequency_specific_data[S].min_installments), v == null ? void 0 : v.installment_date.getDate())
    };
  }, [
    S
  ]), w = (s) => d ? U === "systematic" ? Qe(s, a.sip_frequency_specific_data[S].dates) || Mt(s, v == null ? void 0 : v.installment_date) : Qe(s, ze(S)) || Mt(s, v == null ? void 0 : v.installment_date) : U === "systematic" ? Qe(s, a.sip_frequency_specific_data[S].dates, S) : Qe(s, ze(S), S), H = Tt((s, p) => {
    const N = [];
    return s.forEach((l) => {
      l.amc === p && N.push(l);
    }), N;
  }, []), $ = async () => {
    try {
      const s = await C.fpClient.mf_folios().fetchAll({
        mf_investment_account: c
      }), N = (await C.fpClient.master_data().fetchAmcs()).amcs.find((b) => b.amc_id === a.amc_id), l = H(s.data, N.amc_code);
      B(l), W(N.amc_code), l.length ? (o(l[0]), y(l[0].number)) : se && Te(!0);
    } catch (s) {
      window.debug.log(s), me(s);
    }
  }, re = async () => {
    try {
      q("loading");
      const s = await C.fpClient.bank_accounts().fetchAll({
        profile: f == null ? void 0 : f.id
      }), p = s.data.map((b) => b.old_id), l = (await C.fpClient.mandates().fetchAll({
        bank_account_id: p.join(",")
      })).mandates;
      if (l.sort((b, X) => Number(new Date(X.created_at)) - Number(new Date(b.created_at))), l.length > 0) {
        s.data.forEach((_e) => {
          l.forEach((Fe) => {
            Fe.bank_account_id === _e.old_id && (Fe.bank = {
              bank_name: _e.bank_name,
              number: _e.account_number,
              ifsc: _e.ifsc_code
            });
          });
        });
        const b = [], X = [];
        l.forEach((_e) => {
          _e.mandate_status === "CREATED" || _e.mandate_status === "SUBMITTED" ? b.push(_e) : _e.mandate_status === "APPROVED" && X.push(_e);
        }), X.length ? J(X[0]) : b.length && J(b[0]), q("initial");
      } else
        q("initial");
    } catch (s) {
      window.debug.error(s), q("initial"), me(s);
    }
  }, ye = (s) => {
    s.stopPropagation(), ne(!Y);
  }, he = (s) => {
    s.stopPropagation(), we(!R);
  };
  async function He({ amount: s, frequency: p, installments: N, sip_start_date: l, sip_instl_type: b, sip_end_date: X }) {
    var et, tt, ht;
    if (!P && !d && !R) {
      j(t._({
        id: "dsdjm4"
      }));
      return;
    }
    q("loading"), (b == null ? void 0 : b.value) === "perpetual" ? N = 1200 : (b == null ? void 0 : b.value) === "fixed" && (N = ct(l, X, p.value, U === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[p.value].dates : ze(p.value), U));
    const _e = gt(l, p.value);
    let Fe;
    _e !== null && (Fe = Ae(_e));
    try {
      const Ke = {
        mf_investment_account: c,
        scheme: a.isin,
        frequency: p.value,
        folio_number: _,
        amount: +s,
        installment_day: p.value === "daily" ? null : Ut(l, p.value),
        number_of_installments: +N,
        systematic: U === "systematic",
        payment_method: !d && !R ? "mandate" : null,
        payment_source: !d && !R ? P == null ? void 0 : P.id : null,
        purpose: null,
        generate_first_installment_now: Y,
        auto_generate_installments: !0,
        activate_after: Fe,
        euin: null,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null,
        source_ref_id: null,
        order_gateway: "rta"
      };
      m && ie && !be && (Ke.partner = ie), d && !K((et = n == null ? void 0 : n.groupInvestment) == null ? void 0 : et.employerPartner) && ((tt = n == null ? void 0 : n.groupInvestment) != null && tt.employerPartner[I == null ? void 0 : I.id]) && (Ke.partner = (ht = n == null ? void 0 : n.groupInvestment) == null ? void 0 : ht.employerPartner[I == null ? void 0 : I.id]), d && (v != null && v.activate_after) && (Ke.activate_after = Ae(v == null ? void 0 : v.activate_after, "yyy-mm-dd"));
      const Ct = await C.fpClient.mf_purchase_plans().create(Ke);
      Y && !d ? (q("initial"), D({
        mf_order: Ct,
        isGenerateFirstInstallment: Y,
        shouldOnboardMigratedUser: ve
      })) : (q("initial"), D({
        mf_order: Ct,
        shouldOnboardMigratedUser: ve
      }));
    } catch (Ke) {
      q("initial"), V(t._({
        id: "HbQ3GE"
      })), me(Ke);
    }
  }
  const $e = (s, p) => {
    const N = P;
    let l = !1, b = !1, X = !1;
    if (d)
      return !1;
    if (s && N) {
      const Fe = /* @__PURE__ */ new Date(), et = new Date(s), tt = Math.abs(Fe.getTime() - et.getTime());
      l = Math.ceil(tt / (1e3 * 3600 * 24)) > 21;
    }
    return N && (b = (N == null ? void 0 : N.mandate_status) === "CREATED"), gt(s, p ?? S) === null ? X = !0 : X = !1, (l || b) && X;
  }, pt = ([s, p], N, { changeValue: l }) => {
    p[0] = p[0] === null ? u.sip_start_date : p[0], p[0] = ut(p[0], p[1], U === "systematic" ? a.sip_frequency_specific_data[p[1]].min_installments : xe), l(N, s, () => p[0]);
  }, xt = ([s, p], N, { changeValue: l }) => {
    p[0] !== null && (p[0] = Ht(p[1], U === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[p[1]].dates : ze(p[1]))), l(N, s, () => p[0]);
  }, yt = (s, p) => {
    const N = ct(s || u.sip_start_date, p, S, U === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[S].dates : ze(S), U);
    return U === "systematic" && N <= a.sip_frequency_specific_data[S].min_installments ? !0 : U === "nonSystematic" && N <= xe;
  }, Be = () => {
    var s, p;
    return d ? /* @__PURE__ */ e.jsxs("div", {
      className: "mb-8",
      children: [
        /* @__PURE__ */ e.jsx(ue, {
          message: "Payment method"
        }),
        /* @__PURE__ */ e.jsx(Re, {
          type: "labelDesc",
          text: "Setup automatic payment for installments"
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "mt-4 flex items-start",
          children: [
            /* @__PURE__ */ e.jsx(st, {
              variant: "iconBased",
              name: "paymentMethodRadio",
              group: [
                {
                  icon: "Salary",
                  label: "Salary"
                }
              ],
              disabled: []
            }),
            /* @__PURE__ */ e.jsx(Ce, {
              border: !0,
              symbol: (ke == null ? void 0 : ke.groupPartnerLogo) || (n == null ? void 0 : n.fallbackLogo),
              label: ke == null ? void 0 : ke.groupPartnerName,
              value: `Your employer will pay the installment amount from your salary starting from 
            ${v != null && v.consideredSalaryDate ? ` ${new Date(v == null ? void 0 : v.consideredSalaryDate).toLocaleString("default", {
                month: "long"
              })} ${new Date(v == null ? void 0 : v.consideredSalaryDate).getFullYear()}` : ""}
            `,
              customClass: "ml-3 flex-1"
            })
          ]
        })
      ]
    }) : /* @__PURE__ */ e.jsxs("div", {
      className: "mb-8",
      children: [
        /* @__PURE__ */ e.jsx(ue, {
          message: `${t._({
            id: "5vGD9a"
          })}`
        }),
        /* @__PURE__ */ e.jsx(Re, {
          type: "labelDesc",
          text: `${t._({
            id: "m/rm5V"
          })}`
        }),
        P && P.bank ? /* @__PURE__ */ e.jsx(jt, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 w-full pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: "Bank",
                  value: P ? `${P.bank.bank_name} - ****${P.bank.number.slice(-4)}` : "-",
                  logo: ee[(p = (s = P == null ? void 0 : P.bank) == null ? void 0 : s.ifsc) == null ? void 0 : p.slice(0, 4)] || T
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: "Amount limit",
                  value: P ? `₹${G(P.mandate_limit)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: "Authorization",
                  value: P ? qt[P.mandate_status].value : "-",
                  icon: P ? qt[P.mandate_status].icon : "",
                  iconPlacement: "left"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(pe, {
                  variant: "text",
                  onClick: (N) => {
                    N.preventDefault(), N.stopPropagation(), Z(!0);
                  },
                  children: `${t._({
                    id: "ShFidp"
                  })}`
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx(Re, {
          type: "borderedNotes",
          text: "Setup mandate",
          children: /* @__PURE__ */ e.jsx(pe, {
            variant: "text",
            onClick: (N) => {
              N.preventDefault(), N.stopPropagation(), Z(!0);
            },
            children: `${t._({
              id: "JW7jyx"
            })}`
          })
        })
      ]
    });
  }, Kt = () => {
    var s;
    return !K(M) && (M != null && M.dp_id) || !bt(h) && ((s = h[0].folio_defaults) != null && s.demat_account) ? "Demat" : "Physical";
  }, vt = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      le ? /* @__PURE__ */ e.jsx(ce, {
        title: le,
        description: `${t._({
          id: "mLEhv8"
        })}`,
        showDetails: !1,
        showAction: !0,
        actionText: `${t._({
          id: "RvsoP+"
        })}`,
        actionHandler: () => {
          V(""), Ue(De[0].value);
        }
      }) : /* @__PURE__ */ e.jsx(Xe, {
        onSubmit: He,
        mutators: {
          updateSipEndDate_mutator: pt,
          updateSipStartDate_mutator: xt
        },
        validate: (s) => {
          var N;
          const p = {};
          return s.amount || (p.amount = t._({
            id: "c0D/2W"
          })), s.amount && P && !R && s.amount > P.mandate_limit && (p.amount = t._({
            id: "5VDa6T"
          })), U === "systematic" && s.amount && s.amount < a.sip_frequency_specific_data[S].min_installment_amount && (p.amount = t._({
            id: "9LeqUy",
            values: {
              0: G(a.sip_frequency_specific_data[S].min_installment_amount)
            }
          })), U === "systematic" && s.amount && s.amount > a.sip_frequency_specific_data[S].max_installment_amount && (p.amount = t._({
            id: "DJzP4H",
            values: {
              0: G(a.sip_frequency_specific_data[S].max_installment_amount)
            }
          })), U === "systematic" && dt(s.amount, (N = a == null ? void 0 : a.sip_frequency_specific_data[S]) == null ? void 0 : N.amount_multiples) === !1 && (p.amount = t._({
            id: "O/Jkev",
            values: {
              0: a.sip_frequency_specific_data[S].amount_multiples
            }
          })), U === "systematic" && s.installments && Number(s.installments) < a.sip_frequency_specific_data[S].min_installments && (p.installments = t._({
            id: "O9AVb9",
            values: {
              0: a.sip_frequency_specific_data[S].min_installments
            }
          })), s.installments > 1200 && (p.installments = t._({
            id: "kX+aWa"
          })), s.sip_end_date < s.sip_start_date && (p.sip_end_date = t._({
            id: "/4FybD"
          })), U === "systematic" && d && !(a != null && a.sip_frequency_specific_data[S].dates.includes(v == null ? void 0 : v.installment_date.getDate())) && (p.frequency = t._({
            id: "S+LyGb"
          })), U === "nonSystematic" && s.amount && s.amount < Number(a[Ye(_).minInvestmentAmount]) && (p.amount = t._({
            id: "9LeqUy",
            values: {
              0: G(Number(a[Ye(_).minInvestmentAmount]))
            }
          })), U === "nonSystematic" && s.amount && s.amount > Number(a[Ye(_).maxInvestmentAmount]) && (p.amount = t._({
            id: "DJzP4H",
            values: {
              0: G(Number(a[Ye(_).maxInvestmentAmount]))
            }
          })), U === "nonSystematic" && dt(s.amount, Number(a[Ye(_).investmentAmountMultiples])) === !1 && (p.amount = t._({
            id: "O/Jkev",
            values: {
              0: Number(a[Ye(_).investmentAmountMultiples])
            }
          })), U === "nonSystematic" && s.installments && Number(s.installments) < xe && (p.installments = t._({
            id: "fY6D71",
            values: {
              nonSipMinimumInstallments: xe
            }
          })), U === "nonSystematic" && d && !ze(S).includes(v == null ? void 0 : v.installment_date.getDate()) && (p.frequency = t._({
            id: "S+LyGb"
          })), p;
        },
        initialValues: u,
        subscription: {
          submitting: !0,
          values: !0
        },
        children: (s) => {
          var p, N;
          return /* @__PURE__ */ e.jsxs("form", {
            id: "recurringOrderForm",
            onSubmit: s.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "n30BLK"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "frequency",
                    initialValue: u.frequency,
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: Me(Ne),
                    children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(At, {
                          name: l.input.name,
                          value: l.input.value,
                          onChange: (b) => {
                            const X = [
                              s.values.sip_start_date,
                              b == null ? void 0 : b.value,
                              s.values.sip_end_date
                            ];
                            d || s.form.mutators.updateSipStartDate_mutator("sip_start_date", X), s.form.mutators.updateSipEndDate_mutator("sip_end_date", X), Y && ne($e(s.values.sip_start_date, b == null ? void 0 : b.value)), Ue(b == null ? void 0 : b.value), l.input.onChange(b);
                          },
                          options: De
                        }),
                        l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(z, {
                          type: "error",
                          variant: "field",
                          title: l.meta.error
                        }),
                        l.input.value.value === "daily" && /* @__PURE__ */ e.jsx(z, {
                          type: "helper",
                          variant: "field",
                          title: `${t._({
                            id: "EsyhvE"
                          })}`,
                          customClass: "sm"
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "MznISI"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "amount",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: Me(Ne),
                    children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(Ve, {
                          id: l.input.name,
                          autoComplete: "off",
                          type: "text",
                          name: l.input.name,
                          value: mt(l.input.value),
                          onChange: (b) => l.input.onChange(_t(b.target.value)),
                          onInput: (b) => {
                            b.target.value = b.target.value.replace(We("0-9"), "");
                          },
                          placeholder: `${t._({
                            id: "fK0Zpc"
                          })}`,
                          prefix: "₹",
                          status: l.meta.error && l.meta.touched ? "error" : void 0,
                          autoFocus: !0
                        }),
                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ e.jsx(z, {
                            type: "error",
                            variant: "field",
                            title: l.meta.error
                          })
                        }) : /* @__PURE__ */ e.jsx(z, {
                          type: "helper",
                          variant: "field",
                          title: `${t._({
                            id: "4NTS7E",
                            values: {
                              0: U === "systematic" ? `₹${G(Number(a.sip_frequency_specific_data[S].min_installment_amount))}` : `₹${G(Number(a.min_initial_investment))}`
                            }
                          })}`
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "701So9"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "sip_start_date",
                    initialValue: d ? u.group_sip_start_date : null,
                    validate: Me(Ne),
                    allowNull: !0,
                    children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        !d && /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(rt, {
                              variant: "keyboardInputDOB",
                              onKeyDown: (b) => b.preventDefault(),
                              disableFuture: !1,
                              name: l.input.name,
                              value: l.input.value,
                              format: "dd-MMM-yyyy",
                              minDate: d ? u.group_sip_start_date : u.sip_start_date,
                              maxDate: u.sip_start_max_date,
                              selectedDate: l.input.value || null,
                              error: l.meta.error && l.meta.touched,
                              views: [
                                "date"
                              ],
                              placeholder: "Select start date",
                              labelFunc: (b) => It(b, S),
                              shouldDisableDate: w,
                              handleDateChange: (b) => {
                                Y && ne($e(b)), yt(b, s.values.sip_end_date || u.sip_end_date) && s.form.mutators.updateSipEndDate_mutator("sip_end_date", [
                                  b,
                                  S,
                                  s.values.sip_end_date
                                ]), l.input.onChange(b);
                              },
                              disabled: d
                            }),
                            l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(z, {
                              type: "error",
                              variant: "field",
                              title: l.meta.error
                            })
                          ]
                        }),
                        l.input.value && /* @__PURE__ */ e.jsx(z, {
                          type: "helper",
                          variant: "field",
                          title: `${t._({
                            id: "+7SGSQ",
                            values: {
                              0: Ae(l.input.value, "dd-MMM-yyyy")
                            }
                          })}`,
                          customClass: "sm"
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "a9ECYP"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "sip_instl_type",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: Me(Ne),
                    children: (l) => {
                      var b, X;
                      return /* @__PURE__ */ e.jsx(st, {
                        name: l.input.name,
                        value: l.input.value,
                        group: at,
                        size: "sm",
                        checked: ot(at, (X = (b = l == null ? void 0 : l.input) == null ? void 0 : b.value) == null ? void 0 : X.value),
                        onChange: (_e, Fe) => {
                          l.input.onChange(Fe);
                        }
                      });
                    }
                  })
                ]
              }),
              ((p = s.values.sip_instl_type) == null ? void 0 : p.value) === "installments" && /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "qG3axk"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "installments",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: Me(Ne),
                    children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(Ve, {
                          id: l.input.name,
                          autoComplete: "off",
                          name: l.input.name,
                          onChange: l.input.onChange,
                          placeholder: `${t._({
                            id: "LQjwJ/"
                          })}`,
                          status: l.meta.error && l.meta.touched ? "error" : void 0,
                          onInput: (b) => {
                            b.target.value = b.target.value.replace(We("0-9"), "");
                          },
                          inputMode: "numeric",
                          autoFocus: !0
                        }),
                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ e.jsx(z, {
                            type: "error",
                            variant: "field",
                            title: l.meta.error
                          })
                        }) : /* @__PURE__ */ e.jsx(z, {
                          type: "helper",
                          variant: "field",
                          title: `${t._({
                            id: "J0wmPL",
                            values: {
                              0: U === "systematic" ? `${G(Number(a.sip_frequency_specific_data[S].min_installments))}` : `${G(Number(xe))}`
                            }
                          })}`
                        })
                      ]
                    })
                  })
                ]
              }),
              ((N = s.values.sip_instl_type) == null ? void 0 : N.value) === "fixed" && /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ue, {
                    message: `${t._({
                      id: "iUVPy/"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsx(ge, {
                    name: "sip_end_date",
                    initialValue: d ? u.group_sip_end_date : u.sip_end_date,
                    children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(rt, {
                          variant: "keyboardInputDOB",
                          onKeyDown: (b) => b.preventDefault(),
                          disableFuture: !1,
                          name: l.input.name,
                          value: l.input.value,
                          format: "dd-MMM-yyyy",
                          minDate: ut(s.values.sip_start_date, S, U === "systematic" ? a == null ? void 0 : a.sip_frequency_specific_data[S].min_installments : xe),
                          maxDate: u.sip_end_max_date,
                          selectedDate: l.input.value,
                          error: l.meta.error && l.meta.touched,
                          views: [
                            "date"
                          ],
                          handleDateChange: (b) => {
                            l.input.onChange(b);
                          }
                        }),
                        l.meta.error && l.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ e.jsx(z, {
                            type: "error",
                            variant: "field",
                            title: l.meta.error
                          })
                        }) : /* @__PURE__ */ e.jsx(z, {
                          type: "helper",
                          variant: "field",
                          title: `${t._({
                            id: "J0wmPL",
                            values: {
                              0: U === "systematic" ? `${G(Number(a.sip_frequency_specific_data[S].min_installments))}` : `${G(Number(xe))}`
                            }
                          })}`
                        })
                      ]
                    })
                  })
                ]
              }),
              R ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : Be(),
              !d && /* @__PURE__ */ e.jsx(lt, {
                checked: R,
                onChange: he,
                id: "2",
                customClass: "mb-8",
                children: "Add mandate later"
              }),
              $e(s.form.getState().values.sip_start_date) && /* @__PURE__ */ e.jsx(lt, {
                variant: "cardType",
                description: t._({
                  id: "qEgHb4",
                  values: {
                    0: m ? "Investor" : "You"
                  }
                }),
                id: "1",
                checked: Y,
                onChange: ye,
                children: /* @__PURE__ */ e.jsx("span", {
                  className: "dark:text-primary-DN110",
                  children: "Start first installment today"
                })
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "pt-4",
                children: [
                  g && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-2 mt-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      type: "error",
                      variant: "field",
                      title: g
                    })
                  }),
                  /* @__PURE__ */ e.jsx(Re, {
                    type: "notes",
                    customClass: "items-baseline mb-2",
                    text: Ee(h == null ? void 0 : h[0], _, Q, m).descriptionText,
                    footer: Ee(h == null ? void 0 : h[0], _, Q, m).footerText ? /* @__PURE__ */ e.jsxs("div", {
                      className: "flex items-baseline justify-between",
                      children: [
                        /* @__PURE__ */ e.jsx("span", {
                          className: "text-size-14 text-neutral-60 dark:text-neutral-40 leading-tight",
                          children: Ee(h == null ? void 0 : h[0], _, Q, m).footerText
                        }),
                        /* @__PURE__ */ e.jsx(pe, {
                          variant: "text",
                          onClick: (l) => {
                            l.preventDefault(), te(!fe);
                          },
                          customClass: "ml-2",
                          children: "View"
                        })
                      ]
                    }) : null,
                    children: Ee(h == null ? void 0 : h[0], _, Q, m).descriptionButton ? /* @__PURE__ */ e.jsx(pe, {
                      type: "button",
                      variant: "text",
                      onClick: (l) => {
                        l.preventDefault(), E(!k);
                      },
                      children: t._({
                        id: "ShFidp"
                      })
                    }) : null
                  }),
                  /* @__PURE__ */ e.jsx(pe, {
                    variant: R || d || P ? "primary" : "disabled",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                ]
              })
            ]
          });
        }
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        variant: "fullPage",
        title: "Select mandate",
        isOpen: A,
        toggleModal: () => {
          Z(!1);
        },
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(ca, {
          investorProfile: f,
          isPartner: m,
          handleMandateSelection: (s) => {
            J(s), Z(!1);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: k,
        toggleModal: () => {
          E(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx(Rt, {
          investorProfile: f,
          amc_code: F,
          handleFolioSelection: (s) => {
            i(s), o(s), y(s.number), E(!k);
          },
          handleFolioCreation: () => {
            se && Te(!0), y(""), o(null), E(!k);
          },
          assistedWorkflowPAN: ae,
          isPartner: m
        })
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: fe,
        toggleModal: te,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(Bt, {
          investorProfile: f,
          investment_accounts: h
        })
      })
    ]
  });
  return (() => {
    switch (L) {
      case "initial":
        return vt();
      case "loading":
        return /* @__PURE__ */ e.jsx(qe, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "WddszM"
          }),
          description: t._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return vt();
    }
  })();
}
function ya({ holdings: a, investment_account_id: c, scheme: D, selectedSwitchOutScheme: f, onSuccess: d, user: m }) {
  const [h, oe] = x(!1), [O, ae] = x("initial"), [C, n] = x(!1), { fpapi: ee } = je(Le);
  Je();
  const { isMigratedUser: T, migratedMfiaList: ie, migratedFolioList: be } = Ge({
    user: m
  }), [I, le] = x(!1), V = (q) => {
    var g, j;
    if (T) {
      const A = (g = a == null ? void 0 : a.folios) == null ? void 0 : g.filter((k) => k.folio_number === q), Z = A != null && A.length ? (j = A[0].schemes) == null ? void 0 : j.find((k) => k.isin === D.isin) : [];
      K(Z) ? le(!0) : le(!1);
    }
  };
  Se(() => {
    const q = f ? a[f == null ? void 0 : f.key].folio_number : "";
    V(q);
  }, [
    f,
    T
  ]);
  async function P({ amount: q }) {
    oe(!0);
    try {
      const g = await ee.fpClient.mf_switches().create({
        mf_investment_account: c,
        switch_out_scheme: f.isin,
        switch_in_scheme: D.isin,
        amount: C ? null : q,
        folio_number: a[f.key].folio_number,
        order_gateway: "rta"
      });
      oe(!1), d({
        mf_order: g,
        shouldOnboardMigratedUser: I
      });
    } catch (g) {
      oe(!1), ae("error"), me(g);
    }
  }
  function J() {
    return /* @__PURE__ */ e.jsx(Xe, {
      onSubmit: P,
      validate: (q) => {
        const g = {};
        return C || (q.amount || (g.amount = t._({
          id: "7rkFUv"
        })), q.amount && f && q.amount < f.min_switch_out_amount && (g.amount = t._({
          id: "PuxVdZ",
          values: {
            0: G(f.min_switch_out_amount, 2)
          }
        })), q.amount && D.min_switch_in_amount && q.amount < D.min_switch_in_amount && (g.amount = t._({
          id: "ucZP5J",
          values: {
            0: G(D.min_switch_in_amount, 2)
          }
        })), q.amount && a && f && q.amount > a[f.key].market_value.redeemable_amount && (g.amount = t._({
          id: "yxdAlQ",
          values: {
            0: G(a[f.key].market_value.redeemable_amount)
          }
        })), dt(q.amount, f == null ? void 0 : f.switch_out_amount_multiples) === !1 && (g.amount = t._({
          id: "EjZXF8",
          values: {
            0: G(f.switch_out_amount_multiples)
          }
        }))), g;
      },
      children: (q) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: q.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ue, {
                message: t._({
                  id: "KqeLJ1"
                })
              }),
              /* @__PURE__ */ e.jsx(ge, {
                name: "amount",
                children: (g) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Ve, {
                      id: g.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: g.input.name,
                      value: C ? "" : mt(g.input.value),
                      onChange: (j) => g.input.onChange(_t(j.target.value)),
                      onInput: (j) => {
                        j.target.value = j.target.value.replace(We("0-9"), "");
                      },
                      placeholder: t._({
                        id: "qUFsyJ"
                      }),
                      prefix: "₹",
                      disabled: C,
                      status: g.meta.error && g.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    g.meta.error && g.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(z, {
                        type: "error",
                        variant: "field",
                        title: g.meta.error
                      })
                    }) : f && /* @__PURE__ */ e.jsx(z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "BgHHhn",
                        values: {
                          0: G(Number(a && a[f.key].market_value.redeemable_amount), 2)
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(lt, {
            id: "switch-all-id",
            checked: C,
            onChange: (g) => {
              g.stopPropagation(), n(!C);
            },
            children: t._({
              id: "dNBP2o"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(pe, {
              type: "submit",
              variant: (!f || a && a[f.key].market_value.redeemable_amount <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "3+3T+B"
              })
            })
          })
        ]
      })
    });
  }
  function L() {
    switch (O) {
      case "initial":
        return J();
      case "error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "SOfRak"
          }),
          description: t._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wy5cp6"
          }),
          actionHandler: (q) => ae("initial")
        });
      default:
        return J();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: h ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: L()
    })
  });
}
function ha({ holdings: a, investment_account_id: c, scheme: D, selectedSwitchOutScheme: f, onSuccess: d, user: m }) {
  const [h, oe] = x(!1), [O, ae] = x("initial"), [C, n] = x(!1), { fpapi: ee } = je(Le);
  Je();
  const { isMigratedUser: T, migratedMfiaList: ie, migratedFolioList: be } = Ge({
    user: m
  }), [I, le] = x(!1), V = (g) => {
    var j, A;
    if (T) {
      const Z = (j = a == null ? void 0 : a.folios) == null ? void 0 : j.filter((E) => E.folio_number === g), k = Z != null && Z.length ? (A = Z[0].schemes) == null ? void 0 : A.find((E) => E.isin === D.isin) : [];
      K(k) ? le(!0) : le(!1);
    }
  };
  Se(() => {
    const g = f ? a[f == null ? void 0 : f.key].folio_number : "";
    V(g);
  }, [
    f,
    T
  ]);
  async function P({ unit: g }) {
    oe(!0);
    try {
      const j = await ee.fpClient.mf_switches().create({
        mf_investment_account: c,
        switch_out_scheme: f.isin,
        switch_in_scheme: D.isin,
        units: C ? null : g,
        folio_number: a[f.key].folio_number,
        order_gateway: "rta"
      });
      oe(!1), d({
        mf_order: j,
        shouldOnboardMigratedUser: I
      });
    } catch (j) {
      oe(!1), ae("error"), me(j);
    }
  }
  const J = (g, j) => {
    const A = g.target.value;
    Nt(A, D.switch_out_unit_multiples) !== 0 ? g.preventDefault() : j.input.onChange(A);
  };
  function L() {
    return /* @__PURE__ */ e.jsx(Xe, {
      onSubmit: P,
      validate: (g) => {
        const j = {};
        if (C)
          return j;
        if (g.unit || (j.unit = t._({
          id: "7rkFUv"
        })), g.unit && f && (g.unit < f.min_switch_out_units || g.unit * a[f.key].nav.value < f.min_switch_in_amount)) {
          const A = Math.max((f.min_switch_in_amount / a[f.key].nav.value).toFixed(3), f.min_switch_out_units);
          j.unit = t._({
            id: "K4I1VP",
            values: {
              max_units: A
            }
          });
        }
        return g.unit && a && f && g.unit > a[f.key].holdings.redeemable_units && (j.unit = t._({
          id: "4yUAdy",
          values: {
            0: a[f.key].holdings.redeemable_units
          }
        })), g.amount && Nt(g.amount, D.switch_out_unit_multiples) !== 0 && (j.amount = t._({
          id: "HKO+wY",
          values: {
            0: f.switch_out_unit_multiples
          }
        })), j;
      },
      children: (g) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: g.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ue, {
                message: t._({
                  id: "6ssn5V"
                })
              }),
              /* @__PURE__ */ e.jsx(ge, {
                name: "unit",
                children: (j) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(Ve, {
                      id: j.input.name,
                      autoComplete: "off",
                      type: "number",
                      step: "any",
                      name: j.input.name,
                      value: C ? "" : j.input.value,
                      onChange: (A) => J(A, j),
                      placeholder: "Enter units",
                      disabled: C,
                      status: j.meta.error && j.meta.touched ? "error" : void 0,
                      autoFocus: !0
                    }),
                    j.meta.error && j.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(z, {
                        type: "error",
                        variant: "field",
                        title: j.meta.error
                      })
                    }) : f && /* @__PURE__ */ e.jsx(z, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "vypU3+",
                        values: {
                          0: a && a[f.key].holdings.redeemable_units
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(lt, {
            id: "switch-all-id",
            checked: C,
            onChange: (j) => {
              j.stopPropagation(), n(!C);
            },
            children: t._({
              id: "dNBP2o"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(pe, {
              type: "submit",
              variant: (!f || a && a[f.key].holdings.redeemable_units <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "3+3T+B"
              })
            })
          })
        ]
      })
    });
  }
  function q() {
    switch (O) {
      case "initial":
        return L();
      case "error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "QLKKZs"
          }),
          description: t._({
            id: "fYltlZ"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Retry",
          actionHandler: (g) => ae("initial")
        });
      default:
        return L();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: h ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: q()
    })
  });
}
function ga({ scheme: a, switch_out: c, investment_account_id: D, investment_accounts: f, onSuccess: d, investorProfile: m, user: h, assistedWorkflowPAN: oe, isPartner: O }) {
  const [ae, C] = x(!1), [n, ee] = x([]), [T, ie] = x(!1), [be, I] = x("initial"), [le, V] = x(""), [P, J] = x(""), [L, q] = x(null), [g, j] = x([]), { fpapi: A } = je(Le);
  Se(() => {
    async function y() {
      C(!0);
      try {
        const o = (await A.fpClient.master_data().fetchAmcs()).amcs.find((R) => R.amc_id === a.amc_id), F = await A.fpClient.mf_folios().fetchAll({
          mf_investment_account: D
        }), W = [];
        F.data.forEach((R) => {
          R.amc === o.amc_code && W.push(R.number);
        });
        const Y = await A.fpClient.investor_reports().fetchHoldings({
          investment_account_id: f[0].old_id
        }), ne = {}, Q = [];
        if (Y.folios.forEach((R) => {
          W.includes(R.folio_number) && R.schemes.forEach((we) => {
            we.isin !== a.isin && (Q.push(we.isin), ne[R.folio_number + "-" + we.isin] = {
              ...we,
              folio_number: R.folio_number
            });
          });
        }), K(ne)) {
          C(!1), I("no_holdings");
          return;
        }
        let B = !1;
        if (Object.values(ne).forEach((R) => {
          R.isin === c && (B = !0);
        }), B) {
          const R = await A.fpClient.master_data().fetchScheme(Object.values(ne)[0].isin);
          q({
            ...R,
            key: Object.keys(ne)[0]
          });
        }
        j(Q), ee(ne), C(!1);
      } catch (M) {
        C(!1), V(t._({
          id: "Gojdwx"
        })), J(t._({
          id: "rAP48C"
        })), I("error"), me(M);
      }
    }
    y();
  }, [
    A,
    D,
    f,
    a,
    c
  ]);
  const Z = () => /* @__PURE__ */ e.jsx(ya, {
    scheme: a,
    investment_account_id: D,
    holdings: n,
    selectedSwitchOutScheme: L,
    onSuccess: d,
    user: h
  }), k = () => /* @__PURE__ */ e.jsx(ha, {
    scheme: a,
    investment_account_id: D,
    holdings: n,
    selectedSwitchOutScheme: L,
    onSuccess: d,
    user: h
  });
  async function E(y, M) {
    C(!0);
    try {
      const o = await A.fpClient.master_data().fetchScheme(y);
      q({
        ...o,
        key: M.number + "-" + y
      }), C(!1);
    } catch (o) {
      C(!1), V(t._({
        id: "Gojdwx"
      })), J(t._({
        id: "rAP48C"
      })), I("error"), me(o);
    }
  }
  function fe() {
    const M = window.location.href.replace("withdraw", "checkout");
    window.location.href = M;
  }
  function te() {
    return n && /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ue, {
          message: t._({
            id: "6Xqu8f"
          })
        }),
        L ? /* @__PURE__ */ e.jsx(jt, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "Rtowvt"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: n && L ? n[L.key].folio_number : t._({
                        id: "OM/tRr"
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(pe, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (y) => {
                    y.preventDefault(), ie(!T);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "XTJZw8"
                  }),
                  value: n && L ? $t(n[L.key].name) : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "S0BC0N"
                  }),
                  value: n && L ? `₹ ${G(n[L.key].market_value.amount)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "jlk/lh"
                  }),
                  value: n && L ? n[L.key].holdings.units : "-"
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx(Re, {
          type: "borderedNotes",
          text: t._({
            id: "dQnZG2"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(pe, {
            variant: "text",
            onClick: (y) => {
              y.preventDefault(), ie(!T);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        /* @__PURE__ */ e.jsx(Oe, {
          group: [
            {
              title: t._({
                id: "Op9PiZ"
              }),
              content: Z()
            },
            {
              title: t._({
                id: "q7FP8z"
              }),
              content: k()
            }
          ],
          selected: 0
        }),
        m && /* @__PURE__ */ e.jsx(Pe, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "2k3iZo"
          }),
          isOpen: T,
          toggleModal: ie,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Vt, {
            investorProfile: m,
            schemes: g,
            onSchemeAndFolioSelection: (y, M) => {
              ie(!T), E(y, M);
            },
            assistedWorkflowPAN: oe,
            isPartner: O
          })
        })
      ]
    });
  }
  function _() {
    switch (be) {
      case "initial":
        return te();
      case "error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: le,
          description: P
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "QbrtNj"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Invest now",
          actionHandler: fe
        });
      default:
        return te();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: ae ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: _()
    })
  });
}
const it = [
  {
    label: t._({
      id: "4JqC6N"
    }),
    value: "perpetual"
  },
  {
    label: t._({
      id: "/VDmvv"
    }),
    value: "installments"
  },
  {
    label: t._({
      id: "IUSFui"
    }),
    value: "fixed"
  }
];
function ja({ selectedSwitchOutScheme: a, scheme: c, investment_account_id: D, holdings: f, investorProfile: d, onSuccess: m, isPartner: h, user: oe }) {
  const { tenantConfig: O } = Je(), ae = /* @__PURE__ */ new Date(), C = Pt(c == null ? void 0 : c.stp_frequency_specific_data), [n, ee] = x(C[0].value), [T, ie] = x("initial"), [be, I] = x(!1), { fpapi: le } = je(Le), { loggedInPartner: V, nonPartnerDashboardUser: P } = je(ft), { isMigratedUser: J } = Ge({
    user: oe
  }), [L, q] = x(!1), g = (_) => {
    var y, M;
    if (J) {
      const o = (y = f == null ? void 0 : f.folios) == null ? void 0 : y.filter((W) => W.folio_number === _), F = o != null && o.length ? (M = o[0].schemes) == null ? void 0 : M.find((W) => W.isin === c.isin) : [];
      K(F) ? q(!0) : q(!1);
    }
  };
  Se(() => {
    const _ = a ? f[a == null ? void 0 : a.key].folio_number : "";
    g(_);
  }, [
    a,
    J
  ]);
  const j = nt(() => {
    var _;
    return {
      frequency: C[0],
      stp_instl_type: it[ot(it, "perpetual")] || null,
      stp_start_date: new Date((/* @__PURE__ */ new Date()).setDate(ae.getDate() + 1)),
      stp_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(ae.getDate() + (((_ = O == null ? void 0 : O.app_constants) == null ? void 0 : _.plan_max_start_days) || 0))),
      stp_end_date: n === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(ae.getDate() + 2)) : new Date(ae.getFullYear(), ae.getMonth() + Ze[n] * (c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments), ae.getDate() + 1),
      stp_end_max_date: Lt(n)
    };
  }, [
    n
  ]);
  async function A({ amount: _, frequency: y, stp_start_date: M, stp_instl_type: o, installments: F, stp_end_date: W }) {
    (o == null ? void 0 : o.value) === "perpetual" ? F = 1200 : (o == null ? void 0 : o.value) === "fixed" && (F = ct(M, W, y.value, c == null ? void 0 : c.stp_frequency_specific_data[y.value].dates));
    let Y = gt(M, y.value);
    Y !== null && (Y = Ae(Y)), await le.fetchEmailAndPhone(d);
    const ne = {
      mf_investment_account: D,
      folio_number: f[a == null ? void 0 : a.key].folio_number,
      amount: +_,
      switch_in_scheme: c.isin,
      activate_after: Y,
      switch_out_scheme: a == null ? void 0 : a.isin,
      frequency: y.value,
      systematic: !0,
      installment_day: y.value === "daily" ? null : Ut(M, y.value),
      number_of_installments: +F,
      order_gateway: "rta"
    };
    if (h && V) {
      I(!0);
      let Q = {
        ...ne
      };
      Q = {
        ...Q
      }, P || (Q.partner = V);
      try {
        const B = await le.fpClient.mf_switch_plans().create(Q);
        I(!1), m({
          mf_order: B,
          shouldOnboardMigratedUser: L
        });
      } catch (B) {
        window.debug.error(B), I(!1), ie("error"), me(B);
      }
    } else {
      I(!0);
      try {
        const Q = await le.fpClient.mf_switch_plans().create(ne);
        m({
          mf_order: Q,
          shouldOnboardMigratedUser: L
        }), I(!1), m({
          mf_order: Q,
          shouldOnboardMigratedUser: L
        });
      } catch (Q) {
        window.debug.error(Q), I(!1), ie("error"), I(!1), me(Q);
      }
    }
  }
  const Z = ([_, y], M, { changeValue: o }) => {
    y[0] = y[0] === null ? j.stp_start_date : y[0], y[0] = ut(y[0], y[1], c.stp_frequency_specific_data[y[1]].min_installments), o(M, _, () => y[0]);
  }, k = ([_, y], M, { changeValue: o }) => {
    y[0] !== null && (y[0] = Ht(y[1], c == null ? void 0 : c.stp_frequency_specific_data[y[1]].dates)), o(M, _, () => y[0]);
  }, E = (_, y) => ct(_ || j.stp_start_date, y, n, c == null ? void 0 : c.stp_frequency_specific_data[n].dates) <= c.stp_frequency_specific_data[n].min_installments;
  function fe() {
    return /* @__PURE__ */ e.jsx(Xe, {
      onSubmit: A,
      mutators: {
        updateStpEndDate_mutator: Z,
        updateStpStartDate_mutator: k
      },
      validate: (_) => {
        var M;
        const y = {};
        return dt(_.amount, (M = c == null ? void 0 : c.stp_frequency_specific_data[n]) == null ? void 0 : M.amount_multiples) === !1 && (y.amount = t._({
          id: "EjZXF8",
          values: {
            0: c == null ? void 0 : c.stp_frequency_specific_data[n].amount_multiples
          }
        })), _.amount && c && _.amount < c.stp_frequency_specific_data[n].min_installment_amount && (y.amount = t._({
          id: "IIlbKG",
          values: {
            0: G(Number(c.stp_frequency_specific_data[n].min_installment_amount))
          }
        })), _.amount && _.amount > c.stp_frequency_specific_data[n].max_installment_amount && (y.amount = t._({
          id: "DoPb3h",
          values: {
            0: G(Number(c.stp_frequency_specific_data[n].max_installment_amount))
          }
        })), _.installments < (c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments) && (y.installments = t._({
          id: "MpguFb",
          values: {
            0: c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments
          }
        })), _.installments > 1200 && (y.installments = t._({
          id: "j7AzBN"
        })), _.stp_end_date < _.stp_start_date && (y.stp_end_date = t._({
          id: "T31Ev8"
        })), new Date(_.stp_end_date).setHours(0, 0, 0, 0) < new Date(j.stp_end_date).setHours(0, 0, 0, 0) && (y.stp_end_date = t._({
          id: "limWat",
          values: {
            0: Ae(new Date(j.stp_end_date), "dd-MMM-yyyy")
          }
        })), y;
      },
      subscription: {
        submitting: !0,
        values: !0
      },
      initialValues: j,
      children: (_) => {
        var y, M;
        return /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: _.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "zUtHgw"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "frequency",
                  initialValue: j.frequency,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Me(Ne),
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(At, {
                        name: o.input.name,
                        value: o.input.value,
                        onChange: (F) => {
                          const W = [
                            _.values.stp_start_date,
                            F.value,
                            _.values.stp_end_date
                          ];
                          _.form.mutators.updateStpStartDate_mutator("stp_start_date", W), _.form.mutators.updateStpEndDate_mutator("stp_end_date", W), ee(F.value), o.input.onChange(F);
                        },
                        options: C
                      }),
                      o.meta.error && o.meta.touched && /* @__PURE__ */ e.jsx(z, {
                        type: "error",
                        variant: "field",
                        title: o.meta.error
                      }),
                      o.input.value.value === "daily" && /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "oAMyYj"
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "/DJBcB"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "amount",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Me(Ne),
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Ve, {
                        id: o.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: o.input.name,
                        value: mt(o.input.value),
                        onChange: (F) => o.input.onChange(_t(F.target.value)),
                        onInput: (F) => {
                          F.target.value = F.target.value.replace(We("0-9"), "");
                        },
                        placeholder: t._({
                          id: "qUFsyJ"
                        }),
                        prefix: "₹",
                        status: o.meta.error && o.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      o.meta.error && o.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(z, {
                          type: "error",
                          variant: "field",
                          title: o.meta.error
                        })
                      }) : a && /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "JfMq/g",
                          values: {
                            0: G(Number(c.stp_frequency_specific_data[n].min_installment_amount), 2)
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "jvN2aB"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "stp_start_date",
                  initialValue: null,
                  validate: Me(Ne),
                  allowNull: !0,
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(rt, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (F) => F.preventDefault(),
                        disableFuture: !1,
                        name: o.input.name,
                        value: o.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: j.stp_start_date,
                        maxDate: j.stp_start_max_date,
                        selectedDate: o.input.value || null,
                        error: o.meta.error && o.meta.touched,
                        views: [
                          "date"
                        ],
                        placeholder: "Select start date",
                        labelFunc: (F) => It(F, n),
                        shouldDisableDate: (F) => Qe(F, c == null ? void 0 : c.stp_frequency_specific_data[n].dates, n),
                        handleDateChange: (F) => {
                          E(F, _.values.stp_end_date || j.stp_end_date) && _.form.mutators.updateStpEndDate_mutator("stp_end_date", [
                            F,
                            n,
                            _.values.stp_end_date
                          ]), o.input.onChange(F);
                        }
                      }),
                      o.meta.error && o.meta.touched && /* @__PURE__ */ e.jsx(z, {
                        type: "error",
                        variant: "field",
                        title: o.meta.error
                      }),
                      o.input.value && /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "jI3CM4",
                          values: {
                            0: Ae(o.input.value, "dd-MMM-yyyy")
                          }
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "G7dHtg"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "stp_instl_type",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Me(Ne),
                  children: (o) => {
                    var F, W;
                    return /* @__PURE__ */ e.jsx(e.Fragment, {
                      children: /* @__PURE__ */ e.jsx(st, {
                        name: o.input.name,
                        value: o.input.value,
                        group: it,
                        size: "sm",
                        checked: ot(it, (W = (F = o == null ? void 0 : o.input) == null ? void 0 : F.value) == null ? void 0 : W.value),
                        onChange: (Y, ne) => {
                          o.input.onChange(ne);
                        }
                      })
                    });
                  }
                })
              ]
            }),
            ((y = _.values.stp_instl_type) == null ? void 0 : y.value) === "installments" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "ZS3Gyi"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "installments",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Me(Ne),
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Ve, {
                        id: o.input.name,
                        autoComplete: "off",
                        name: o.input.name,
                        onChange: o.input.onChange,
                        placeholder: "Enter number of installments",
                        status: o.meta.error && o.meta.touched ? "error" : void 0,
                        onKeyPress: (F) => {
                          fa(F.key) || F.preventDefault();
                        },
                        inputMode: "numeric",
                        autoFocus: !0
                      }),
                      o.meta.error && o.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(z, {
                          type: "error",
                          variant: "field",
                          title: o.meta.error
                        })
                      }) : a && /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "4xlwZY",
                          values: {
                            0: c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            ((M = _.values.stp_instl_type) == null ? void 0 : M.value) === "fixed" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ue, {
                  message: t._({
                    id: "BrsP9r"
                  })
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "stp_end_date",
                  initialValue: null,
                  allowNull: !0,
                  children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(rt, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (F) => F.preventDefault(),
                        disableFuture: !1,
                        name: o.input.name,
                        value: o.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: ut(_.values.stp_start_date, n, c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments),
                        maxDate: j.stp_end_max_date,
                        selectedDate: o.input.value,
                        labelFunc: (F) => F ? Ae(F, "dd-MMM-yyyy") : "Select end date",
                        error: o.meta.error && o.meta.touched,
                        views: [
                          "date"
                        ],
                        handleDateChange: (F) => {
                          o.input.onChange(F);
                        }
                      }),
                      o.meta.error && o.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(z, {
                          type: "error",
                          variant: "field",
                          title: o.meta.error
                        })
                      }) : a && /* @__PURE__ */ e.jsx(z, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "VCqIPq",
                          values: {
                            0: c == null ? void 0 : c.stp_frequency_specific_data[n].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ e.jsx(pe, {
                type: "submit",
                variant: a ? "primary" : "disabled",
                fullwidth: !0,
                children: t._({
                  id: "3+3T+B"
                })
              })
            })
          ]
        });
      }
    });
  }
  function te() {
    switch (T) {
      case "initial":
        return fe();
      case "error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "SOfRak"
          }),
          description: t._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wy5cp6"
          }),
          actionHandler: (_) => {
            ee(C[0].value), ie("initial");
          }
        });
      default:
        return fe();
    }
  }
  return be ? /* @__PURE__ */ e.jsx(qe, {
    variant: "fullpage"
  }) : te();
}
function ba({ scheme: a, switch_out: c, investment_account_id: D, investment_accounts: f, onSuccess: d, investorProfile: m, isPartner: h, user: oe, assistedWorkflowPAN: O }) {
  const [ae, C] = x(!1), [n, ee] = x(!1), [T, ie] = x([]), [be, I] = x("initial"), [le, V] = x(""), [P, J] = x(""), [L, q] = x(null), [g, j] = x([]), { fpapi: A } = je(Le);
  Se(() => {
    async function _() {
      C(!0);
      try {
        const M = (await A.fpClient.master_data().fetchAmcs()).amcs.find((B) => B.amc_id === a.amc_id), o = await A.fpClient.mf_folios().fetchAll({
          mf_investment_account: D
        }), F = [];
        o.data.forEach((B) => {
          B.amc === M.amc_code && F.push(B.number);
        });
        const W = await A.fpClient.investor_reports().fetchHoldings({
          investment_account_id: f[0].old_id
        }), Y = {}, ne = [];
        if (W.folios.forEach((B) => {
          F.includes(B.folio_number) && B.schemes.forEach((R) => {
            R.isin !== a.isin && (ne.push(R.isin), Y[B.folio_number + "-" + R.isin] = {
              ...R,
              folio_number: B.folio_number
            });
          });
        }), K(Y)) {
          C(!1), I("no_holdings");
          return;
        }
        let Q = !1;
        if (Object.values(Y).forEach((B) => {
          B.isin === c && (Q = !0);
        }), Q) {
          const B = await A.fpClient.master_data().fetchScheme(Object.values(Y)[0].isin);
          q({
            ...B,
            key: Object.keys(Y)[0]
          });
        }
        j(ne), ie(Y), C(!1);
      } catch (y) {
        C(!1), V(t._({
          id: "Gojdwx"
        })), J(t._({
          id: "rAP48C"
        })), I("error"), me(y);
      }
    }
    _();
  }, [
    A,
    D,
    f,
    a,
    c
  ]);
  function Z() {
    const y = window.location.href.replace("withdraw", "checkout");
    window.location.href = y;
  }
  const k = () => /* @__PURE__ */ e.jsx(ja, {
    scheme: a,
    investment_account_id: D,
    holdings: T,
    selectedSwitchOutScheme: L,
    onSuccess: d,
    investorProfile: m,
    isPartner: h,
    user: oe
  });
  function E() {
    return T && /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ue, {
          message: t._({
            id: "6Xqu8f"
          })
        }),
        L ? /* @__PURE__ */ e.jsx(jt, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "OW2zXN"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: T && L ? T[L.key].folio_number : "Please choose a scheme and folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(pe, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (_) => {
                    _.preventDefault(), ee(!n);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "XTJZw8"
                  }),
                  value: T && L ? $t(T[L.key].name) : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "S0BC0N"
                  }),
                  value: T && L ? `₹ ${G(T[L.key].market_value.amount)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pl-2 pr-2",
                children: /* @__PURE__ */ e.jsx(Ce, {
                  label: t._({
                    id: "jlk/lh"
                  }),
                  value: T && L ? T[L.key].holdings.units : "-"
                })
              })
            ]
          })
        }) : /* @__PURE__ */ e.jsx(Re, {
          type: "borderedNotes",
          text: t._({
            id: "dQnZG2"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(pe, {
            variant: "text",
            onClick: (_) => {
              _.preventDefault(), ee(!n);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        /* @__PURE__ */ e.jsx(Oe, {
          group: [
            {
              title: t._({
                id: "ijXM8H"
              }),
              content: k()
            }
          ],
          selected: 0
        }),
        m && /* @__PURE__ */ e.jsx(Pe, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "2k3iZo"
          }),
          isOpen: n,
          toggleModal: ee,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(Vt, {
            investorProfile: m,
            schemes: g,
            onSchemeAndFolioSelection: (_, y) => {
              ee(!n), fe(_, y);
            },
            assistedWorkflowPAN: O,
            isPartner: h
          })
        })
      ]
    });
  }
  async function fe(_, y) {
    C(!0);
    try {
      const M = await A.fpClient.master_data().fetchScheme(_);
      q({
        ...M,
        key: y.number + "-" + _
      }), C(!1);
    } catch (M) {
      C(!1), V(t._({
        id: "Gojdwx"
      })), J(t._({
        id: "rAP48C"
      })), I("error"), me(M);
    }
  }
  function te() {
    switch (be) {
      case "initial":
        return E();
      case "error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: le,
          description: P
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "QbrtNj"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "wBAu+p"
          }),
          actionHandler: Z
        });
      default:
        return E();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: ae ? /* @__PURE__ */ e.jsx(qe, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: te()
    })
  });
}
function wa(a) {
  const c = () => {
    const f = () => /* @__PURE__ */ e.jsx(pa, {
      ...a
    }), d = () => /* @__PURE__ */ e.jsx(xa, {
      ...a
    }), m = () => /* @__PURE__ */ e.jsx(ce, {
      title: t._({
        id: "JHDCYv"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), h = () => /* @__PURE__ */ e.jsx(ce, {
      title: t._({
        id: "Rds/a6"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    });
    return /* @__PURE__ */ e.jsx(Oe, {
      variant: "pills",
      selected: a.scheme.purchase_allowed ? 0 : 1,
      group: [
        {
          title: t._({
            id: "DUs4hi"
          }),
          content: a.scheme.purchase_allowed ? f() : m()
        },
        {
          title: t._({
            id: "v7QEHl"
          }),
          content: a.scheme.sip_allowed ? d() : h()
        }
      ]
    });
  }, D = () => {
    const f = () => /* @__PURE__ */ e.jsx(ga, {
      ...a
    }), d = () => /* @__PURE__ */ e.jsx(ba, {
      ...a
    }), m = () => /* @__PURE__ */ e.jsx(ce, {
      title: t._({
        id: "CGb61H"
      }),
      description: t._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    });
    return /* @__PURE__ */ e.jsx(Oe, {
      variant: "pills",
      selected: 0,
      group: [
        {
          title: t._({
            id: "DUs4hi"
          }),
          content: a.scheme.switch_in_allowed ? f() : m()
        },
        {
          title: t._({
            id: "v7QEHl"
          }),
          content: a.scheme.switch_in_allowed ? d() : m()
        }
      ]
    });
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Oe, {
      selected: a.isGroupInvestment || a.defaultInvestmentType === "purchase" ? 0 : 1,
      group: a.isGroupInvestment ? [
        {
          title: t._({
            id: "dmetZq"
          }),
          content: c()
        }
      ] : [
        {
          title: t._({
            id: "dmetZq"
          }),
          content: c()
        },
        {
          title: t._({
            id: "+Q/+Mn"
          }),
          content: D()
        }
      ]
    })
  });
}
function Ga({ scheme: a, switch_out: c, isGroupInvestment: D }) {
  var ke, S, Ue;
  const [f, d] = x("order"), [m, h] = x(null), [oe, O] = x(null), [ae, C] = x(null), [n, ee] = x(null), [T, ie] = x(null), [be, I] = x(!1), [le, V] = x("purchase"), [P, J] = x(null), [L, q] = x(!1), [g, j] = x(null), { AppState: { employer: A, tenant: Z } } = je(wt), { analytics: k } = Et(), { fpapi: E } = je(Le), { loggedInUser: fe } = je(ft), { tenantConfig: te } = Je(), _ = (ke = te == null ? void 0 : te.groupInvestment) == null ? void 0 : ke.allowedEmailDomains, y = ((S = te == null ? void 0 : te.app_constants) == null ? void 0 : S.kyc_refetch_interval_in_months) || 3, M = a != null && a.purchase_allowed ? ((Ue = te == null ? void 0 : te.app_constants) == null ? void 0 : Ue.plans_mode) || "" : "systematic";
  Se(() => {
    ne();
  }, [
    te,
    fe,
    a
  ]), Yt(() => {
    const r = sessionStorage.getItem("product"), i = a == null ? void 0 : a.isin;
    i && (sessionStorage.setItem("product", i), r !== i && de());
  }, []);
  const o = (r) => K(r) ? {
    success: !1,
    stageToRender: "scheme_not_found"
  } : !(r != null && r.active) || !(r != null && r.purchase_allowed) && !(r != null && r.sip_allowed) ? {
    success: !1,
    stageToRender: "purchase_not_allowed"
  } : {
    success: !0
  }, F = ({ tenantConfig: r, user: i, investorProfile: u }) => {
    var H;
    return (_ == null ? void 0 : _[(H = i == null ? void 0 : i.email_address) == null ? void 0 : H.split("@")[1]]) ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "invalid_email_domain"
    };
  }, W = ({ kycCheckObj: r, user: i, mfia: u }) => {
    var re, ye;
    const w = ((re = i == null ? void 0 : i.annexure) == null ? void 0 : re.isonboarded) === "true";
    return !w || !(u != null && u.primary_investor) ? {
      success: !1,
      stageToRender: "onboard"
    } : w && !r.status ? {
      success: !1,
      stageToRender: "kyc_non_compliant"
    } : (ye = u == null ? void 0 : u.folio_defaults) != null && ye.payout_bank_account ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "bank_verification"
    };
  }, Y = async (r) => {
    try {
      const i = await E.fpClient.investor_reports().fetchHoldings({
        investment_account_id: r == null ? void 0 : r.old_id
      });
      j(i);
    } catch (i) {
      window.debug.log(i, "Error");
    }
  }, ne = async () => {
    var r, i, u;
    try {
      if (I(!0), !fe) {
        d("auth");
        return;
      }
      const w = o(a);
      if (!w.success) {
        d(w.stageToRender);
        return;
      }
      const H = [
        E.fetchUser(fe.profile.preferred_username, Z),
        E.getInvestorProfile(),
        E.fpClient.mf_investment_accounts().fetchAll({
          investor: (i = (r = fe == null ? void 0 : fe.profile) == null ? void 0 : r.preferred_username) == null ? void 0 : i.toUpperCase()
        })
      ], [$, re, ye] = await Promise.all(H);
      if (!re) {
        window.debug.error("Investor profile is not present"), d("error_page");
        return;
      }
      const he = $ == null ? void 0 : $[0], He = (u = ye == null ? void 0 : ye.data) == null ? void 0 : u[0];
      if (D) {
        const Be = F({
          tenantConfig: te,
          user: he,
          investorProfile: re
        });
        if (!Be.success) {
          d(Be.stageToRender);
          return;
        }
      }
      const [$e, { isMigratedUser: pt, migratedMfiaList: xt, migratedFolioList: yt }] = await Promise.all([
        Ie(he),
        ta(he, E)
      ]);
      if (pt)
        Y(He), await aa(E, he, xt, yt), $e.status ? d("order") : d("onboard");
      else {
        const Be = W({
          kycCheckObj: $e,
          user: he,
          mfia: He
        });
        Be.success ? d("order") : d(Be.stageToRender);
      }
      O(he), ee(re), ie(ye == null ? void 0 : ye.data), J($e), k.identify(re == null ? void 0 : re.id);
    } catch (w) {
      d("error_page"), window.debug.error(w), me(w);
    } finally {
      I(!1);
    }
  };
  async function Q(r, i) {
    if (K(i)) {
      d("finished"), h(r);
      return;
    }
    I(!0);
    try {
      const u = await E.fpClient.mf_purchases().fetch(i == null ? void 0 : i.id);
      h(u);
    } catch (u) {
      window.debug.log(u), me(u);
    }
    d("payment"), I(!1);
  }
  const B = (r) => {
    K(r) || (D ? d("finished") : (m == null ? void 0 : m.object) === "mf_purchase" && (h(m), d("payment")));
  }, R = async (r, i) => {
    if (r) {
      if (D)
        d("finished");
      else if (m.object === "mf_purchase_plan") {
        if (!K(i) && M === "nonSystematic") {
          const { emailData: u, phoneData: w } = await E.fetchEmailAndPhone(n);
          await E.fpClient.mf_purchases().update({
            id: i == null ? void 0 : i.id,
            consent: {
              email: u.email,
              isd_code: w.isd,
              mobile: w.number
            }
          });
        }
        Q(m, i);
      }
    }
  };
  function we(r) {
    const { mf_order: i, isGenerateFirstInstallment: u, shouldOnboardMigratedUser: w } = r;
    if (w) {
      h(i), d("onboard_migrated_user");
      return;
    }
    if ((i == null ? void 0 : i.object) === "mf_purchase") {
      h(i), d("purchase_confirmation");
      return;
    }
    if ((i == null ? void 0 : i.object) === "mf_purchase_plan") {
      h(i), q(u), d("purchase_plan_confirmation");
      return;
    }
    (i == null ? void 0 : i.object) === "mf_switch" && (h(i), d("switch_confirmation")), (i == null ? void 0 : i.object) === "mf_switch_plan" && (h(i), d("switch_plan_confirmation"));
  }
  function de() {
    m && (m.object === "mf_purchase" || m.object === "mf_purchase_plan" ? V("purchase") : V("switch")), h({}), d("order");
  }
  function se() {
    D ? d("order") : m.object === "mf_purchase" && E.fpClient.mf_purchases().retry(m == null ? void 0 : m.id).then(() => {
      window.debug.log("Order Retry successful"), d("payment");
    }).catch((r) => {
      de(), me(r);
    });
  }
  function ve({ success: r, isWindowBlocked: i, paymentId: u }) {
    (async () => {
      if (u)
        try {
          const w = await E.fpClient.payments().fetch(Number(u));
          C(w);
          let H;
          m.plan ? H = await E.fpClient.mf_purchase_plans().fetch(m == null ? void 0 : m.plan) : H = await E.fpClient.mf_purchases().fetch(m.id), h(H);
        } catch (w) {
          window.debug.log(w), me(w);
        }
      d(r && !i || !r && !i ? "finished" : "finished_error");
    })();
  }
  const Te = async (r, i, u) => {
    try {
      I(!0);
      const w = await Ie(u), H = W({
        kycCheckObj: w,
        user: u,
        mfia: i == null ? void 0 : i[0]
      });
      H.success ? d("order") : d(H.stageToRender), O(u), J(w), ee(r), ie(i);
    } catch (w) {
      window.debug.error(w), me(w), d("error_page");
    } finally {
      I(!1);
    }
  }, U = (r) => {
    h(r), d("finished");
  }, xe = () => {
    var r;
    (r = E == null ? void 0 : E.userAuthService) == null || r.initiateLogout(window.location.href).then((i) => {
      window.location.href = i.userState;
    });
  }, De = async (r) => {
    var i;
    if (T != null && T.length)
      try {
        const u = (i = T == null ? void 0 : T[0]) == null ? void 0 : i.id, w = await E.fpClient.mf_investment_accounts().update({
          id: u,
          folio_defaults: {
            payout_bank_account: r == null ? void 0 : r.id
          }
        }), H = T.map(($) => {
          if ($.id === w.id) {
            const re = $;
            return re.folio_defaults.payout_bank_account = w.folio_defaults.payout_bank_account, re;
          } else
            return $;
        });
        ie(H), d("order");
      } catch (u) {
        window.debug.error(u);
      }
  }, Ie = async (r) => {
    const i = Xt(r == null ? void 0 : r.annexure);
    if (i != null && i.kycCheckId) {
      const u = await E.fpClient.kyc_checks().fetch(i == null ? void 0 : i.kycCheckId);
      return u.status && !Gt(u.updated_at, y) ? u : ea(u.updated_at) ? await E.fpClient.kyc_checks().refetch(i == null ? void 0 : i.kycCheckId) : u;
    } else {
      const u = r == null ? void 0 : r.id, w = await E.fpClient.kyc_checks().create({
        pan: r == null ? void 0 : r.username.toUpperCase()
      }), H = {
        ...i,
        kycCheckId: w.id
      }, $ = await E.updateUser(u, H, null, Z);
      if ($)
        return w;
      throw $;
    }
  };
  function v() {
    switch (f) {
      default:
        return /* @__PURE__ */ e.jsx(qe, {
          variant: "fullpage"
        });
      case "auth":
        return /* @__PURE__ */ e.jsx(Zt, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: D
        });
      case "onboard":
        return /* @__PURE__ */ e.jsx(_a, {
          user: oe,
          isGroupInvestment: D || !1,
          setUser: O,
          onBoardingComplete: Te,
          isPartner: !1
        });
      case "bank_verification":
        return /* @__PURE__ */ e.jsx(ma, {
          investorProfile: n,
          handleBankSelection: De,
          customPageSubtitle: t._({
            id: "KM15wf"
          })
        });
      case "order":
        return bt(T) ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(wa, {
          scheme: a,
          switch_out: c,
          investment_account_id: T[0].id,
          investment_accounts: T,
          defaultInvestmentType: le,
          investorProfile: n,
          isGroupInvestment: D,
          onSuccess: we,
          user: oe,
          holdings: g
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(va, {
          onContinue: () => {
            window.location.href = `${window.location.origin + St}/onboarding?${D ? `isGroupInvestment=${D}` : ""}${D ? `&employer=${A == null ? void 0 : A.id}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + St}/${D ? "group-checkout" : "checkout"}/?isin=${a.isin}${D ? `&employer=${A == null ? void 0 : A.id}` : ""}`)}`;
          }
        });
      case "purchase_confirmation":
        return !K(n) && !K(m) ? /* @__PURE__ */ e.jsx(da, {
          investorProfile: n,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: D,
          onSuccess: B,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "purchase_plan_confirmation":
        return !K(n) && !K(m) ? /* @__PURE__ */ e.jsx(oa, {
          investorProfile: n,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: D,
          onSuccess: (r, i) => R(r, i),
          isFirstInstallmentPayment: L,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "payment":
        return !K(n) && !K(m) ? /* @__PURE__ */ e.jsx(la, {
          investorProfile: n,
          mf_purchase: m,
          onCompletion: ve
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !K(a) && !K(m) ? /* @__PURE__ */ e.jsx(ra, {
          investorProfile: n,
          scheme: a,
          mf_order: m,
          payment: ae,
          isGroupInvestment: D,
          handleRetry: se,
          handleInvestAgain: de
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished_error":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "sS+OUW"
          }),
          showAction: !0,
          actionText: t._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            d("payment");
          }
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "YzYf5v"
          }),
          description: t._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "+PjKpk"
          }),
          description: t._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "switch_confirmation":
        return !K(a) && !K(m) ? /* @__PURE__ */ e.jsx(sa, {
          id: m == null ? void 0 : m.id,
          investorProfile: n,
          showSchemeDetails: !1,
          onSuccess: U,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "switch_plan_confirmation":
        return !K(a) && !K(m) ? /* @__PURE__ */ e.jsx(na, {
          id: m.id,
          investorProfile: n,
          onSuccess: U,
          showSchemeDetails: !1,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "ZXjii8"
          }),
          description: t._({
            id: "mpazQQ"
          }),
          showDetails: !1,
          actionText: t._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: xe
        });
      case "invalid_employer":
        return /* @__PURE__ */ e.jsx(ce, {
          title: t._({
            id: "8gLo60"
          }),
          description: t._({
            id: "REewWf"
          }),
          showDetails: !1,
          actionText: t._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: xe
        });
      case "kyc_non_compliant":
        return P ? /* @__PURE__ */ e.jsx(ia, {
          kycCheckObject: P,
          customMessage: {
            unverified: t._({
              id: "V2UMyt"
            })
          }
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "error_page":
        return /* @__PURE__ */ e.jsx(ce, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return be ? /* @__PURE__ */ e.jsx(qe, {
    variant: "fullpage"
  }) : v();
}
const va = ({ onContinue: a }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Jt, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(pe, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => a(),
        children: "Continue"
      })
    })
  ]
});
export {
  Ga as I,
  wa as O
};
