import { j as u } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as J, useEffect as L } from "react";
import { S as Q, c, i as Z } from "./index.esm-qyC9L-ym.js";
import { y as n, k as M, i as g, h as R, z as p, A as m, C as y, b as A, a as C, D as H } from "./utils-Bzskk-FI.js";
import { u as O } from "./useConfiguration-B-lyaBB9.js";
import { i as l } from "./index-C-jpp3An.js";
const r = ({ order: s, currentPlanObject: v, isInstallmentListing: b, handlePayment: F, scheme: f, switchOutScheme: _, isGroupInvestment: $, isPartner: k, paymentDetails: i }) => {
  const { tenantConfig: t, amcs: T } = O(), h = t == null ? void 0 : t.fallbackLogo, w = t == null ? void 0 : t.showSchemeLogo, [U, V] = J("");
  L(() => {
    let a;
    (s == null ? void 0 : s.object) === "mf_purchase" ? a = (s == null ? void 0 : s.state) === "pending" && (i == null ? void 0 : i.status) === "INITIATED" ? "Payment processing" : n(s == null ? void 0 : s.state, s == null ? void 0 : s.object, s == null ? void 0 : s.failure_code) : a = n(s == null ? void 0 : s.state, s == null ? void 0 : s.object, s == null ? void 0 : s.failure_code), V(a);
  }, [
    s
  ]);
  const q = (a) => {
    const j = [
      "SUCCESS",
      "INITIATED",
      "APPROVED",
      "SUBMITTED"
    ], x = [
      "FAILED",
      "TIMEDOUT"
    ];
    let N = !1, S = !1, E = !1, I = !1;
    g(i) || (j.includes(i == null ? void 0 : i.status) && (N = !0), x.includes(i == null ? void 0 : i.status) && (S = !0), (i == null ? void 0 : i.status) === "APPROVED" && (E = !0), (i == null ? void 0 : i.status) === "INITIATED" && (I = !0));
    const z = (a == null ? void 0 : a.state) === "pending" && !N, B = (a == null ? void 0 : a.state) === "failed" && (a == null ? void 0 : a.failure_code) === "payment_failure" && !N && S;
    return {
      isPaymentPending: z,
      isPaymentRetry: B,
      isPaymentSuccessful: E,
      isPaymentInitiated: I
    };
  }, K = (a) => {
    if (!$ && (a == null ? void 0 : a.object) === "mf_purchase" && (!(v != null && v.payment_method) && b || !(a != null && a.plan) && !b)) {
      const { isPaymentPending: j, isPaymentRetry: x } = q(a);
      return k && (j || x) ? {
        buttonText: l._({
          id: "D90FBQ"
        })
      } : j ? {
        buttonText: l._({
          id: "mtFRI7"
        })
      } : x ? H(i == null ? void 0 : i.created_at) ? {
        buttonText: l._({
          id: "vgDUPm"
        })
      } : {
        title: /* @__PURE__ */ u.jsx(u.Fragment, {
          children: /* @__PURE__ */ u.jsx("div", {
            className: "text-center",
            children: l._({
              id: "7XOaxV"
            })
          })
        })
      } : void 0;
    } else
      return;
  }, X = (a) => !(a != null && a.amount) && !(a != null && a.units) ? "All" : a != null && a.units ? m(a == null ? void 0 : a.units, 2) : "-", P = (a) => a === "mf_purchase" ? "purchased_price" : a === "mf_redemption" ? "redeemed_price" : "switched_in_price";
  return /* @__PURE__ */ u.jsx(Q, {
    customClass: "mb-8",
    header: {
      title: /* @__PURE__ */ u.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: l._({
          id: "moFZxg",
          values: {
            0: M(new Date(s == null ? void 0 : s.created_at), "dd-MMM-yyyy")
          }
        })
      })
    },
    footer: K(s),
    onClick: () => F && F(s, "details_page"),
    children: /* @__PURE__ */ u.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "6Xqu8f"
            }),
            value: g(_) ? "-" : R(_ == null ? void 0 : _.name),
            logo: w ? T[_ == null ? void 0 : _.amc_id] || h : ""
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        b ? /* @__PURE__ */ u.jsx(u.Fragment, {}) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "UMs2dC"
            }),
            value: g(f) ? "-" : R(f == null ? void 0 : f.name),
            logo: w ? T[f == null ? void 0 : f.amc_id] || h : ""
          })
        }),
        (s == null ? void 0 : s.object) === "mf_redemption" || (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsxs(u.Fragment, {
          children: [
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "cNfDE7"
                }),
                value: X(s)
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "pN9mLH"
                }),
                value: s != null && s.amount ? `₹ ${p(s == null ? void 0 : s.amount)}` : "-"
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "Q9PC60",
                  values: {
                    0: (s == null ? void 0 : s.object) === "mf_redemption" ? "withdrawn" : "switched"
                  }
                }),
                value: (s == null ? void 0 : s.object) === "mf_redemption" ? s != null && s.redeemed_units ? m(s == null ? void 0 : s.redeemed_units, 2) : "-" : s != null && s.switched_out_units ? m(s == null ? void 0 : s.switched_out_units, 2) : "-"
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "pv2sFw",
                  values: {
                    0: (s == null ? void 0 : s.object) === "mf_redemption" ? "withdrawn" : "switched"
                  }
                }),
                value: (s == null ? void 0 : s.object) === "mf_redemption" ? s != null && s.redeemed_amount ? `₹ ${p(s == null ? void 0 : s.redeemed_amount)}` : "-" : s != null && s.switched_out_amount ? `₹ ${p(s == null ? void 0 : s.switched_out_amount)}` : "-"
              })
            })
          ]
        }) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: s != null && s.amount ? l._({
              id: "KqeLJ1"
            }) : l._({
              id: "6ssn5V"
            }),
            value: s != null && s.amount ? `₹ ${p(s == null ? void 0 : s.amount)}` : s != null && s.units ? s == null ? void 0 : s.units : "-"
          })
        }),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "o9DqK+"
            }),
            value: s != null && s.allotted_units ? m(s == null ? void 0 : s.allotted_units, 2) : "-"
          })
        }),
        (s == null ? void 0 : s.object) === "mf_purchase" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "TK3bTJ"
            }),
            value: s != null && s.confirmed_at ? M(new Date(s == null ? void 0 : s.confirmed_at), "dd-MMM-yyyy") : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "e7LnWq"
            }),
            value: s != null && s.state ? /* @__PURE__ */ u.jsx(Z, {
              variant: "outline",
              message: U,
              status: y(s == null ? void 0 : s.state)
            }) : "-"
          })
        }),
        b ? /* @__PURE__ */ u.jsx(u.Fragment, {}) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "cXcPd1"
            }),
            value: s != null && s.folio_number ? s == null ? void 0 : s.folio_number : "New folio"
          })
        }),
        b ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "uoyZIo",
              values: {
                0: A(s == null ? void 0 : s.id),
                1: (s == null ? void 0 : s.object) === "mf_switch" ? "in " : ""
              }
            }),
            value: s[P(s == null ? void 0 : s.object)] ? `₹ ${m(s[P(s == null ? void 0 : s.object)], 2)}` : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        b && (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "42mR8Y",
              values: {
                0: A(s == null ? void 0 : s.id)
              }
            }),
            value: s.switched_out_price ? `₹ ${m(s.switched_out_price, 2)}` : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: `${C(s == null ? void 0 : s.id)} ref`,
            value: (s == null ? void 0 : s.id) || "-"
          })
        }),
        s != null && s.plan && !b ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: `${C(s == null ? void 0 : s.plan)} ref`,
            value: s == null ? void 0 : s.plan
          })
        }) : null
      ]
    })
  });
};
export {
  r as D
};
