import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as h, useContext as X, useEffect as Y } from "react";
import { a as le, B as L, S as te, c as j, D as ae, e as ee, T as be, M as Z, F as we, d as Ie, b as xe } from "./index.esm-qyC9L-ym.js";
import { F as ce } from "./provider-CNUuOe_A.js";
import { r as S } from "./sentry-Djb1pAvw.js";
import { o as Re } from "./redirection-_r5ryu1X.js";
import { i as se, B as G, A as q, k as ne, z as Be, $ as Oe, m as $e, E as Ve } from "./utils-Bzskk-FI.js";
import { u as re } from "./useConfiguration-B-lyaBB9.js";
import { i as a } from "./index-C-jpp3An.js";
import { S as ge } from "./StatusMessage-CBzuZxyP.js";
import { ErrorPage as ie } from "./ErrorPage.js";
import { R as ze, F as je } from "./react-final-form.es-B_J3uV0l.js";
import { f as He, r as Je } from "./validators-BNinzCrT.js";
import { B as Ue } from "./BankAccountListing-21nhFdVG.js";
import { AppContext as We } from "./AppContext.js";
const H = {
  CREATED: {
    value: "Pending",
    message_type: "alert",
    icon: "Alert"
  },
  RECEIVED: {
    value: "Processing",
    message_type: "alert",
    icon: "Alert"
  },
  SUBMITTED: {
    value: "Processing",
    message_type: "alert",
    icon: "Alert"
  },
  APPROVED: {
    value: "Approved",
    message_type: "success",
    icon: "successInfo"
  },
  REJECTED: {
    value: "Rejected",
    message_type: "error",
    icon: "Error"
  },
  CANCELLED: {
    value: "Cancelled",
    message_type: "error",
    icon: "Error"
  }
};
function Ye({ currentMandateId: r, investorProfile: w }) {
  const [A, O] = h(""), [C, F] = h(""), [o, M] = h("initial"), { fpapi: I } = X(ce), [k, T] = h({}), [f, y] = h({}), [J, _] = h(!1), { tenantConfig: R, banks: U } = re(), $ = R == null ? void 0 : R.fallbackLogo;
  Y(() => {
    V();
  }, []);
  const V = async () => {
    var E;
    _(!0);
    let s = {}, g = {};
    try {
      s = await I.fpClient.mandates().fetch(r), g = await I.fpClient.bank_accounts().fetchAll({
        profile: w == null ? void 0 : w.id
      });
      const D = (E = g == null ? void 0 : g.data) == null ? void 0 : E.filter((d) => (d == null ? void 0 : d.old_id) === (s == null ? void 0 : s.bank_account_id));
      O(a._({
        id: "y/ttrn"
      })), F("successInfo"), y(D[0]), T(s);
    } catch (D) {
      M("error"), _(!1), S(D);
    }
    _(!1);
  }, i = (s) => {
    var g;
    return /* @__PURE__ */ e.jsx(te, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-full",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "JVRi6u"
              }),
              value: `${f == null ? void 0 : f.bank_name} (*****${f == null ? void 0 : f.account_number.slice(-4)})`,
              logo: U[(g = f == null ? void 0 : f.ifsc_code) == null ? void 0 : g.slice(0, 4)] || $
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "OzNl8x"
              }),
              value: s ? H[s.mandate_status].value : "-",
              icon: s ? H[s.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "nlXCcY"
              }),
              value: "NACH"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "Zp6kam"
              }),
              value: `₹ ${q(s.mandate_limit)}`
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "0OJ5oD"
              }),
              value: ne(new Date(s.valid_from), "dd-MMM-yyyy")
            })
          })
        ]
      })
    }, s.id);
  };
  function l() {
    switch (o) {
      case "initial":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ge, {
              title: A,
              iconName: C,
              customClass: "mt-8"
            }),
            !se(k) && i(k),
            /* @__PURE__ */ e.jsx(L, {
              fullwidth: !0,
              onClick: () => window.location.href = `${window.location.origin + G}/mandates`,
              children: a._({
                id: "yk7/Xz"
              })
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(ie, {
          title: a._({
            id: "uWhFK2"
          }),
          description: a._({
            id: "NHwYHE"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ge, {
              title: A,
              iconName: C,
              customClass: "mt-8"
            }),
            !se(k) && i(k),
            /* @__PURE__ */ e.jsx(L, {
              fullwidth: !0,
              onClick: () => window.location.href = `${window.location.origin + G}/mandates`,
              children: a._({
                id: "yk7/Xz"
              })
            })
          ]
        });
    }
  }
  return J ? /* @__PURE__ */ e.jsx(le, {
    variant: "fullpage"
  }) : l();
}
function Ke({ investorProfile: r, onCompletion: w }) {
  const [A, O] = h(null), [C, F] = h(null), [o, M] = h(null), [I, k] = h("initial"), { fpapi: T } = X(ce), { tenantConfig: f, banks: y } = re(), J = f == null ? void 0 : f.fallbackLogo;
  Y(() => {
    R();
  }, [
    C
  ]), Y(() => {
    _();
  }, []);
  const _ = async () => {
    try {
      if (r != null && r.id) {
        k("loading");
        const i = await T.fpClient.bank_accounts().fetchAll({
          profile: r.id
        });
        F(i.data), k("initial");
      }
    } catch (i) {
      k("initial"), window.debug.error(i), S(i);
    }
  }, R = async () => {
    if (C) {
      k("loading");
      const i = /* @__PURE__ */ new Map();
      C.forEach((l) => l.id && i.set(l.id, l)), T.fpClient.bank_account_verifications().fetchAll({
        bank_accounts: Array.from(i.keys())
      }).then((l) => {
        const s = [];
        l.data.forEach((g) => {
          g.status === "completed" && s.push(i.get(g.bank_account));
        }), s.length && !o && M(s[0]), k("initial");
      });
    }
  };
  function U({ mandate_limit: i }) {
    var g, E;
    const l = o.old_id, s = {
      mandate_type: "E_MANDATE",
      mandate_limit: i,
      bank_account_id: l
    };
    (g = f == null ? void 0 : f.app_constants) != null && g.paymentGateway && (s.provider_name = (E = f == null ? void 0 : f.app_constants) == null ? void 0 : E.paymentGateway), T.fpClient.mandates().create(s).then((D) => {
      w({
        success: !0,
        mandate: {
          id: D.id,
          provider_name: s.provider_name
        }
      });
    }).catch((D) => {
      O(!0), w({
        success: !1
      }), S(D);
    });
  }
  const $ = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      r ? /* @__PURE__ */ e.jsx(ae, {
        type: "text",
        text: a._({
          id: "xEu7ys"
        })
      }) : null,
      /* @__PURE__ */ e.jsx(ze, {
        onSubmit: U,
        subscription: {
          submitting: !0,
          values: !0
        },
        validate: (i) => {
          const l = {};
          return i.mandate_limit && i.mandate_limit > 1e6 && (l.mandate_limit = a._({
            id: "ajSlbF"
          })), l;
        },
        children: (i) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: i.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: "Bank account"
                }),
                /* @__PURE__ */ e.jsx(ae, {
                  type: "borderedNotes",
                  text: o ? `${o.bank_name} - ****${o == null ? void 0 : o.account_number.slice(-4)}` : "No verified bank found",
                  logo: o ? y[o == null ? void 0 : o.ifsc_code.slice(0, 4)] : J,
                  children: /* @__PURE__ */ e.jsx(L, {
                    variant: "text",
                    onClick: () => {
                      k("listing_bankaccount");
                    },
                    children: a._({
                      id: "ShFidp"
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: a._({
                    id: "A59Kth"
                  })
                }),
                /* @__PURE__ */ e.jsx(je, {
                  name: "auth_mode",
                  initialValue: "NACH(Electronic)",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (l) => /* @__PURE__ */ e.jsx(e.Fragment, {
                    children: /* @__PURE__ */ e.jsx(be, {
                      id: l.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: l.input.name,
                      value: l.input.value,
                      disabled: !0,
                      onChange: l.input.onChange
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: a._({
                    id: "Zp6kam"
                  })
                }),
                /* @__PURE__ */ e.jsx(je, {
                  name: "mandate_limit",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: He(Je),
                  children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(be, {
                        id: l.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: l.input.name,
                        value: Be(l.input.value),
                        onChange: (s) => l.input.onChange(Oe(s.target.value)),
                        onInput: (s) => {
                          s.target.value = s.target.value.replace($e("0-9"), ""), s.target.value.length > 7 && (s.target.value = s.target.value.substring(0, 7));
                        },
                        prefix: "₹",
                        placeholder: `${q(25e3)}`,
                        status: l.meta.error && l.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      l.meta.error && l.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: l.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(we, {
              children: /* @__PURE__ */ e.jsx(L, {
                fullwidth: !0,
                variant: o ? "secondary" : "disabled",
                children: a._({
                  id: "bM8Qcn"
                })
              })
            })
          ]
        })
      }),
      A ? /* @__PURE__ */ e.jsx(Z, {
        type: "error",
        variant: "field",
        title: "Cannot create mandate"
      }) : null
    ]
  }), V = () => {
    switch (I) {
      case "initial":
        return $();
      case "loading":
        return /* @__PURE__ */ e.jsx(le, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ e.jsx(Ue, {
          investorProfile: r,
          handleBankSelection: async (i) => {
            M(i), _();
          },
          removeTitle: !0
        });
      default:
        return $();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: V()
  });
}
function Xe() {
  return window;
}
const Ze = (r) => {
  const w = Xe();
  return new w.Razorpay({
    key: r,
    // logo, displayed in the payment processing popup
    image: "https://i.imgur.com/n5tjHFD.png"
  });
};
function ut({ investorProfile: r, handleMandateSelection: w, status: A, limit: O, isPartner: C, mandate_id: F }) {
  const { fpapi: o } = X(ce), { tenantConfig: M, banks: I } = re(), { AppState: { tenant: k } } = X(We), T = M == null ? void 0 : M.fallbackLogo, [f, y] = h("initial"), [J, _] = h(!1), [R, U] = h(a._({
    id: "3Ep5Oa"
  })), [$, V] = h({
    index: 0,
    obj: null
  }), [i, l] = h(null), [s, g] = h(!0), [E, D] = h([]), d = F == null ? void 0 : F.split(",").map(Number), [W, Ce] = h([]), [oe, de] = h(!1), [ue, me] = h(!1), [ye, Q] = h(!1), K = {
    APPROVED: 1,
    CREATED: 2,
    SUBMITTED: 3,
    CANCELLED: 4,
    REJECTED: 5,
    RECEIVED: 6
  };
  Y(() => {
    (async () => {
      if (d) {
        const t = await o.fpClient.mandates().fetch(d[0]);
        t && (t.mandate_status.toUpperCase() === "CREATED" || t.mandate_status.toUpperCase() === "RECEIVED") && Q(!0);
      }
    })();
  }, [
    d
  ]), Y(() => {
    se(r) || z();
  }, [
    r
  ]);
  const z = () => {
    _(!0), o.fpClient.bank_accounts().fetchAll({
      profile: r == null ? void 0 : r.id
    }).then((t) => {
      const n = t.data;
      if (!n.length) {
        _(!1);
        return;
      }
      const b = n.map((p) => p.old_id);
      o.fpClient.mandates().fetchAll({
        bank_account_id: b.join(","),
        size: 100
      }).then((p) => {
        const u = p.mandates;
        if (u.sort((N, x) => new Date(x.created_at).valueOf() - new Date(N.created_at).valueOf()), u.length > 0) {
          U(a._({
            id: "dke4pV"
          }));
          let N = 0;
          n.forEach((c) => {
            u.forEach((m) => {
              m.bank_account_id === c.old_id && (m.bank = {
                bank_name: c.bank_name,
                number: c.account_number,
                ifsc: c.ifsc_code
              }), m.mandate_status.toLowerCase() === "approved" && N++;
            });
          }), N > 10 && g(!1);
          let x = [], v = [];
          if (w) {
            if (u == null || u.forEach((c) => {
              c.mandate_status.toLowerCase() === "approved" || c.mandate_status.toLowerCase() === "created" || c.mandate_status.toLowerCase() === "submitted" ? x.push(c) : v.push(c);
            }), A != null && A.length) {
              const c = [], m = [];
              x.forEach((B) => {
                A.includes(B.mandate_status.toLowerCase()) ? c.push(B) : m.push(B);
              }), x = [
                ...c
              ], v = [
                ...v,
                ...m
              ];
            }
            if (O) {
              const c = [], m = [];
              x.forEach((B) => {
                B.mandate_limit >= O ? c.push(B) : m.push(B);
              }), x = [
                ...c
              ], v = [
                ...v,
                ...m
              ];
            }
          } else d && d.length ? v = u.filter((c) => d.includes(c.id)) : u.forEach((c) => v.push(c));
          x.length && (x = x.sort((c, m) => K[c.mandate_status] - K[m.mandate_status] || c.mandate_limit - m.mandate_limit), V({
            index: 0,
            obj: x[0]
          })), v.length && (v = v.sort((c, m) => K[c.mandate_status] - K[m.mandate_status] || c.mandate_limit - m.mandate_limit)), D(x), Ce(v), _(!1);
        } else
          w && y("create_mandate"), _(!1);
      }).catch((p) => {
        _(!1), S(p);
      });
    }).catch((t) => {
      _(!1), S(t);
    });
  }, ve = async () => {
    try {
      const t = await o.fpClient.email_addresses().fetchAll({
        profile: r == null ? void 0 : r.id
      }), n = await o.fpClient.phone_numbers().fetchAll({
        profile: r == null ? void 0 : r.id
      });
      return {
        emailData: t,
        phoneData: n
      };
    } catch (t) {
      window.debug.log(t), S(t);
    }
  }, fe = (t) => {
    const n = t == null ? void 0 : t.mandate_status, b = (t == null ? void 0 : t.provider_name) === "BILLDESK";
    if (!(C || F))
      return n === "CREATED" ? {
        title: /* @__PURE__ */ e.jsx("div", {
          className: "text-center",
          children: C ? a._({
            id: "VT9pGb"
          }) : a._({
            id: "VNWQ6H"
          })
        }),
        buttonText: C ? a._({
          id: "HfQVqg"
        }) : a._({
          id: "ITf1WC"
        })
      } : n === "APPROVED" && !b ? {
        title: "",
        buttonText: a._({
          id: "LSCJk2"
        })
      } : n === "SUBMITTED" ? {
        title: /* @__PURE__ */ e.jsx("div", {
          className: "text-center",
          children: C ? a._({
            id: "OYc5eB"
          }) : a._({
            id: "Hm8Ax+"
          })
        })
      } : void 0;
  }, ke = () => {
    d && (window.location.href = window.location.origin + `/mandates?id=${d[0]}`), z(), y("initial"), Q(!1);
  }, Ee = () => {
    d && (window.location.href = window.location.origin + `/mandates?id=${d[0]}`), z(), y("initial");
  };
  function Me(t, n) {
    n === "BILLDESK" ? Re(t == null ? void 0 : t.token_url, "mandate-billdesk", ke, Ee) : ve().then((b) => {
      var v, c;
      const { emailData: p, phoneData: u } = b, N = {
        amount: 0,
        currency: "INR",
        method: "emandate",
        bank: "ICIC",
        email: (v = p == null ? void 0 : p.data[0]) == null ? void 0 : v.email,
        contact: (c = u == null ? void 0 : u.data[0]) == null ? void 0 : c.number,
        order_id: t.sdk_options.order_id,
        customer_id: t.sdk_options.customer_id
      }, x = Ze(t.sdk_options.key);
      x.createPayment(N), x.on("payment.success", function(m) {
        o.submitPayment(t.sdk_options.callback_url, m.razorpay_payment_id, m.razorpay_order_id, m.razorpay_signature).then(() => {
          d && (window.location.href = window.location.origin + `/mandates?id=${d[0]}`), z(), y("initial"), Q(!1);
        });
      }), x.on("payment.error", function(m) {
        o.submitPaymentError(t.sdk_options.callback_url, "error", m.error.code, m.error.description).then(() => {
          d && (window.location.href = window.location.origin + `/mandates?id=${d[0]}`), z(), y("initial");
        });
      });
    });
  }
  function pe(t) {
    var b;
    _(!0);
    const n = {
      mandate_id: t == null ? void 0 : t.id
    };
    ((b = M == null ? void 0 : M.app_constants) == null ? void 0 : b.paymentGateway) === "BILLDESK" && (n.payment_postback_url = `${window.location.origin + G}/redirection`), o.fpClient.mandates().authorize(n).then((p) => {
      Me(p, t == null ? void 0 : t.provider_name);
    }).catch((p) => {
      window.debug.error(p), _(!1), S(p);
    });
  }
  function Ne(t) {
    t.success ? (y("initial"), C ? z() : pe(t == null ? void 0 : t.mandate)) : y("error");
  }
  function Ae(t) {
    V({
      index: t.target.value,
      obj: E[t.target.value]
    });
  }
  const De = async (t) => {
    try {
      _(!0), l(t);
      const n = await o.fpClient.mf_investment_accounts().fetchAll({
        investor: r.pan
      }), u = (await o.fpClient.mf_purchase_plans().fetchAll({
        mf_investment_account: n.data[0].id
      })).data.filter((N) => Number(N.payment_source) === t.id);
      u != null && u.length ? de(!oe) : me(!ue), _(!1);
    } catch (n) {
      window.debug.error(n), _(!1), S(n);
    }
  }, Se = async () => {
    try {
      await o.fpClient.mandates().cancel(i == null ? void 0 : i.id), y("mandate_cancel_status");
    } catch (t) {
      window.debug.error(t), S(t);
    }
  }, P = (t) => {
    const n = t.mandate_status;
    n === "APPROVED" ? De(t) : (n === "CREATED" || n === "SUBMITTED") && (C ? window.open(Ve(window.location.origin + `/mandates?id=${t == null ? void 0 : t.id}`, k), "_blank", "noopener=true") : pe(t));
  }, Le = (t) => {
    const n = E.concat(W).filter((b) => b.id === t);
    n && P(n[0]);
  }, Fe = (t) => /* @__PURE__ */ e.jsx(L, {
    variant: "secondary",
    fullwidth: !0,
    onClick: () => {
      window.location.href = window.location.origin + G + `/plans-list?mandate_id=${t}`;
    },
    children: a._({
      id: "g+sYqK"
    })
  }), he = (t, n) => {
    var b, p, u, N;
    return /* @__PURE__ */ e.jsx(te, {
      footer: n && fe(t),
      onClick: () => P(t),
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "JVRi6u"
              }),
              value: `${(b = t.bank) == null ? void 0 : b.bank_name} (*****${(p = t.bank) == null ? void 0 : p.number.slice(-4)})`,
              logo: I[(N = (u = t == null ? void 0 : t.bank) == null ? void 0 : u.ifsc) == null ? void 0 : N.slice(0, 4)] || T
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "OzNl8x"
              }),
              value: t ? H[t.mandate_status].value : "-",
              icon: t ? H[t.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "nlXCcY"
              }),
              value: "NACH"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "Zp6kam"
              }),
              value: `₹ ${q(t.mandate_limit)}`
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "0OJ5oD"
              }),
              value: ne(new Date(t.valid_from), "dd-MMM-yyyy")
            })
          })
        ]
      })
    }, t.id);
  }, _e = () => r ? J ? /* @__PURE__ */ e.jsx(le, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs("div", {
    className: "pb-20",
    children: [
      !d && /* @__PURE__ */ e.jsx(ae, {
        type: "text",
        text: R
      }),
      w && E.length ? (
        // Mandate With Select property
        /* @__PURE__ */ e.jsx(Ie, {
          variant: "selectable",
          selected: 0,
          onChange: Ae,
          children: E.map((t) => {
            var n, b, p, u;
            return /* @__PURE__ */ e.jsx(te, {
              footer: fe(t),
              onClick: () => P(t),
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-mr-2 -ml-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-full pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "JVRi6u"
                      }),
                      value: `${(n = t.bank) == null ? void 0 : n.bank_name} (*****${(b = t.bank) == null ? void 0 : b.number.slice(-4)})`,
                      logo: I[(u = (p = t == null ? void 0 : t.bank) == null ? void 0 : p.ifsc) == null ? void 0 : u.slice(0, 4)] || T
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "OzNl8x"
                      }),
                      value: t ? H[t.mandate_status].value : "-",
                      icon: t ? H[t.mandate_status].icon : "",
                      iconPlacement: "left"
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "nlXCcY"
                      }),
                      value: "NACH"
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "Zp6kam"
                      }),
                      value: `₹ ${q(t.mandate_limit)}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "0OJ5oD"
                      }),
                      value: ne(new Date(t.valid_from), "dd-MMM-yyyy")
                    })
                  })
                ]
              })
            }, t.id);
          })
        })
      ) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      W.length ? /* @__PURE__ */ e.jsx(e.Fragment, {
        children: W.map((t) => he(t, !0))
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      !W.length && !E.length ? /* @__PURE__ */ e.jsx(ie, {
        title: a._({
          id: "cVDNPn"
        }),
        description: a._({
          id: "qkY58T"
        }),
        showDetails: !1,
        showAction: !1
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      /* @__PURE__ */ e.jsxs(xe, {
        title: a._({
          id: "C7VNHg"
        }),
        isOpen: oe,
        toggleModal: () => {
          de(!1);
        },
        contentHeightMax: !1,
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "pb-5",
            children: a._({
              id: "aUwXDf"
            })
          }),
          Fe(i == null ? void 0 : i.id)
        ]
      }),
      /* @__PURE__ */ e.jsx(xe, {
        title: a._({
          id: "th6zPi"
        }),
        variant: "fullPage",
        isOpen: ue,
        toggleModal: () => {
          me(!1);
        },
        contentHeightMax: !1,
        children: i ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            he(i, !1),
            /* @__PURE__ */ e.jsx(Z, {
              type: "important",
              variant: "flash",
              title: a._({
                id: "tFTUWJ"
              }),
              customClass: "mb-5"
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-1",
              children: /* @__PURE__ */ e.jsx(L, {
                variant: "primary",
                type: "submit",
                fullwidth: !0,
                onClick: Se,
                children: a._({
                  id: "H/E6fZ"
                })
              })
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      }),
      d && ye && !C && (W.length || E.length) ? /* @__PURE__ */ e.jsx(L, {
        variant: "primary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: () => Le(d[0]),
        children: a._({
          id: "gnQNf6"
        })
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      /* @__PURE__ */ e.jsxs(we, {
        children: [
          w ? /* @__PURE__ */ e.jsx("div", {
            className: "mb-4",
            children: /* @__PURE__ */ e.jsx(L, {
              variant: E.length ? "primary" : "disabled",
              fullwidth: !0,
              onClick: (t) => {
                t.preventDefault(), w($.obj);
              },
              children: a._({
                id: "H/E6fZ"
              })
            })
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          d ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : s ? /* @__PURE__ */ e.jsx("div", {
            className: "mb-4",
            children: /* @__PURE__ */ e.jsx(L, {
              variant: "secondary",
              fullwidth: !0,
              onClick: () => {
                y("create_mandate");
              },
              children: a._({
                id: "EkzoDS"
              })
            })
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
        ]
      })
    ]
  }) : /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Z, {
      type: "important",
      variant: "flash",
      title: a._({
        id: "uUf3a6"
      }),
      customClass: "mb-5"
    })
  }), Te = () => {
    switch (f) {
      case "initial":
        return _e();
      case "create_mandate":
        return /* @__PURE__ */ e.jsx(Ke, {
          investorProfile: r,
          onCompletion: (t) => {
            Ne(t);
          }
        });
      case "mandate_cancel_status":
        return /* @__PURE__ */ e.jsx(Ye, {
          currentMandateId: i.id,
          investorProfile: r
        });
      case "error":
        return /* @__PURE__ */ e.jsx(ie, {
          title: a._({
            id: "ygb3I+"
          }),
          description: a._({
            id: "02oCtw"
          }),
          showAction: !0,
          actionText: a._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            y("initial");
          }
        });
      default:
        return _e();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: Te()
  });
}
export {
  ut as M,
  H as m,
  Ze as r
};
